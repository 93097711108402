import React from 'react';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import { Tooltip } from '@mui/material';

import { InfoOutlined } from '@mui/icons-material';
// import InfoIcon from '../../assets/images/info.png';

const styles = (theme) => ({
  tooltipPopper: (props) => ({
    textTransform: theme.typography.textTransform[props.textTransform],
  }),
  tooltipContainer: {
    ...theme.typography.fontSizes.normalText,
    // ...theme.content.flexStyles.flexRow,
    // ...theme.content.flexStyles.flexAlignBetweenCenter,
    padding: '2px',
  },
  tooltipHelperText: {
    ...theme.typography.fontSizes.normalText,
    margin: 0,
    maxWidth: 180,
    whiteSpace: 'nowrap',
    overflow: theme.content.overflow.hidden,
    textOverflow: 'ellipsis',
  },
  tooltipHelperTextFull: {
    ...theme.typography.fontSizes.normalText,
    margin: 0,
  },
  tooltipHelperLink: {
    ...theme.typography.fontSizes.normalText,
    marginLeft: 12,
    fontWeight: theme.typography.fontWeight.normal,
    color: theme.palette.bgBadgeLight,
  },
  tableHeadInfoIcon: {
    cursor: `${theme.content.cursor.pointer}`,
    color: theme.palette.stroke,
    fontSize: 16,
  },
});

const useStyles = makeStyles(styles);

const Helper = ({ title, link }) => {
  const classes = useStyles();

  const helperTooltip = (helperText, helperLink) => {
    return (
      <div className={classes.tooltipContainer}>
        <span className={classes.tooltipHelperTextFull}>{helperText}</span>
        {helperLink ? (
          <Link to={`/${helperLink}`} className={classes.tooltipHelperLink}>
            Learn More
          </Link>
        ) : null}
      </div>
    );
  };

  return (
    <div className={classes.container} onClick={(e) => e.stopPropagation()}>
      <Tooltip
        arrow
        title={helperTooltip(title, link)}
        classes={{ popper: classes.tooltipPopper }}
      >
        <InfoOutlined className={classes.tableHeadInfoIcon} />
        {/* <img src={InfoIcon} style={{ width: 20, height: 20, marginLeft: 4}} /> */}
      </Tooltip>
    </div>
  );
};

export default Helper;
