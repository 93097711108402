import { useQuery, useMutation } from 'react-query';
import { FLOORS_STRING } from '../../config/constants';
import axiosInstance from 'Utils/axios';
import Toast from 'components/Toast';
import React from 'react';
const fetchConstraintFilters = ({ season, store_id }) => {
  const reqestPayload = {
    season,
  };

  return axiosInstance.post(`constraints/filters/${store_id}/`, reqestPayload);
};

export const fetchStoreConstraints = ({ season, store_id }) => {
  const reqestPayload = {
    season,
  };

  return axiosInstance.post(`constraints/list/${store_id}/`, reqestPayload);
};

const saveConstraints = (payload) => {
  const tableData = payload?.data?.map((item) => {
    const {
      floor_plan_area,
      store_number,
      floor,
      department,
      user_fix_area,
      ly_area,
      sim_area_array,
      user_min_area,
      user_max_area,
      fix_flag,
      user_dept_selected,
      delete_flag,
      new_dept_flag,
      step_size,
    } = item;
    const filteredItem = {
      floor_plan_area,
      store_number,
      floor,
      department,
      user_fix_area,
      ly_area,
      sim_area_array,
      user_min_area,
      user_max_area,
      fix_flag,
      user_dept_selected,
      delete_flag,
      new_dept_flag,
      step_size,
    };
    return filteredItem;
  });
  const reqestPayload = {
    season: {
      name: payload?.season?.name,
      year: payload?.season?.year,
    },
    data: tableData,
  };

  return axiosInstance.post(
    `constraints/user/${payload.storeId}/`,
    reqestPayload
  );
};

const runStoreOptimizer = async (payload) => {
  const { season, level_flag, storeId, floors, floor_plan } = payload;
  const requestPayload = {
    season: {
      year: season?.year,
      name: season?.name,
    },
    level_flag,
    floors,
    floor_plan,
  };

  return axiosInstance.post(`optimize/run/${storeId}/`, requestPayload);
};

const fetchTotalArea = ({ season = {}, store_id }) => {
  const reqestPayload = {
    season: {
      year: season?.year,
      name: season?.name,
    },
  };

  return axiosInstance.post(`constraints/ly_area/${store_id}/`, reqestPayload);
};

export const getConstraintFilters = (payload) => {
  return useQuery(
    ['constraint-filters', payload],
    () => fetchConstraintFilters(payload),
    {
      enabled: false,
      select: (data) => {
        const filters = data.data.data;
        filters.future_seasons = filters?.future_seasons?.map((season) => {
          season.label = `${season?.year} ${season?.name}`;

          return season;
        });

        return filters;
      },
    }
  );
};

export const getStoreConstraintsData = (payload) => {
  return useQuery(
    ['store-constraints', payload],
    () => fetchStoreConstraints(payload),
    {
      enabled: false,
      select: (data) => {
        data.data.data.forEach((store, index) => {
          store.department_index = index;
          store.store_number = payload.store_id;
          store.floor_str = FLOORS_STRING[store.floor];
          store.min_area_index = store?.sim_area_array?.indexOf(
            store.user_min_area
          );
          store.max_area_index = store?.sim_area_array?.indexOf(
            store.user_max_area
          );
          store.user_fix_area = store.user_fix_area || parseInt(store.ly_area);
          store.disable_user_fix_area = true;

          if (store.new_dept_flag === 0) {
            store.ly_area_int = parseInt(store.ly_area);
            store.ly_revenue_int = parseInt(store.ly_revenue);
            store.step_size =
              store.new_department_flag === 1 ||
              !store.final_elasticity_tag ||
              store.final_tagging === 'Low'
                ? null
                : (store?.step_size_array?.length &&
                    store.step_size_array[0]) ||
                  store?.step_size;
            store.step_size_index = 0;
          } else {
            store.final_elasticity_tag = null;
            store.final_elasticity_tag_number = null;
            store.final_tagging = null;
            store.final_tagging_number = null;
          }
        });

        data.data.new_department.forEach((dept, index) => {
          dept.is_added = false;
          dept.new_dept_index = index;
          dept.step_size_array = dept.step_size_array || [];
          dept.user_fix_area = parseInt(dept.ly_area);
          dept.disable_user_fix_area = false;
        });

        return data.data;
      },
    }
  );
};

export const getTotalArea = (payload) => {
  return useQuery(['ly-total-area', payload], () => fetchTotalArea(payload), {
    enabled: true,
    select: (data) => {
      const total_area_data = data.data.data;
      return total_area_data;
    },
  });
};

export const saveStoreConstraints = (handleSuccess) => {
  return useMutation(saveConstraints, {
    onSuccess: (_, payload) => {
      handleSuccess();
      if (payload.cb) {
        payload.cb();
      }
      Toast('Constraints saved successfully', 'success');
    },
  });
};

export const runOptimizer = (successHandler) => {
  return useMutation((payload) => runStoreOptimizer(payload), {
    onSuccess: (_, payload) => {
      successHandler(payload);
    },
    onError: (errResponse) => {
      const errorData = errResponse?.response?.data?.error;
      if (errorData) {
        const htmlError = (
          <ul>
            {errorData.map((el, i) =>
              !el.status ? <li key={i}>{el.message}</li> : null
            )}
          </ul>
        );

        return Toast(htmlError, 'error');
      }
      Toast('Something Went Wrong', 'error');
    },
  });
};
