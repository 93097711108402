import { useQuery } from 'react-query';
import axiosInstance from 'Utils/axios';

const fetchStoreData = ({ season, compareAgainst, level_flag, store_id }) => {
  const reqestPayload = {
    season,
    compare_against: compareAgainst,
    level_flag,
  };

  return axiosInstance.post(
    `optimize/store-master/${store_id}/`,
    reqestPayload
  );
};

const fetchStoreDeptFilters = ({ season, store_id }) => {
  const reqestPayload = {
    season,
  };

  return axiosInstance.post(`optimize/filters/${store_id}/`, reqestPayload);
};

const fetchOptimizedSeasons = ({ season, store_id }) => {
  const reqestPayload = {
    season,
  };

  return axiosInstance.post(`optimize/seasons/${store_id}/`, reqestPayload);
};

const fetchtotalArea = ({ payload = {}, store_id }) => {
  const reqestPayload = {
    season: {
      year: payload?.year,
      name: payload?.name,
    },
    level_flag: payload?.level_flag,
  };

  return axiosInstance.post(
    `optimize/optimized-area/${store_id}/`,
    reqestPayload
  );
};

const fetchFloorPlanData = ({ store_id, floor, department }) => {
  const requestPayload = {
    floor,
    department,
  };

  return axiosInstance.post(
    `department/floor_plan/${store_id}/`,
    requestPayload
  );
};

const fetchFloorPlanDetails = ({ store_id, season }) => {
  const requestPayload = {
    season,
  };

  return axiosInstance.post(
    `available-floor-details/${store_id}/`,
    requestPayload
  );
};

export const getOptimizerData = (payload) => {
  return useQuery(['optimizer', payload], () => fetchStoreData(payload), {
    enabled: false,
    select: (data) => {
      return data.data.data;
    },
  });
};

export const getSOptimizerDeptFilters = (payload) => {
  return useQuery(
    ['store-optimizer-filters', payload],
    () => fetchStoreDeptFilters(payload),
    {
      enabled: false,
      select: (data) => {
        const storeDepartmentFilters = data.data.data;

        if (storeDepartmentFilters.store_detail) {
          storeDepartmentFilters.store_detail.title = `${storeDepartmentFilters.store_detail.store_number}-${storeDepartmentFilters.store_detail.store_name}`;
        }
        storeDepartmentFilters.metric = storeDepartmentFilters.metric.map(
          (metricItem) => {
            metricItem.value = metricItem.name;
            metricItem.label = metricItem.name;

            return metricItem;
          }
        );

        storeDepartmentFilters.compare_against =
          storeDepartmentFilters.compare_against.map((compareOption) => {
            compareOption.label = compareOption.name;

            return compareOption;
          });

        storeDepartmentFilters.season = storeDepartmentFilters.season.map(
          (seasonItem) => {
            seasonItem.label = `${seasonItem.year} ${seasonItem.name}`;
            return seasonItem;
          }
        );

        return storeDepartmentFilters;
      },
    }
  );
};

export const getSeasons = (payload) => {
  return useQuery(
    ['optimized-seasons', payload],
    () => fetchOptimizedSeasons(payload),
    {
      enabled: false,
      select: (data) => {
        const seasons = data.data.data.map((seasonItem) => {
          seasonItem.label = `${seasonItem.year} ${seasonItem.name}`;
          return seasonItem;
        });

        return seasons;
      },
    }
  );
};

export const getFloorPlanElasticity = (payload) => {
  return useQuery(
    ['floor-plan-elasticity', payload],
    () => fetchFloorPlanData(payload),
    {
      enabled: false,
      select: (data) => {
        const floorPlanElasticity = data.data.data;
        return floorPlanElasticity;
      },
    }
  );
};

export const getAvailableFloorPlanDetails = (payload) => {
  return useQuery(
    ['available_floor_plan_details', payload],
    () => fetchFloorPlanDetails(payload),
    {
      enabled: false,
      select: (data) => {
        const floorPlanDetails = data.data;

        return floorPlanDetails;
      },
    }
  );
};

export const getOptimizedTotalArea = (payload) => {
  return useQuery(
    ['optimized-total-area', payload],
    () => fetchtotalArea(payload),
    {
      enabled: true,
      select: (data) => {
        const total_area_data = data.data.data;
        return total_area_data;
      },
    }
  );
};
