import StoreDetailContainer from 'molecules/StoreDetailContainer';
import React from 'react';
import { useParams } from 'react-router-dom';
import {
  getOptimizerData,
  getSOptimizerDeptFilters,
} from 'services/optimizer/optimizer';
import {
  floorHeadCells,
  floorPlanAreaHeadCells,
  storeHeadCells,
} from './components/data';

const headCells = {
  store: storeHeadCells,
  floor_plan: floorPlanAreaHeadCells,
  floor: floorHeadCells,
};

// const filterHeadCellsForCompare = (tableHeadCells, compareAgainstMetric) => {
//   const toggleHeads = [
//     'optimized_area',
//     'optimized_margin',
//     'optimized_margin_persqft',
//     'optimized_revenue',
//     'optimized_revenue_persqft',
//   ];
//   if (compareAgainstMetric?.value) {
//     const headCells = tableHeadCells?.map((cell) => {
//       if (toggleHeads.indexOf(cell.column_name) > -1 && cell.group) {
//         cell.subColumns = cell.subColumns?.map((subColumn) => {
//           subColumn.isVisible = true;

//           return subColumn;
//         });

//         cell.subColumns[1].label = compareAgainstMetric.name;
//       }

//       return cell;
//     });
//     return headCells;
//   } else {
//     const headCells = tableHeadCells?.map((cell) => {
//       if (toggleHeads.indexOf(cell.column_name) > -1 && cell.group) {
//         cell.subColumns = cell.subColumns?.map((subColumn, index) => {
//           delete subColumn.subLabel;

//           if (index > 0) {
//             subColumn.isVisible = false;
//           }

//           return subColumn;
//         });
//       }

//       return cell;
//     });
//     return headCells;
//   }
// };

const OptimizerPage = () => {
  const params = useParams();
  // const [searchParams] = useSearchParams();

  // const season = searchParams.get('optimized_season');

  return (
    <StoreDetailContainer
      getStoreData={getOptimizerData}
      getStoreDeptFilters={getSOptimizerDeptFilters}
      headCells={headCells[params.type]}
      isoptimizer
      exportEndpoint={`optimize/store-master/${params?.id}/export/`}
    />
  );
};

export default OptimizerPage;
