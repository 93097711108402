import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Checkbox from '@mui/material/Checkbox';
import CustomDialog from 'components/CustomDialog';
import CustomText from 'components/CustomText';

import { ReactComponent as UncheckedIcon } from 'assets/svg/unchecked.svg';
import { ReactComponent as CheckedIcon } from 'assets/svg/check.svg';
import { FLOORS_STRING } from 'config/constants';
import Loader from 'components/Loader';

const styles = (theme) => ({
  floorsContainer: {
    ...theme.content.flexStyles.flexCol,
    ...theme.content.flexStyles.flexAlignBetweenStart,
  },
  floorItem: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignStartCenter,
    margin: '12px 0',
  },
  labelText: {
    ...theme.typography.fontSizes.normalText,
    color: theme.palette.stroke,
    marginTop: '16px',
  },
  text: {
    ...theme.typography.fontSizes.normalText,
    color: theme.palette.textDark,
  },
  checkBoxIcon: {
    fontSize: '10px !important',
    color: theme.palette.stroke,
    background: 'transparent !important',
    marginRight: 8,
  },
});

const useStyles = makeStyles(styles);

const FloorLevels = ({
  season,
  floorOptions,
  floorPlanOptions,
  handleOpen,
  handleClose,
  isLoading,
  runOptimizer,
}) => {
  const classes = useStyles();
  const [selectedFloors, setSelectedFloors] = useState([]);

  const onSelectChange = (event) => {
    const value = parseInt(event.target.name);
    const values = selectedFloors;
    const index = values?.indexOf(value);

    if (index > -1) {
      values?.splice(index, 1);
    } else {
      values?.push(value);
    }

    setSelectedFloors([...values]);
  };

  const handleButtonClick = () => {
    runOptimizer('floor', season, selectedFloors, floorPlanOptions);
  };

  useEffect(() => {
    if (floorOptions?.length) {
      setSelectedFloors([...floorOptions]);
    }
  }, [floorOptions?.length]);

  return (
    <CustomDialog
      open
      handleOpen={handleOpen}
      handleClose={handleClose}
      disableClose={isLoading}
      handleButtonClick={handleButtonClick}
      footer={true}
      showButton={
        selectedFloors?.length > 0 && floorPlanOptions?.length > 0 && !isLoading
      }
      isTransparent={false}
    >
      <div style={{ height: 256, width: 256 }}>
        {isLoading ? (
          <Loader />
        ) : (
          <div className={classes.floorsContainer}>
            <div>
              <CustomText
                content={'Select Floors'}
                variant='body1'
                classes={classes.labelText}
              />
              {floorOptions?.map((floor) => {
                return (
                  <div key={`floor-${floor}`} className={classes.floorItem}>
                    <Checkbox
                      name={`${floor}`}
                      checked={selectedFloors?.indexOf(floor) > -1}
                      icon={<UncheckedIcon />}
                      checkedIcon={<CheckedIcon />}
                      className={classes.checkBoxIcon}
                      onChange={(e) => onSelectChange(e)}
                    />
                    <CustomText
                      content={FLOORS_STRING[floor]}
                      variant='body1'
                      classes={classes.text}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </CustomDialog>
  );
};

export default FloorLevels;
