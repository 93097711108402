import React from 'react';
import { connect } from 'react-redux';
import LoginComponent from './components/loginComponent.jsx';
import { useNavigate } from 'react-router-dom';

const Auth = (props) => {
  return (
    <>
      <LoginComponent tenantId={props.tenantId} history={useNavigate()} />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    tenantId: state.authReducer.tenantId,
  };
};

export default connect(mapStateToProps, null)(Auth);
