import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Checkbox, Tooltip } from '@mui/material';
import CustomCard from 'components/CustomCard';
import Table from 'components/Table';
import CustomText from 'components/CustomText';
import LoadingOverlay from 'components/LoadingOverlay';
import { headCells } from './data';
import TableAction from './TableAction';
import AddDepartment from './AddDepartment';

import AddIcon from '../../../assets/svg/add_filled.svg';
import AddIconDisabled from '../../../assets/svg/add_filled_dark.svg';
import { ReactComponent as UncheckedIcon } from '../../../assets/svg/unchecked.svg';
import { ReactComponent as CheckedIcon } from '../../../assets/svg/check.svg';
import Toast from 'components/Toast';
import { getHeight } from 'utils';
// import Helper from 'components/Helper';
// import { helperTexts } from 'config/constants';

const styles = (theme) => ({
  dataHeader: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignStartCenter,
    padding: '24px',
    width: '100%',
  },
  dataFilter: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignStartCenter,
    marginRight: 16,
  },
  dataFilterText: {
    ...theme.typography.fontSizes.normalText,
    color: theme.palette.textDark,
  },
  tableCard: {
    ...theme.content.card,
    width: '100%',
    margin: '16px 0',
  },
  addDepartmentContainer: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignStartCenter,
    width: 'fit-content',
    margin: 16,
    cursor: theme.content.cursor.pointer,
    transition: 'all 0.3s',
    '&:active': {
      transform: 'scale(0.95)',
    },
  },
  addDepartmentContainerDisabled: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignStartCenter,
    width: 'fit-content',
    cursor: theme.content.cursor.notAllowed,
    margin: 16,
  },
  content: {
    ...theme.typography.fontSizes.normalText,
    color: theme.palette.textPrimary,
  },
  contentDisabled: {
    ...theme.typography.fontSizes.normalText,
    color: theme.palette.strokeDark,
  },
  addIcon: {
    height: 16,
    width: 16,
    marginLeft: 8,
  },
  checkBoxIcon: {
    fontSize: '10px !important',
    color: theme.palette.stroke,
    background: 'transparent !important',
  },
  helper: {
    marginLeft: 8,
  },
});

const useStyles = makeStyles(styles);

const StoreConstraints = (props) => {
  const classes = useStyles();
  const [deletedDepartments, setDeletedDepartments] = useState(true);
  const [addedDepartments, setAddedDepartments] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [openAddDepartmentDialog, setOpenAddDepartmentDialog] = useState(false);

  const {
    storeDepartmentDetails,
    season,
    newDepartments,
    storeDetails,
    selectedColumns,
    addNewDepartment,
    addDepartment,
    removeDepartment,
    increaseValue,
    decreaseValue,
    selectAll,
    setFixedArea,
    toggleEditable,
    toggleFixableArea,
    isLoadingTableData,
  } = props;

  const handleOpenAddDepartmentDialog = () => {
    setOpenAddDepartmentDialog(true);
  };

  const scrollToBottom = () => {
    let elem = document.getElementById('table-container');
    elem?.scroll({ top: elem.scrollHeight });
  };

  const handleCloseAddDepartmentDialog = () => {
    setOpenAddDepartmentDialog(false);
    setTimeout(scrollToBottom, 0);
  };

  const filterData = () => {
    let data = [...storeDepartmentDetails];

    if (!deletedDepartments) {
      data = data?.filter((item) => !item.delete_flag);
    }

    if (addedDepartments) {
      setDeletedDepartments(false);
      data = data?.filter((item) => item.is_added || item.new_dept_flag);
    }

    setTableData([...data]);
  };

  useEffect(() => {
    if (storeDepartmentDetails?.length) {
      filterData();
    }
  }, [storeDepartmentDetails]);

  useEffect(() => {
    filterData();
  }, [deletedDepartments, addedDepartments]);

  const tableAction = (index, isDeleted) => {
    return (
      <TableAction
        index={index}
        isDeleted={isDeleted}
        addDepartment={addDepartment}
        removeDepartment={removeDepartment}
      />
    );
  };

  return (
    <>
      <CustomCard cardStyles={classes.tableCard}>
        <div className={classes.dataHeader}>
          <div className={classes.dataFilter}>
            <Checkbox
              checked={deletedDepartments}
              icon={<UncheckedIcon />}
              checkedIcon={<CheckedIcon />}
              className={classes.checkBoxIcon}
              onChange={() => {
                if (addedDepartments) {
                  return Toast(
                    "You can't view deleted department when you are viewing only added departments",
                    'warning'
                  );
                }
                setDeletedDepartments(!deletedDepartments);
              }}
            />
            <CustomText
              content={'Show Deleted Departments'}
              variant={'body1'}
              classes={classes.dataFilterText}
            />
          </div>
          <div className={classes.dataFilter}>
            <Checkbox
              checked={addedDepartments}
              icon={<UncheckedIcon />}
              checkedIcon={<CheckedIcon />}
              className={classes.checkBoxIcon}
              onChange={() => setAddedDepartments(!addedDepartments)}
            />
            <CustomText
              content={'Show Only Added Departments'}
              variant={'body1'}
              classes={classes.dataFilterText}
            />
          </div>
        </div>
        <div>
          <LoadingOverlay
            loader={isLoadingTableData || !season?.year}
            text='Fetching Data'
            position='absolute'
            minHeight={480}
            maxHeight={`calc(100vh - ${getHeight('table-container')})`}
          />
          <Table
            isConstraint
            headCells={headCells}
            data={tableData || []}
            selectedColumns={selectedColumns}
            tableAction={tableAction}
            onIncrease={increaseValue}
            onDecrease={decreaseValue}
            selectColumn={selectAll}
            setFixedArea={setFixedArea}
            toggleEditable={toggleEditable}
            toggleCheckbox={toggleFixableArea}
            tableHeight={480}
            isLoading={isLoadingTableData}
            noPagination
            id='store-table'
          />
        </div>
      </CustomCard>
      {newDepartments?.length > 0 ? (
        <div
          className={classes.addDepartmentContainer}
          onClick={() => handleOpenAddDepartmentDialog()}
        >
          <CustomText
            content={'Add floor department'}
            variant='body1'
            classes={classes.content}
          />
          <img src={AddIcon} alt='add' className={classes.addIcon} />
          {/* <div className={classes.helper}>
              <Helper title={helperTexts.add.text} link={helperTexts.add.link} />
            </div> */}
        </div>
      ) : (
        <Tooltip
          arrow
          title={`All departments are already added, there are no more departments to be added.`}
          classes={{ popper: classes.tooltipPopper }}
        >
          <div className={classes.addDepartmentContainerDisabled}>
            <CustomText
              content={'Add floor department'}
              variant='body1'
              classes={classes.contentDisabled}
            />
            <img src={AddIconDisabled} alt='add' className={classes.addIcon} />
            {/* <div className={classes.helper}>
          <Helper title={helperTexts.add.text} link={helperTexts.add.link} />
        </div> */}
          </div>
        </Tooltip>
      )}
      {openAddDepartmentDialog && (
        <AddDepartment
          floors={storeDetails?.no_of_floor}
          departments={newDepartments || []}
          addNewDepartment={addNewDepartment}
          handleOpen={handleOpenAddDepartmentDialog}
          handleClose={handleCloseAddDepartmentDialog}
        />
      )}
    </>
  );
};

export default StoreConstraints;
