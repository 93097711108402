import { helperTexts } from 'config/constants';

export const headCells = [
  {
    id: 'floor_str',
    sortBy: 'floor',
    column_name: 'floor_str',
    type: 'text',
    isVisible: true,
    isSticky: true,
    isLink: false,
    isSearchable: false,
    isSelectable: true,
    disablePadding: false,
    textTransform: 'uppercase',
    colSpan: 1,
    minWidth: 200,
    colWidth: '14%',
    label: 'Floor',
  },
  {
    id: 'floor_plan_area',
    column_name: 'floor_plan_area',
    type: 'text',
    isVisible: true,
    isSticky: true,
    isLink: false,
    isSearchable: false,
    isSelectable: true,
    disablePadding: false,
    textTransform: 'uppercase',
    colSpan: 1,
    minWidth: 220,
    colWidth: '14%',
    label: 'Floor Plan area',
  },
  {
    id: 'department',
    column_name: 'department',
    type: 'text',
    isVisible: true,
    isLink: false,
    isSticky: true,
    isSearchable: false,
    isSelectable: true,
    disablePadding: false,
    textTransform: 'uppercase',
    colSpan: 1,
    minWidth: 250,
    colWidth: '12%',
    label: 'Dept.',
  },
  {
    id: 'space_elasticity_tag',
    sortBy: 'space_elasticity_tag_number',
    column_name: 'space_elasticity_tag',
    type: 'chart',
    isVisible: true,
    isLink: false,
    isSearchable: false,
    isSelectable: false,
    disablePadding: false,
    textTransform: 'uppercase',
    colSpan: 1,
    minWidth: 148,
    colWidth: '16%',
    label: 'Elasticity',
    rightIcon: 'test',
    helperText: helperTexts.elasticity.text,
    helperLink: helperTexts.elasticity.link,
  },
  {
    id: 'elastic_confidence',
    sortBy: 'elastic_confidence_number',
    column_name: 'elastic_confidence',
    type: 'elasticity',
    isVisible: true,
    isLink: false,
    isSearchable: false,
    isSelectable: false,
    disablePadding: false,
    textTransform: 'uppercase',
    colSpan: 1,
    minWidth: 158,
    colWidth: '13%',
    label: 'Elasticity Confidence',
    helperText: helperTexts.confidence.text,
    helperLink: helperTexts.confidence.link,
  },
  {
    id: 'sister_store_name',
    column_name: 'sister_store_name',
    sortBy: 'sister_store_number',
    type: 'text',
    prefixId: 'sister_store_number',
    isVisible: false,
    isLink: false,
    isSearchable: false,
    isSelectable: false,
    disablePadding: false,
    textTransform: 'uppercase',
    colSpan: 1,
    minWidth: 200,
    colWidth: '10%',
    label: 'Sister Store',
    helperText: helperTexts.sister_store.text,
    helperLink: helperTexts.sister_store.link,
  },
  {
    id: 'cluster',
    column_name: 'cluster',
    type: 'text',
    isVisible: true,
    isLink: false,
    isSearchable: false,
    sortBy: 'assortmentNum',
    isSelectable: false,
    disablePadding: false,
    prefix: 'TIER ',
    prefixId: 'tier',
    colSpan: 1,
    minWidth: 172,
    colWidth: '10%',
    label: 'Tier Cluster',
    helperText: helperTexts.tier_cluster.text,
    helperLink: helperTexts.tier_cluster.link,
  },
  {
    id: 'store_dept_index',
    column_name: 'store_dept_index',
    type: 'text',
    isVisible: false,
    isLink: false,
    isSearchable: false,
    isSelectable: false,
    disablePadding: false,
    colSpan: 1,
    minWidth: 152,
    colWidth: '6%',
    label: 'Store Dept. Index',
    helperText: helperTexts.store_dept_index.text,
    helperLink: helperTexts.store_dept_index.link,
  },
  {
    id: 'dept_index',
    column_name: 'dept_index',
    type: 'number',
    isVisible: false,
    isLink: false,
    isSearchable: false,
    isSelectable: false,
    disablePadding: false,
    colSpan: 1,
    minWidth: 96,
    colWidth: '6%',
    label: 'Dept Index',
    helperText: helperTexts.dept_index.text,
    helperLink: helperTexts.dept_index.link,
  },
  {
    id: 'rev_area_index',
    column_name: 'rev_area_index',
    type: 'number',
    isVisible: false,
    isLink: false,
    isSearchable: false,
    isSelectable: false,
    disablePadding: false,
    colSpan: 1,
    minWidth: 160,
    colWidth: '6%',
    label: 'Revenue Area Index',
    helperText: helperTexts.revenue_area_index.text,
    helperLink: helperTexts.revenue_area_index.link,
  },
  {
    id: 'Total Area',
    sortBy: 'actual_total_area',
    column_name: 'actual_total_area',
    type: 'text',
    isVisible: true,
    isLink: false,
    isSearchable: false,
    isSelectable: false,
    disablePadding: false,
    colSpan: 1,
    minWidth: 148,
    label: 'Total Area',
    group: true,
    canSortChildren: true,
    subColumns: [
      {
        id: 'actual_total_area',
        column_name: 'actual_total_area',
        type: 'numeric',
        isVisible: true,
        isSearchable: false,
        isSelectable: false,
        disablePadding: false,
        colSpan: 1,
        minWidth: 112,
        textTransform: 'uppercase',
        colWidth: '7%',
        label: `Store`,
      },
      {
        id: 'compare_actual_total_area',
        column_name: 'compare_actual_total_area',
        type: 'numeric',
        isVisible: false,
        isSearchable: false,
        isSelectable: false,
        disablePadding: false,
        colSpan: 1,
        minWidth: 104,
        textTransform: 'uppercase',
        colWidth: '7%',
      },
      {
        id: 'delta_total_area',
        column_name: 'delta_total_area',
        type: 'delta',
        isVisible: false,
        isSearchable: false,
        isSelectable: false,
        disablePadding: false,
        colSpan: 1,
        minWidth: 96,
        textTransform: 'uppercase',
        colWidth: '7%',
        label: 'Delta',
      },
    ],
  },
  {
    id: 'Revenue',
    column_name: 'actual_revenue',
    sortBy: 'actual_revenue',
    type: 'number',
    isVisible: true,
    isLink: false,
    isSearchable: false,
    isSelectable: false,
    disablePadding: false,
    minWidth: 148,
    colSpan: 1,
    prefix: '$',
    label: 'Revenue',
    group: true,
    canSortChildren: true,
    subColumns: [
      {
        id: 'actual_revenue',
        column_name: 'actual_revenue',
        type: 'number',
        isVisible: true,
        isSearchable: false,
        isSelectable: false,
        disablePadding: false,
        colSpan: 1,
        minWidth: 112,
        textTransform: 'uppercase',
        prefix: '$',
        colWidth: '7%',
        label: `Store`,
      },
      {
        id: 'compare_actual_revenue',
        column_name: 'compare_actual_revenue',
        type: 'number',
        isVisible: false,
        isSearchable: false,
        isSelectable: false,
        disablePadding: false,
        colSpan: 1,
        minWidth: 104,
        textTransform: 'uppercase',
        prefix: '$',
        colWidth: '7%',
      },
      {
        id: 'delta_revenue',
        column_name: 'delta_revenue',
        type: 'delta',
        isVisible: false,
        isSearchable: false,
        isSelectable: false,
        disablePadding: false,
        colSpan: 1,
        minWidth: 96,
        textTransform: 'uppercase',
        prefix: '$',
        colWidth: '7%',
        label: 'Delta',
      },
    ],
  },
  {
    id: 'Revenue/ Sq ft',
    column_name: 'actual_revenue_persqft',
    sortBy: 'actual_revenue_persqft',
    type: 'number',
    isVisible: true,
    isLink: false,
    isSearchable: false,
    isSelectable: false,
    disablePadding: false,
    minWidth: 148,
    colSpan: 1,
    label: 'Revenue/ Sq ft',
    prefix: '$',
    group: true,
    canSortChildren: true,
    subColumns: [
      {
        id: 'actual_revenue_persqft',
        column_name: 'actual_revenue_persqft',
        type: 'number',
        isVisible: true,
        isSearchable: false,
        isSelectable: false,
        disablePadding: false,
        colSpan: 1,
        minWidth: 112,
        textTransform: 'uppercase',
        prefix: '$',
        colWidth: '7%',
        label: `Store`,
      },
      {
        id: 'compare_revenue_persqft',
        column_name: 'compare_revenue_persqft',
        type: 'number',
        isVisible: false,
        isSearchable: false,
        isSelectable: false,
        disablePadding: false,
        colSpan: 1,
        minWidth: 104,
        textTransform: 'uppercase',
        prefix: '$',
        colWidth: '7%',
      },
      {
        id: 'delta_revenue_persqft',
        column_name: 'delta_revenue_persqft',
        type: 'delta',
        isVisible: false,
        isSearchable: false,
        isSelectable: false,
        disablePadding: false,
        colSpan: 1,
        minWidth: 96,
        textTransform: 'uppercase',
        prefix: '$',
        colWidth: '7%',
        label: 'Delta',
      },
    ],
  },
  {
    id: 'Margin',
    column_name: 'actual_margin',
    sortBy: 'actual_margin',
    type: 'number',
    isVisible: false,
    isLink: false,
    isSearchable: false,
    isSelectable: false,
    disablePadding: false,
    colSpan: 1,
    minWidth: 128,
    label: 'Margin',
    prefix: '$',
    group: true,
    canSortChildren: true,
    subColumns: [
      {
        id: 'actual_margin',
        column_name: 'actual_margin',
        type: 'number',
        isVisible: true,
        isSearchable: false,
        isSelectable: false,
        disablePadding: false,
        colSpan: 1,
        minWidth: 112,
        textTransform: 'uppercase',
        prefix: '$',
        colWidth: '7%',
        label: `Store`,
      },
      {
        id: 'compare_actual_margin',
        column_name: 'compare_actual_margin',
        type: 'number',
        isVisible: false,
        isSearchable: false,
        isSelectable: false,
        disablePadding: false,
        colSpan: 1,
        minWidth: 104,
        textTransform: 'uppercase',
        prefix: '$',
        colWidth: '7%',
      },
      {
        id: 'delta_margin',
        column_name: 'delta_margin',
        type: 'delta',
        isVisible: false,
        isSearchable: false,
        isSelectable: false,
        disablePadding: false,
        colSpan: 1,
        minWidth: 96,
        textTransform: 'uppercase',
        prefix: '$',
        colWidth: '7%',
        label: 'Delta',
      },
    ],
  },
  {
    id: 'Margin/ Sq ft',
    column_name: 'margin_persqft',
    sortBy: 'margin_persqft',
    type: 'number',
    isVisible: false,
    isLink: false,
    isSearchable: false,
    isSelectable: false,
    disablePadding: false,
    minWidth: 132,
    colSpan: 1,
    label: 'Margin/ Sq ft',
    prefix: '$',
    group: true,
    canSortChildren: true,
    subColumns: [
      {
        id: 'margin_persqft',
        column_name: 'margin_persqft',
        type: 'number',
        isVisible: true,
        isSearchable: false,
        isSelectable: false,
        disablePadding: false,
        colSpan: 1,
        minWidth: 112,
        textTransform: 'uppercase',
        prefix: '$',
        colWidth: '7%',
        label: `Store`,
      },
      {
        id: 'compare_margin_persqft',
        column_name: 'compare_margin_persqft',
        type: 'number',
        isVisible: false,
        isSearchable: false,
        isSelectable: false,
        disablePadding: false,
        colSpan: 1,
        minWidth: 104,
        textTransform: 'uppercase',
        prefix: '$',
        colWidth: '7%',
      },
      {
        id: 'delta_margin_persqft',
        column_name: 'delta_margin_persqft',
        type: 'delta',
        isVisible: false,
        isSearchable: false,
        isSelectable: false,
        disablePadding: false,
        colSpan: 1,
        minWidth: 96,
        textTransform: 'uppercase',
        prefix: '$',
        colWidth: '7%',
        label: 'Delta',
      },
    ],
  },
];
