import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import CustomDialog from 'components/CustomDialog';
import SingleSelect from 'components/SingleSelect';
import { KeyboardArrowRight } from '@mui/icons-material';
import CustomCard from 'components/CustomCard';
import CustomText from 'components/CustomText';
import Loader from 'components/Loader';
import { getConstraintFilters } from 'services/constraints/constraints';
import { getAvailableFloorPlanDetails } from 'services/optimizer/optimizer';
import Helper from 'components/Helper';
import { helperTexts } from 'config/constants';

const styles = (theme) => ({
  linkButton: {
    textDecoration: 'none',
    width: '100%',
  },
  optimizeText: {
    ...theme.typography.fontSizes.normalText,
    color: theme.palette.stroke,
    margin: '16px 0',
  },
  levelContainer: {
    ...theme.content.flexStyles.flexCol,
    ...theme.content.flexStyles.flexAlignStartCenter,
    alignItems: 'initial',
    width: '100%',
  },
  loadingContainer: {
    ...theme.content.flexStyles.flexCol,
    ...theme.content.flexStyles.flexAlignStartCenter,
    height: 180,
    width: '100%',
  },
  buttonCardHovered: {
    background: theme.palette.textPrimary,
    cursor: theme.content.cursor.pointer,
    marginBottom: 24,
    padding: '6px 16px 6px 24px',
  },
  buttonCard: {
    cursor: theme.content.cursor.pointer,
    marginBottom: 24,
    padding: '6px 16px 6px 24px',
  },
  buttonContent: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignBetweenCenter,
  },
  buttonTextHovered: {
    ...theme.typography.fontSizes.normalText,
    color: theme.palette.textWhite,
  },
  buttonText: {
    ...theme.typography.fontSizes.normalText,
    color: theme.palette.textDark,
  },
  arrowIconHovered: {
    color: theme.palette.textWhite,
  },
  arrowIcon: {
    color: theme.palette.textPrimary,
  },
});

const useStyles = makeStyles(styles);

const RunOptimizer = ({
  isLySeason,
  season,
  storeId,
  floors,
  handleOpen,
  handleClose,
  isLoading,
  handleOptimizationByStore,
  handleOptimizationByFloors,
  handleOptimizationByFloorPlan,
}) => {
  const classes = useStyles();
  const [seasonValue, setSeasonValue] = useState(null);
  const [seasonOptions, setSeasonOptions] = useState([]);
  const [floorOptions, setFloorOptions] = useState([]);
  const [floorPlanOptions, setFloorPlanOptions] = useState([]);
  const [isStoreCardHovered, setIsStoreCardHovered] = useState(false);
  const [isFloorCardHovered, setIsFloorCardHovered] = useState(false);
  const [isDeptCardHovered, setIsDeptCardHovered] = useState(false);

  const onSeasonSelect = (_, value) => {
    setSeasonValue(value);
  };

  const {
    isLoading: isFilterLoading,
    data: seasons,
    isFetching: isFilterFetching,
    refetch: refetchConstraintFilters,
  } = getConstraintFilters({
    season: {
      year: season?.year,
      name: season?.name,
    },
    store_id: storeId,
  });

  const {
    isLoading: isFloorPlanDetailsLoading,
    data: floorPlanDetails,
    isFetching: isFloorPlanDetailsFetching,
    refetch: refetchFloorPlanDetails,
  } = getAvailableFloorPlanDetails({
    season: {
      year: seasonValue?.year,
      name: seasonValue?.name,
    },
    store_id: storeId,
  });

  useEffect(() => {
    if (season?.year) {
      if (isLySeason) {
        refetchConstraintFilters();
      } else {
        setSeasonValue(season);
      }
    }
  }, [season]);

  useEffect(() => {
    if (seasons) {
      const nextSeason = seasons?.future_seasons?.find(
        (seasonOption) => seasonOption?.selected
      );

      setSeasonValue(nextSeason);
      setSeasonOptions(seasons.future_seasons);
    }
  }, [seasons]);

  useEffect(() => {
    if (seasonValue?.year) {
      refetchFloorPlanDetails();
    }
  }, [seasonValue]);

  useEffect(() => {
    if (floorPlanDetails) {
      setFloorOptions(floorPlanDetails?.floor_array);
      setFloorPlanOptions(floorPlanDetails?.floor_plan_area_array);
    }
  }, [floorPlanDetails]);

  return (
    <CustomDialog
      open
      handleOpen={handleOpen}
      handleClose={handleClose}
      disableClose={isLoading}
      isTransparent={false}
    >
      <div
        style={{
          width: 280,
          height: 304,
          padding: '0 12px',
        }}
      >
        {isLoading ? (
          <Loader />
        ) : (
          <div>
            {isLySeason && (
              <SingleSelect
                label={'Season'}
                placeholder={'Select Season'}
                selectedValue={seasonValue}
                options={seasonOptions || []}
                optionLabelKey={'label'}
                width={156}
                maxHeight={36}
                loading={
                  isFilterLoading ||
                  isFloorPlanDetailsLoading ||
                  isFilterFetching ||
                  isFloorPlanDetailsFetching
                }
                textTransform='capitalize'
                onSelect={onSeasonSelect}
              />
            )}

            <CustomText
              content={'Select Optimize Level'}
              variant='body1'
              classes={classes.optimizeText}
            />
            {floorOptions?.length > 0 && floorPlanOptions?.length > 0 ? (
              <div className={classes.levelContainer}>
                <div style={{ display: 'flex' }}>
                  <CustomCard
                    cardStyles={
                      isStoreCardHovered && seasonValue?.year
                        ? classes.buttonCardHovered
                        : classes.buttonCard
                    }
                    fullWidth
                  >
                    <div
                      className={classes.buttonContent}
                      onClick={() =>
                        seasonValue?.year &&
                        handleOptimizationByStore(
                          seasonValue,
                          floorOptions,
                          floorPlanOptions
                        )
                      }
                      onMouseEnter={() => setIsStoreCardHovered(true)}
                      onMouseLeave={() => setIsStoreCardHovered(false)}
                    >
                      <CustomText
                        content={'Intra - Store'}
                        variant='body1'
                        classes={
                          isStoreCardHovered && seasonValue?.year
                            ? classes.buttonTextHovered
                            : classes.buttonText
                        }
                      />

                      <KeyboardArrowRight
                        className={
                          isStoreCardHovered && seasonValue?.year
                            ? classes.arrowIconHovered
                            : classes.arrowIcon
                        }
                      />
                    </div>
                  </CustomCard>
                  <div
                    className={classes.helper}
                    style={{ marginTop: -8, marginLeft: 3 }}
                  >
                    <Helper
                      title={helperTexts.intra_store_optimization.text}
                      link={helperTexts.intra_store_optimization.link}
                    />
                  </div>
                </div>
                {floors > 1 ? (
                  <div style={{ display: 'flex' }}>
                    <CustomCard
                      cardStyles={
                        isFloorCardHovered && seasonValue?.year
                          ? classes.buttonCardHovered
                          : classes.buttonCard
                      }
                      fullWidth
                    >
                      <div
                        className={classes.buttonContent}
                        onClick={() =>
                          seasonValue?.year &&
                          handleOptimizationByFloors(
                            seasonValue,
                            floorOptions,
                            floorPlanOptions
                          )
                        }
                        onMouseEnter={() => setIsFloorCardHovered(true)}
                        onMouseLeave={() => setIsFloorCardHovered(false)}
                      >
                        <CustomText
                          content={'Intra - Floor'}
                          variant='body1'
                          classes={
                            isFloorCardHovered && seasonValue?.year
                              ? classes.buttonTextHovered
                              : classes.buttonText
                          }
                        />
                        <KeyboardArrowRight
                          className={
                            isFloorCardHovered && seasonValue?.year
                              ? classes.arrowIconHovered
                              : classes.arrowIcon
                          }
                        />
                      </div>
                    </CustomCard>
                    <div
                      className={classes.helper}
                      style={{ marginTop: -8, marginLeft: 3 }}
                    >
                      <Helper
                        title={helperTexts.intra_floor_optimization.text}
                        link={helperTexts.intra_floor_optimization.link}
                      />
                    </div>
                  </div>
                ) : null}
                <div style={{ display: 'flex' }}>
                  <CustomCard
                    cardStyles={
                      isDeptCardHovered && seasonValue?.year
                        ? classes.buttonCardHovered
                        : classes.buttonCard
                    }
                    fullWidth
                  >
                    <div
                      className={classes.buttonContent}
                      onClick={() =>
                        seasonValue?.year &&
                        handleOptimizationByFloorPlan(
                          seasonValue,
                          floorOptions,
                          floorPlanOptions
                        )
                      }
                      onMouseEnter={() => setIsDeptCardHovered(true)}
                      onMouseLeave={() => setIsDeptCardHovered(false)}
                    >
                      <CustomText
                        content={'Intra - Floorplan Area'}
                        variant='body1'
                        classes={
                          isDeptCardHovered && seasonValue?.year
                            ? classes.buttonTextHovered
                            : classes.buttonText
                        }
                      />
                      <KeyboardArrowRight
                        className={
                          isDeptCardHovered && seasonValue?.year
                            ? classes.arrowIconHovered
                            : classes.arrowIcon
                        }
                      />
                    </div>
                  </CustomCard>
                  <div
                    className={classes.helper}
                    style={{ marginTop: -8, marginLeft: 3 }}
                  >
                    <Helper
                      title={helperTexts.intra_floor_plan_optimization.text}
                      link={helperTexts.intra_floor_plan_optimization.link}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className={classes.loadingContainer}>
                <Loader />
              </div>
            )}
          </div>
        )}
      </div>
    </CustomDialog>
  );
};

export default RunOptimizer;
