import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import AutoCompleteSearch from 'components/AutoCompleteSearch';
import CustomText from 'components/CustomText';
import { abbreviateNumber } from '../../../utils';
import { getStoreList } from '../../../services/store/storeFilter';
import FloorPlan from 'molecules/FloorPlan';

const styles = (theme) => ({
  fieldLabel: {
    ...theme.typography.fontSizes.normalText,
    color: theme.palette.textDark,
    fontWeight: theme.typography.fontWeight.normal,
    marginRight: 12,
  },
  fieldLabelLink: {
    ...theme.typography.fontSizes.normalText,
    color: theme.palette.textPrimary,
    fontWeight: theme.typography.fontWeight.medium,
    cursor: theme.content.cursor.pointer,
  },
  textValue: {
    ...theme.typography.fontSizes.normalText,
    color: theme.palette.stroke,
    fontWeight: theme.typography.fontWeight.normal,
    textTransform: theme.typography.textTransform.capitalize,
  },
  gridFlex: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignStartCenter,
    maxWidth: '100%',
    flexBasis: '100%',
  },
  gridFlexFirstItem: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignStartCenter,
    height: 60,
    maxWidth: '100%',
    flexBasis: '100%',
  },
});

const useStyles = makeStyles(styles);

const StoreModelInfo = ({ storeDetails }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const season = searchParams.get('season');
  const [store, setStore] = useState(null);

  const classes = useStyles();

  const onStoreSearch = (_, value) => {
    if (value) {
      setStore(value);
      navigate(`/store/${value.store_number}?season=${season}`);
    }
  };

  const {
    isLoading,
    data = [],
    refetch,
  } = getStoreList({
    text: '',
    region: [],
    state: [],
    climate: [],
  });

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (storeDetails) {
      setStore(storeDetails);
    }
  }, [storeDetails]);

  return (
    <Grid container spacing={3} columns={{ sm: 12, md: 12, lg: 16, xl: 16 }}>
      <Grid item xs={12} sm={6} md={4} lg={4.4} xl={4.4}>
        <Grid container spacing={1}>
          <Grid item xs={12} classes={{ root: classes.gridFlex }}>
            <CustomText
              content={'Store: '}
              classes={classes.fieldLabel}
              paragraph={false}
              variant={'body1'}
            />
            <AutoCompleteSearch
              label='Store'
              selectedValue={store}
              placeholder='Enter Store Name'
              options={[store, ...data]?.filter(
                (a, i, self) =>
                  self.findIndex((s) => a?.store_number === s?.store_number) ===
                  i
              )}
              multiple={false}
              loading={isLoading}
              showLabel={false}
              textTransform='uppercase'
              width={'194px'}
              maxWidth={194}
              maxHeight={36}
              textColor={'stroke'}
              onChange={onStoreSearch}
              disableCloseOnSelect={false}
            />
          </Grid>
          <Grid item xs={12} classes={{ root: classes.gridFlex }}>
            <CustomText
              content={'Prototype Store: '}
              classes={classes.fieldLabel}
              paragraph={false}
              variant={'body1'}
            />
            <CustomText
              content={
                storeDetails?.prototype_store_name
                  ? `${
                      storeDetails.prototype_store_number
                    }-${storeDetails.prototype_store_name?.toUpperCase()}`
                  : '-'
              }
              classes={classes.textValue}
              paragraph={false}
              variant={'body1'}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2.2} xl={2.2}>
        <Grid container spacing={1}>
          <Grid item xs={12} classes={{ root: classes.gridFlexFirstItem }}>
            <CustomText
              content={'Region: '}
              classes={classes.fieldLabel}
              paragraph={false}
              variant={'body1'}
            />
            <CustomText
              content={
                storeDetails ? `${storeDetails?.region?.toUpperCase()}` : '-'
              }
              classes={classes.textValue}
              paragraph={false}
              variant={'body1'}
            />
          </Grid>
          <Grid item xs={12} classes={{ root: classes.gridFlex }}>
            <CustomText
              content={'$ Revenue: '}
              classes={classes.fieldLabel}
              paragraph={false}
              variant={'body1'}
            />
            <CustomText
              content={
                storeDetails?.actual_revenue
                  ? `$${abbreviateNumber(
                      storeDetails.actual_revenue
                    ).toUpperCase()}`
                  : '-'
              }
              classes={classes.textValue}
              paragraph={false}
              variant={'body1'}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2.4} xl={2.4}>
        <Grid container spacing={1}>
          <Grid item xs={12} classes={{ root: classes.gridFlexFirstItem }}>
            <CustomText
              content={'Climate: '}
              classes={classes.fieldLabel}
              paragraph={false}
              variant={'body1'}
            />
            <CustomText
              content={storeDetails ? `${storeDetails.climate}` : '-'}
              classes={classes.textValue}
              paragraph={false}
              variant={'body1'}
            />
          </Grid>
          <Grid item xs={12} classes={{ root: classes.gridFlex }}>
            <CustomText
              content={'Revenue/Sq. ft.: '}
              classes={classes.fieldLabel}
              paragraph={false}
              variant={'body1'}
            />
            <CustomText
              content={
                storeDetails?.actual_revenue_per_squarefeet
                  ? `$${abbreviateNumber(
                      storeDetails.actual_revenue_per_squarefeet
                    )}`
                  : '-'
              }
              classes={classes.textValue}
              paragraph={false}
              variant={'body1'}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        <Grid container spacing={1}>
          <Grid item xs={12} classes={{ root: classes.gridFlexFirstItem }}>
            <CustomText
              content={'Total Area (Sq. Ft.): '}
              classes={classes.fieldLabel}
              paragraph={false}
              variant={'body1'}
            />
            <CustomText
              content={
                storeDetails?.area_square_feet
                  ? abbreviateNumber(
                      storeDetails.area_square_feet
                    ).toUpperCase()
                  : '-'
              }
              classes={classes.textValue}
              paragraph={false}
              variant={'body1'}
            />
          </Grid>
          <Grid item xs={12} classes={{ root: classes.gridFlex }}>
            <CustomText
              content={'Last Re-Modeled: '}
              classes={classes.fieldLabel}
              paragraph={false}
              variant={'body1'}
            />
            <CustomText
              content={storeDetails?.last_remodelled_date || '-'}
              classes={classes.textValue}
              paragraph={false}
              variant={'body1'}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2.7} xl={2.7}>
        <Grid container spacing={1}>
          <Grid item xs={12} classes={{ root: classes.gridFlexFirstItem }}>
            <CustomText
              content={'#Floor: '}
              classes={classes.fieldLabel}
              paragraph={false}
              variant={'body1'}
            />
            <CustomText
              content={
                storeDetails?.no_of_floor ? `${storeDetails.no_of_floor}` : '-'
              }
              classes={classes.textValue}
              paragraph={false}
              variant={'body1'}
            />
          </Grid>
          <Grid item xs={12} classes={{ root: classes.gridFlex }}>
            <CustomText
              content={'#Department: '}
              classes={classes.fieldLabel}
              paragraph={false}
              variant={'body1'}
            />
            <CustomText
              content={
                storeDetails?.no_of_departments
                  ? `${storeDetails.no_of_departments}`
                  : '-'
              }
              classes={classes.textValue}
              paragraph={false}
              variant={'body1'}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={1.3} xl={1.3}>
        <Grid container spacing={1}>
          <Grid item xs={12} classes={{ root: classes.gridFlexFirstItem }}>
            <FloorPlan
              season={season}
              fieldLabelLinkClass={classes.fieldLabelLink}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default StoreModelInfo;
