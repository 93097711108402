import React, { memo, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import { ShowChart } from '@mui/icons-material';

import { styles } from './styles';
import { abbreviateNumberK } from '../../utils';

import AddIcon from '../../assets/svg/add_outlined.svg';
import SubtractIcon from '../../assets/svg/subtract_outlined.svg';
import EditIcon from '../../assets/images/edit.png';
import { ReactComponent as UncheckedIcon } from '../../assets/svg/unchecked.svg';
import { ReactComponent as CheckedIcon } from '../../assets/svg/check.svg';

const useStyles = makeStyles(styles);

const TableBodyCell = (props) => {
  const classes = useStyles();
  const [animate, setAnimate] = useState(null);

  const {
    id,
    data,
    showInput,
    fixedInput,
    dataIndex,
    deleteFlag,
    newFlag,
    isTotal,
    elasiticity,
    elasiticityConfidence,
    rowDisabled,
    inputDisableKey,
    rangeKey,
    rangeLength,
    rangeIndex,
    rangeIndexKey,
    minRangeIndex,
    maxRangeIndex,
    isLink,
    prefixIdValue,
    prefix,
    type,
    floor,
    totalFloors,
    storeId,
    department,
    textTransform,
    rowIndex,
    handleOpenChart,
    tableAction,
    urlPrefix,
    queryParams,
    onIncrease,
    onDecrease,
    setFixedArea,
    toggleEditable,
    toggleCheckbox,
    showIsFixed,
    headCellIndex,
  } = props;

  const [input, setinput] = useState(data);

  const onChange = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      setinput(e.target.value);
    }
  };

  useEffect(() => {
    if (type === 'input') {
      setinput(data);
    }
  }, [data, rowDisabled]);

  const onKeyPress = (id, dataIndex, e) => {
    if (e?.keyCode == 13) {
      setFixedArea(id, dataIndex, input, true, true);
    }
  };

  const toggleHandler = (key, dataIndex) => {
    toggleEditable(key, dataIndex);
  };

  const isDisabled =
    newFlag === 1 ||
    elasiticity === null ||
    elasiticityConfidence?.toLowerCase() === 'low';

  if (id === 'actions') {
    return tableAction(dataIndex, deleteFlag, storeId, totalFloors);
  }
  if (type === 'input') {
    return (
      <>
        {showInput ? (
          <TextField
            id={`${rowIndex}-input-${id}`}
            value={rowDisabled ? input?.toLocaleString() || '' : input}
            inputMode='numeric'
            autoComplete='off'
            onFocus={(e) =>
              e?.currentTarget?.setSelectionRange(
                e?.currentTarget?.value?.length,
                e?.currentTarget?.value?.length
              )
            }
            // autoFocus
            disabled={rowDisabled || deleteFlag === 1}
            onBlur={() => {
              setFixedArea(id, dataIndex, input, true, true);
            }}
            onChange={(e) => {
              // setFixedArea(id, dataIndex, e.target.value, false, false)
              onChange(e);
            }}
            onClick={() =>
              rowDisabled && toggleHandler(inputDisableKey, dataIndex)
            }
            // onClick={rowDisabled && toggleHandler(disabled, dataIndex)}
            onKeyDown={(e) => onKeyPress(id, dataIndex, e)}
            inputRef={(input) => input && input.focus()}
            classes={{
              root: classes.textFieldRoot,
            }}
            InputProps={{
              startAdornment: rowDisabled && (
                <InputAdornment position='start'>
                  <IconButton
                    disableRipple
                    disabled={deleteFlag === 1}
                    classes={{
                      disabled: classes.rangeIconDisabled,
                    }}
                    onClick={() => {
                      toggleHandler(inputDisableKey, dataIndex);
                    }}
                  >
                    <img
                      src={EditIcon}
                      alt='edit'
                      className={classes.editIcon}
                    />
                  </IconButton>
                </InputAdornment>
              ),
              classes: {
                root: classes.inputRoot,
                input: classes.inputField,
              },
            }}
          />
        ) : null}
      </>
    );
  }
  if (type === 'checkbox') {
    return (
      <div className={classes.checkField}>
        <Checkbox
          name={`${rowIndex}`}
          disabled={deleteFlag === 1}
          checked={data === 1}
          icon={<UncheckedIcon />}
          checkedIcon={<CheckedIcon />}
          onChange={() => toggleCheckbox(isDisabled, id, dataIndex)}
          className={
            isDisabled
              ? classnames(classes.checkBoxIcon, classes.rangeIconDisabled)
              : classes.checkBoxIcon
          }
          classes={{ disabled: classes.rangeIconDisabled }}
        />
      </div>
    );
  }
  if (type === 'range') {
    return (
      <div className={classes.text}>
        <IconButton
          disableRipple
          disabled={
            (minRangeIndex > -1 && rangeIndex - 1 <= minRangeIndex) ||
            rangeIndex <= 0 ||
            fixedInput === 1 ||
            deleteFlag === 1
          }
          className={classes.rangeIcon}
          classes={{
            disabled: classes.rangeIconDisabled,
          }}
          onClick={() => {
            onDecrease(rangeKey, rangeIndexKey, id, dataIndex);
            setAnimate('top');
            setTimeout(() => {
              setAnimate(null);
            }, 350);
          }}
        >
          <img src={SubtractIcon} alt='add' />
        </IconButton>
        <span
          className={
            animate === 'top'
              ? classnames(classes.rangeText, classes.animatedItem)
              : animate === 'bottom'
              ? classnames(classes.rangeText, classes.animatedItemExiting)
              : classnames(classes.rangeText)
          }
          style={{
            color: (fixedInput === 1 || deleteFlag === 1) && '#96A8B9',
          }}
        >
          {prefix && data ? prefix : ''}
          {prefixIdValue && data ? `${prefixIdValue}-` : ''}
          {data || data === 0 ? data : '-'}
        </span>
        <IconButton
          disableRipple
          disabled={
            (maxRangeIndex > -1 && rangeIndex >= maxRangeIndex - 1) ||
            rangeIndex >= rangeLength - 1 ||
            fixedInput === 1 ||
            deleteFlag === 1
          }
          className={classes.rangeIcon}
          classes={{
            disabled: classes.rangeIconDisabled,
          }}
          onClick={() => {
            onIncrease(rangeKey, rangeIndexKey, id, dataIndex);
            setAnimate('bottom');
            setTimeout(() => {
              setAnimate(null);
            }, 350);
          }}
        >
          <img src={AddIcon} alt='add' />
        </IconButton>
      </div>
    );
  }
  if (isLink) {
    return (
      <Link
        to={`${urlPrefix}/${prefixIdValue}?${queryParams}`}
        className={classes.textLink}
      >
        {prefix && data ? prefix : ''}
        {prefixIdValue && data ? `${prefixIdValue}-` : ''}
        {data}
      </Link>
    );
  }
  return (
    <span
      className={
        type === 'chart' || type === 'elasticity'
          ? classes[data?.toLowerCase()]
          : classes.text
      }
      style={{
        justifyContent: type === 'elasticity' && 'center',
      }}
    >
      <Tooltip
        arrow
        title={`${prefix && data ? prefix : ''} ${
          prefixIdValue && data ? `${prefixIdValue}-` : ''
        }${data || data === 0 ? data?.toLocaleString() : '-'}`}
        classes={{ popper: classes[textTransform] }}
      >
        <span style={{ width: '100%' }}>
          {data && data < 0 ? '-' : ''}
          {prefix && data ? prefix : ''}
          {prefixIdValue && data ? `${prefixIdValue}-` : ''}
          {data || data === 0
            ? type === 'number' || type === 'delta'
              ? abbreviateNumberK(Math.abs(data))
              : data.toLocaleString()
            : '-'}
          {showIsFixed && <span style={{ color: '#F05656' }}>*</span>}
          {headCellIndex === 0 && isTotal && (
            <span style={{ color: '#F05656' }}>**</span>
          )}
        </span>
      </Tooltip>

      {type === 'chart' && data ? (
        <ShowChart
          className={classes.chartIcon}
          onClick={() => {
            handleOpenChart(floor, storeId, department);
          }}
        />
      ) : null}
    </span>
  );
};

export default memo(TableBodyCell);
