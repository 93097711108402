import React from 'react';
import { Grid } from '@mui/material';
import SingleSelect from '../../../components/SingleSelect';
import MultiSelect from '../../../components/MultiSelect';

const StoreFilters = ({
  metrics,
  compareAgainst,
  season,
  metricsOptions,
  compareOptions,
  seasonOptions,
  onMetricsSelect,
  onCompareSelect,
  onSeasonSelect,
}) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={6} lg={4}>
        <MultiSelect
          label={'Metrics'}
          placeholder={'Select Metrics'}
          selectedValues={metrics}
          options={metricsOptions}
          disableSearch={true}
          limitTags={1}
          width={156}
          maxHeight={36}
          onSelect={onMetricsSelect}
        />
      </Grid>
      <Grid item xs={6} lg={4}>
        <SingleSelect
          label={'Compare Against'}
          placeholder={'Select Season'}
          selectedValue={compareAgainst}
          options={compareOptions}
          optionLabelKey={'label'}
          width={156}
          maxHeight={36}
          onSelect={onCompareSelect}
        />
      </Grid>
      <Grid item xs={6} lg={4}>
        <SingleSelect
          label={'Season'}
          placeholder={'Select Season'}
          selectedValue={season}
          options={seasonOptions}
          optionLabelKey={'label'}
          width={156}
          maxHeight={36}
          textTransform='capitalize'
          onSelect={onSeasonSelect}
        />
      </Grid>
    </Grid>
  );
};

export default StoreFilters;
