import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import CustomCard from 'components/CustomCard';
import StoreModelInfo from './components/StoreModelInfo';
import StoreDetailsFilters from './components/StoreDetailsFilters';
import { getStoreData, getStoreDeptFilters } from '../../services/store/store';
import BreadCrumb from 'components/BreadCrumb';
import { FLOORS_STRING } from 'config/constants';
import { calculateDiff } from 'utils';

const styles = (theme) => ({
  loader: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignCenter,
    height: 492,
    width: '100%',
    color: theme.palette.textPrimary,
  },
  contentCard: {
    ...theme.content.card,
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignBetweenCenter,
    width: '100%',
    margin: '16px 0 32px 0',
    padding: '24px 32px',
  },
  filterActions: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignCenter,
    flexWrap: theme.content.flexStyles.flexWrap.wrap,
  },
});

const useStyles = makeStyles(styles);

const StoreInfoPage = () => {
  const classes = useStyles();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const season = searchParams.get('season');

  const [compareAgainst, setCompareAgaint] = useState({});
  const [tableData, setTableData] = useState([]);
  const [metricOptions, setMetricOptions] = useState([]);

  const {
    isLoading: isLoadingStoreDepartmentDetails,
    data: storeDepartmentDetails,
    isFetching: isFetchingStoreDepartmentDetails,
    refetch: refetchStoreDepartmentDetails,
  } = getStoreData({
    season: {
      year: season?.split(' ')[0],
      name: season?.split(' ')[1],
    },
    compareAgainst,
    store_id: params?.id,
  });

  const {
    isLoading: isLoadingStoreDeptFilters,
    data: storeDeptFilters,
    isFetching: isFetchingStoreDeptFilters,
    refetch: refetchStoreDeptFilters,
  } = getStoreDeptFilters({
    season: {
      year: season?.split(' ')[0],
      name: season?.split(' ')[1],
    },
    store_id: params?.id,
  });

  useEffect(() => {
    if (params?.id) {
      refetchStoreDeptFilters();
    }
  }, [params, searchParams]);

  useEffect(() => {
    if (params?.id) {
      refetchStoreDepartmentDetails();
    }
  }, [params, searchParams, compareAgainst]);

  useEffect(() => {
    if (storeDepartmentDetails?.length > 0) {
      const data = storeDepartmentDetails.map((store) => {
        const newStore = { ...store };
        newStore.floor_str = FLOORS_STRING[store.floor];
        newStore.actual_revenue_persqft = parseInt(
          store.actual_revenue_persqft
        );
        newStore.margin_persqft = parseInt(store.margin_persqft);
        newStore.compare_revenue_persqft = parseInt(
          store.compare_revenue_persqft
        );
        newStore.compare_margin_persqft = parseInt(
          store.compare_margin_persqft
        );
        newStore.ly_total_area = store.compare_actual_total_area - 5;
        newStore.ly_actual_revenue = store.compare_actual_revenue - 5;
        newStore.ly_revenue_persqft = store.compare_revenue_persqft - 5;
        newStore.ly_actual_margin = store.compare_actual_margin - 5;
        newStore.ly_margin_persqft = store.compare_margin_persqft - 5;
        newStore.assortmentNum = Number(store.tier + store.cluster);

        newStore.delta_total_area = calculateDiff(
          store.actual_total_area,
          store.compare_actual_total_area
        );
        newStore.delta_revenue = calculateDiff(
          store.actual_revenue,
          store.compare_actual_revenue
        );
        newStore.delta_revenue_persqft = calculateDiff(
          store.actual_revenue_persqft,
          store.compare_revenue_persqft
        );
        newStore.delta_margin = calculateDiff(
          store.actual_margin,
          store.compare_actual_margin
        );
        newStore.delta_margin_persqft = calculateDiff(
          store.margin_persqft,
          store.compare_margin_persqft
        );
        return newStore;
      });
      setTableData([...data]);
    }
  }, [storeDepartmentDetails]);

  useEffect(() => {
    if (storeDeptFilters?.metric?.length) {
      setMetricOptions(storeDeptFilters?.metric);
    }
  }, [storeDeptFilters?.metric?.length]);

  const subRoutes = [
    {
      text: storeDeptFilters?.store_detail?.store_number
        ? ` View Store: ${
            storeDeptFilters?.store_detail?.store_number
          }-${storeDeptFilters?.store_detail?.store_name?.toUpperCase()}`
        : null,
    },
  ];

  return (
    <>
      <BreadCrumb subRoutes={subRoutes} queryParams={season} />
      <CustomCard cardStyles={classes.contentCard}>
        <StoreModelInfo storeDetails={storeDeptFilters?.store_detail || null} />
      </CustomCard>
      <StoreDetailsFilters
        metricsOptions={metricOptions}
        seasonOptions={storeDeptFilters?.season || []}
        compareOptions={
          storeDeptFilters?.compare_against
            ? [
                ...storeDeptFilters.compare_against,
                {
                  name: 'Another Store',
                  value: 'another_store',
                  label: 'Another Store',
                },
              ]
            : []
        }
        storeDetails={storeDeptFilters?.store_detail || null}
        storeDepartmentDetails={tableData}
        setCompareAgaintFilter={setCompareAgaint}
        isLoading={isLoadingStoreDeptFilters || isFetchingStoreDeptFilters}
        isLoadingTableData={
          isLoadingStoreDepartmentDetails || isFetchingStoreDepartmentDetails
        }
        compareAgainstFilter={compareAgainst}
      />
    </>
  );
};

export default StoreInfoPage;
