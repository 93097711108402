import React, { useEffect } from 'react';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';

import EnhancedTableHead from './TableHead.jsx';
import EnhancedTableBody from './TableBody.jsx';
import { filterData, getHeight } from '../../utils';
import { useParams } from 'react-router-dom';

function EnhancedTable({
  data,
  selectedColumns,
  headCells,
  tableAction,
  onIncrease,
  onDecrease,
  selectColumn,
  setFixedArea,
  toggleEditable,
  toggleCheckbox,
  isLoading,
  isGroupedData,
  noPagination,
  id,
  urlPrefix,
  queryParams,
  isConstraint,
  tableHeight,
}) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [text, setText] = React.useState('');
  const [filters, setFilters] = React.useState({});
  const params = useParams();
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const onFilter = (selectKey, values) => {
    setFilters((prevState) => ({ ...prevState, [selectKey]: values }));
  };

  const onSearch = (value) => {
    setText(value);
    if (value?.trim()) {
      setPage(0);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setPage(0);
  }, [data]);

  useEffect(() => {
    if (params?.id) {
      setFilters({});
    }
  }, [params?.id]);

  let rows = filterData(data, filters);

  if (id === 'stores-table') {
    rows = rows.filter(({ store_name, store_number }) => {
      return (String(store_number) + '-' + store_name)
        .toLowerCase()
        .includes(text.trim().toLowerCase());
    });
  }

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer
        style={{
          maxHeight: `calc(100vh - ${getHeight(
            'table-container',
            isConstraint
          )})`,
          minHeight: tableHeight,
        }}
        id='table-container'
      >
        <Table stickyHeader aria-label='sticky table' id={id}>
          <colgroup style={{ width: '100%' }}>
            {headCells?.map(({ id, colWidth, group, subColumns }) =>
              group ? (
                subColumns?.map((subColumn) => (
                  <col
                    style={{ width: subColumn.colWidth }}
                    key={subColumn.id}
                  />
                ))
              ) : (
                <col style={{ width: colWidth }} key={id} />
              )
            )}
          </colgroup>
          <EnhancedTableHead
            data={data}
            selectedColumns={selectedColumns}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rows={rows}
            rowCount={rows?.length}
            headCells={headCells}
            onSearch={onSearch}
            text={text}
            selectColumn={selectColumn}
            isLoading={isLoading}
            id={`thead-${id}`}
            filters={filters}
            onFilter={onFilter}
          />
          <EnhancedTableBody
            rows={rows}
            order={order}
            orderBy={orderBy}
            rowCount={rows?.length}
            page={page}
            rowsPerPage={noPagination ? data?.length || 0 : rowsPerPage}
            text={text}
            headCells={headCells}
            tableAction={tableAction}
            urlPrefix={urlPrefix}
            queryParams={queryParams}
            isGroupedData={isGroupedData}
            onIncrease={onIncrease}
            onDecrease={onDecrease}
            setFixedArea={setFixedArea}
            toggleEditable={toggleEditable}
            toggleCheckbox={toggleCheckbox}
          />
        </Table>
      </TableContainer>
      {!noPagination && (
        <TablePagination
          rowsPerPageOptions={[15, 25, 35, 50]}
          component='div'
          count={rows?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Paper>
  );
}

export default EnhancedTable;
