import React, { useEffect } from 'react';
import AutoCompleteSearch from 'components/AutoCompleteSearch';
import { getStoreList } from '../../../services/stores/storeFilters';

const StoreSearchFilter = ({
  region,
  state,
  climate,
  storeNumber,
  setStoreNumber,
}) => {
  const {
    isLoading,
    data = [],
    refetch,
  } = getStoreList({
    text: '',
    region: region?.map(({ value }) => value),
    state: state?.map(({ value }) => value),
    climate: climate?.map(({ value }) => value),
  });

  useEffect(() => {
    refetch();
  }, [region, state, climate]);

  return (
    <AutoCompleteSearch
      label='Store'
      selectedValues={storeNumber}
      placeholder='Enter Store Name'
      multiple
      options={[...storeNumber, ...data]?.filter(
        (a, i, self) =>
          self.findIndex((s) => a?.store_number === s?.store_number) === i
      )}
      limitTags={1}
      loading={isLoading}
      showLabel={true}
      width={'194px'}
      maxWidth={194}
      maxHeight={36}
      textTransform='uppercase'
      textColor={'textDark'}
      onChange={setStoreNumber}
      disableCloseOnSelect={true}
    />
  );
};

export default StoreSearchFilter;
