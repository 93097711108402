import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import SearchIcon from '../../assets/svg/search.svg';
import CustomText from '../CustomText';

const styles = (theme) => ({
  fieldLabel: {
    ...theme.typography.fontSizes.normalText,
    marginBottom: '4px',
    color: theme.palette.textSecondary,
    fontWeight: theme.typography.fontWeight.medium,
  },
  form: (props) => ({
    width: props.width,
    maxWidth: props.maxWidth,
    margin: '8px 0',
    '& .MuiOutlinedInput-notchedOutline': {
      border: `0.5px solid ${theme.palette.stroke} !important`,
      borderRadius: '2px',
    },
  }),
  autocompleteRoot: (props) => ({
    padding: '0 !important',
    paddingRight: '12px !important',
    maxHeight: props.maxHeight,
  }),
  autocompleteRootMultiple: (props) => ({
    ...theme.typography.fontSizes.selectText,
    color: theme.palette[props.textColor],
    maxHeight: props.maxHeight,
    padding: '0 8px !important',
  }),
  inputFieldEmpty: {
    ...theme.typography.fontSizes.selectText,
    padding: `0 !important`,
    boxSizing: theme.content.boxSizing.borderBox,
    height: '2.25rem',
    color: theme.palette.textDark,

    '&::placeholder': {
      opacity: 1,
    },
  },
  inputFieldMultiple: (props) => ({
    ...theme.typography.fontSizes.selectText,
    padding: `0 8px 0 6px !important`,
    maxWidth: '56%',
    boxSizing: theme.content.boxSizing.borderBox,
    height: '2.25rem',
    color: theme.palette[props.textColor],

    '&::placeholder': {
      opacity: 1,
    },
  }),
  inputField: (props) => ({
    ...theme.typography.fontSizes.selectText,
    padding: `6.5px 0px 6.5px 14px !important`,
    boxSizing: theme.content.boxSizing.borderBox,
    height: '2.25rem',
    color: theme.palette[props.textColor],
    textTransform: theme.typography.textTransform[props.textTransform],

    '&::placeholder': {
      opacity: 1,
    },
  }),
  autoCompletePaper: (props) => ({
    marginTop: '0.15rem',
    width: props.width,
    maxWidth: props.maxWidth,
    border: `0.5px solid ${theme.palette.stroke}`,
    borderRadius: '2px',
    boxShadow: 'none',
  }),
  autoCompleteRenderContent: (props) => ({
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignBetweenCenter,
    maxWidth: '40%',
    margin: 0,
    whiteSpace: 'nowrap',
    overflow: theme.content.overflow.hidden,
    textTransform: theme.typography.textTransform[props.textTransform],
    textOverflow: 'ellipsis',
  }),
  autoCompleteRenderContentValue: (props) => ({
    ...theme.typography.fontSizes.selectText,
    whiteSpace: 'nowrap',
    overflow: theme.content.overflow.hidden,
    textTransform: theme.typography.textTransform[props.textTransform],
    textOverflow: 'ellipsis',
  }),
  autoCompleteRenderContentTag: {
    ...theme.typography.fontSizes.subText,
    marginLeft: 4,
    padding: 4,
    borderRadius: 4,
    background: theme.palette.bgTransparent,
  },
  autoCompleteOption: (props) => ({
    ...theme.typography.fontSizes.selectText,
    fontWeight: theme.typography.fontWeight.light,
    color: theme.palette[props.textColor],
    textTransform: theme.typography.textTransform[props.textTransform],
    paddingLeft: '12px',
    background: 'transparent',

    '&[aria-selected="true"]': {
      background: 'transparent',
    },
  }),
  icon: {
    height: 14,
  },
  clearIcon: {
    width: 14,
    marginRight: 14,
    marginTop: 3,
    background: 'none !important',

    '& .MuiSvgIcon-root': {
      height: '14px',
      width: '14px',
    },
  },
  popupIndicator: {
    display: 'none !important',
  },
  tooltipPopper: (props) => ({
    textTransform: theme.typography.textTransform[props.textTransform],
  }),
});

const useStyles = makeStyles(styles);

const AutoCompleteSearch = (props) => {
  const {
    label,
    selectedValue,
    selectedValues,
    placeholder,
    multiple,
    options,
    limitTags,
    showLabel,
    loading,
    width,
    maxWidth,
    maxHeight,
    textTransform,
    textColor,
    onChange,
    popperHeight,
    disableCloseOnSelect,
  } = props;

  const styleProps = {
    width,
    maxWidth,
    maxHeight: `${maxHeight}px`,
    textTransform,
    textColor,
  };
  const classes = useStyles(styleProps);
  const [open, setOpen] = useState(false);

  return (
    <div style={{ width: '100%' }}>
      {showLabel ? (
        <CustomText
          content={label}
          classes={classes.fieldLabel}
          paragraph={false}
          variant={'body1'}
        />
      ) : null}

      <FormControl
        sx={{ m: 1, width, maxHeight }}
        size='small'
        className={classes.form}
        onBlur={() => {
          setOpen(false);
        }}
      >
        <Autocomplete
          ListboxProps={{ style: { maxHeight: popperHeight || 'default' } }}
          open={open}
          limitTags={1}
          disableClearable={!multiple}
          disableCloseOnSelect={disableCloseOnSelect}
          multiple={multiple}
          value={multiple ? selectedValues : selectedValue}
          options={options}
          getOptionLabel={(option) => option.title}
          isOptionEqualToValue={(option, value) => option.title === value.title}
          loading={loading}
          onChange={(e, val) => {
            if (!disableCloseOnSelect) {
              setOpen(false);
            }
            onChange(e, val);
          }}
          classes={{
            paper: classes.autoCompletePaper,
            option: classes.autoCompleteOption,
            noOptions: classes.autoCompleteOption,
            loading: classes.autoCompleteOption,
            clearIndicator: classes.clearIcon,
            popupIndicator: classes.popupIndicator,
          }}
          renderTags={(value) => {
            return (
              <div
                className={classes.autoCompleteRenderContent}
                onClick={() => {
                  setOpen(!open);
                }}
              >
                <Tooltip
                  arrow
                  disableFocusListener={open}
                  disableHoverListener={open}
                  title={`${value?.map((option) => {
                    return ` ${option.title}`;
                  })}`}
                  classes={{ popper: classes.tooltipPopper }}
                >
                  <div className={classes.autoCompleteRenderContentValue}>
                    {value?.map((option, index) =>
                      index < limitTags
                        ? `${option.title}${value?.length < 2 ? '' : ', '}`
                        : null
                    )}
                  </div>
                </Tooltip>

                {value?.length > 1 ? (
                  <span className={classes.autoCompleteRenderContentTag}>{`+${
                    value?.length - 1
                  }`}</span>
                ) : null}
              </div>
            );
          }}
          renderInput={(params) => {
            params.inputProps.autoComplete = 'new-password';
            return (
              <TextField
                onClick={() => {
                  setOpen(!open);
                }}
                autoComplete='off'
                {...params}
                InputProps={{
                  ...params.InputProps,
                  autoComplete: 'off',
                  placeholder: (
                    multiple ? selectedValues?.length === 0 : !selectedValue
                  )
                    ? placeholder
                    : '',
                  endAdornment: loading ? (
                    <CircularProgress
                      classes={{ root: classes.icon }}
                      size={16}
                    />
                  ) : (
                    <>
                      {params.InputProps.endAdornment}
                      <InputAdornment position='end'>
                        <img
                          src={SearchIcon}
                          alt={'search'}
                          className={classes.icon}
                        />
                      </InputAdornment>
                    </>
                  ),
                  classes: {
                    root: multiple
                      ? classes.autocompleteRootMultiple
                      : classes.autocompleteRoot,
                    input: multiple
                      ? selectedValues?.length > 0
                        ? classes.inputFieldMultiple
                        : classes.inputFieldEmpty
                      : classes.inputField,
                  },
                }}
              />
            );
          }}
        />
      </FormControl>
    </div>
  );
};

AutoCompleteSearch.propTypes = {
  label: PropTypes.string,
  selectedValue: PropTypes.object,
  selectedValues: PropTypes.array,
  placeholder: PropTypes.string,
  multiple: PropTypes.bool,
  options: PropTypes.array,
  limitTags: PropTypes.number,
  showLabel: PropTypes.bool,
  loading: PropTypes.bool,
  width: PropTypes.string,
  maxWidth: PropTypes.number,
  maxHeight: PropTypes.number,
  textTransform: PropTypes.string,
  textColor: PropTypes.oneOf([
    'textPrimary',
    'textSecondary',
    'textDark',
    'textWhite',
    'textBlack',
    'stroke',
    'strokeDark',
  ]),
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
};

export default AutoCompleteSearch;
