import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import StoreFilterActions from './StoreFilterActions';
import StoreFilters from './StoreFilters';
import CustomCard from 'components/CustomCard';
import Table from 'components/Table';
import { headCells } from './data';
import { nullCompareOption } from '../../HomePage/data';
import AnotherStore from './AnotherStore';
import LoadingOverlay from 'components/LoadingOverlay';
import { getHeight } from 'utils';

const styles = (theme) => ({
  dataHeader: {
    padding: '24px',
    width: '100%',
  },
  tableCard: {
    ...theme.content.card,
    width: '100%',
    margin: '16px 0',
  },
});

const useStyles = makeStyles(styles);

const StoreDetailsFilters = ({
  metricsOptions,
  seasonOptions,
  compareOptions,
  storeDetails,
  storeDepartmentDetails,
  setCompareAgaintFilter,
  isLoading,
  isLoadingTableData,
  compareAgainstFilter,
}) => {
  const classes = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();
  const seasonParams = searchParams.get('season');

  const [tableHeadCells, setTableHeadCells] = useState([...headCells]);
  const [metrics, setMetrics] = useState([]);
  const [anotherStorePopup, setAnotherStorePopup] = useState(false);
  const [compareAgainst, setCompareAgaint] = useState({
    value: null,
    label: 'None',
  });
  const [season, setSeason] = useState(null);

  const onMetricsSelect = (_, values) => {
    filterHeadCells(values);
    setMetrics(values);
  };

  const onCompareSelect = (_, value) => {
    if (value?.value === 'another_store') return setCompareAgaint(value);
    filterHeadCellsForCompare(value);
    if (value?.value) {
      setCompareAgaintFilter({ name: value.value });
    } else {
      setCompareAgaintFilter({});
    }

    setCompareAgaint(value);
  };

  const onSeasonSelect = (_, value) => {
    if (value?.label) {
      const searchParams = new URLSearchParams({ season: value.label });
      setSearchParams(searchParams);
    }
  };

  const showDelta = (metrics, compareAgainst) => {
    const deltaMetric = metrics?.some(
      (option) => option.column_name === 'delta_change'
    );

    const showDeltaCol = deltaMetric && compareAgainst?.value;
    return showDeltaCol;
  };

  const getLabel = (metric, season) => {
    if (metric?.value === 'ly') {
      return {
        0: `${season?.year}-${season?.name}`,
        1: `${`${season?.year - 1}-${season?.name}`}`,
        2: 'Delta',
      };
    } else if (metric?.value === 'lly') {
      return {
        0: `${season?.year}-${season?.name}`,
        1: `${`${season?.year - 2}-${season?.name}`}`,
        2: 'Delta',
      };
    } else if (metric?.value === 'prototype_store') {
      return {
        0: `Store Id - ${storeDetails?.store_number}`,
        1: `Prototype Store - ${storeDetails?.prototype_store_number}`,
        2: 'Delta',
      };
    } else {
      return {
        0: `Store Id - ${storeDetails?.store_number}`,
        1: `${
          compareAgainstFilter?.store_number || metric?.store_number
            ? `Store Id - ${
                metric?.store_number || compareAgainstFilter?.store_number
              }`
            : metric?.name
        }`,
        2: 'Delta',
      };
    }
  };

  const filterHeadCellsForCompare = (compareAgainstMetric) => {
    const toggleHeads = [
      'actual_total_area',
      'actual_revenue',
      'actual_revenue_persqft',
      'actual_margin',
      'margin_persqft',
    ];
    const showDeltaColumn = showDelta(metrics, compareAgainstMetric);

    if (compareAgainstMetric?.value) {
      const cells = tableHeadCells?.map((cell) => {
        if (cell.id.includes('sister_store')) {
          if (compareAgainstMetric?.value === 'sister_store') {
            cell.isVisible = true;
          } else {
            cell.isVisible = false;
          }
        }

        if (toggleHeads.indexOf(cell.column_name) > -1 && cell.group) {
          cell.colSpan = cell?.subColumns?.length;
          const labels = getLabel(compareAgainstMetric, season);
          cell.subColumns = cell.subColumns?.map((subColumn, index) => {
            subColumn.isVisible =
              index === 2 ? (showDeltaColumn ? true : false) : true;
            subColumn.label = labels[index];

            if (!subColumn.isVisible) {
              cell.colSpan--;
            }

            return subColumn;
          });
        }
        return cell;
      });

      setTableHeadCells([...cells]);
    } else {
      const cells = tableHeadCells?.map((cell) => {
        if (cell.id.includes('sister_store')) {
          cell.isVisible = false;
        }

        if (toggleHeads?.indexOf(cell.column_name) > -1 && cell.group) {
          const labels = getLabel(compareAgainstMetric, season);
          cell.colSpan = cell?.subColumns?.length;

          cell.subColumns = cell.subColumns?.map((subColumn, index) => {
            subColumn.label = labels[index];

            if (index > 0) {
              subColumn.isVisible = false;
              cell.colSpan--;
            }

            return subColumn;
          });
        }

        return cell;
      });

      setTableHeadCells([...cells]);
    }
  };

  const filterHeadCells = (metrics) => {
    const cells = [...headCells];
    metricsOptions?.forEach((metric) => {
      const showMetric = metrics?.find(
        (metricOption) =>
          metricOption.column_name === metric?.column_name &&
          metricOption.column_name !== 'delta_change'
      );

      cells.forEach((cell) => {
        const labels = getLabel(compareAgainst, season);
        if (cell?.group) {
          cell.subColumns = cell?.subColumns?.map((subColumn, index) => {
            subColumn.label = labels[index];

            if (index > 0 && !compareAgainst?.value) {
              subColumn.isVisible = false;
            }
            return subColumn;
          });
        }

        if (cell.column_name === metric?.column_name && showMetric) {
          cell.isVisible = true;
        } else if (cell.column_name === metric?.column_name && !showMetric) {
          cell.isVisible = false;
        }
      });
    });

    const showDeltaCol = showDelta(metrics, compareAgainst);
    cells?.forEach((cell) => {
      let colSpan = 1;
      if (cell?.group) {
        colSpan = cell?.subColumns?.length;
        cell.subColumns[2].isVisible = showDeltaCol;
        cell?.subColumns?.map((subCol) => {
          if (!subCol.isVisible) {
            colSpan--;
          }
        });
      }
      cell.colSpan = colSpan;
    });

    setTableHeadCells([...cells]);
  };

  useEffect(() => {
    if (compareAgainst?.value === 'another_store') {
      setAnotherStorePopup(true);
    }
  }, [compareAgainst]);

  useEffect(() => {
    if (metricsOptions?.length) {
      const values = metricsOptions?.filter((metric) => metric.selected);
      setMetrics(values);
      filterHeadCells(values);
    }
  }, [metricsOptions?.length]);

  useEffect(() => {
    if (seasonOptions?.length && seasonParams) {
      const season = seasonOptions.find(
        (season) => season.label === seasonParams
      );
      setSeason(season);
    }
  }, [seasonOptions, seasonParams]);

  useEffect(() => {
    if (season?.year) {
      filterHeadCellsForCompare(compareAgainst || compareAgainstFilter);
    }
  }, [season]);

  return (
    <CustomCard cardStyles={classes.tableCard}>
      <div className={classes.dataHeader}>
        <Grid
          container
          spacing={3}
          columns={12}
          justifyContent={'space-between'}
        >
          <Grid item xs={12} sm={6} md={5.5} xl={5.2}>
            <StoreFilters
              metrics={metrics}
              compareAgainst={compareAgainst}
              season={season}
              metricsOptions={metricsOptions}
              compareOptions={[nullCompareOption, ...compareOptions]}
              seasonOptions={seasonOptions}
              onMetricsSelect={onMetricsSelect}
              onCompareSelect={onCompareSelect}
              onSeasonSelect={onSeasonSelect}
              isLoading={isLoading}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6.5}
            xl={6}
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <StoreFilterActions
              season={season}
              seasonOptions={seasonOptions}
              storeDetails={storeDetails}
              storeData={storeDepartmentDetails}
              compareAgainstFilter={compareAgainstFilter}
            />
          </Grid>
        </Grid>
      </div>
      <div>
        <LoadingOverlay
          loader={isLoadingTableData || !season?.year}
          text='Fetching Data'
          position='absolute'
          minHeight={460}
          maxHeight={`calc(100vh - ${getHeight('table-container')})`}
        />
        <Table
          headCells={tableHeadCells}
          data={storeDepartmentDetails}
          tableHeight={460}
          isLoading={isLoadingTableData}
          noPagination
          id='store-table'
        />
      </div>

      {anotherStorePopup && (
        <AnotherStore
          storeId={storeDetails?.store_number}
          season={season}
          filterHeadCellsForCompare={(name) =>
            filterHeadCellsForCompare({
              ...compareAgainst,
              name: `Store Id - ${name}`,
              store_number: typeof name === 'number' && name,
            })
          }
          seasonOptions={seasonOptions}
          handleOpen={() => setAnotherStorePopup(true)}
          handleClose={(val) => {
            if (val) {
              filterHeadCellsForCompare(val);
              setCompareAgaint(val);
            }
            setAnotherStorePopup(false);
          }}
          setCompareAgaintFilter={setCompareAgaintFilter}
        />
      )}
    </CustomCard>
  );
};

export default StoreDetailsFilters;
