import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import { Button } from '@mui/material';
import { MapInteractionCSS } from 'react-map-interaction';
import CustomDialog from 'components/CustomDialog';
import CustomText from 'components/CustomText';
import LoadingOverlay from 'components/LoadingOverlay';
import { getFloorPlanApi } from 'services/generic/generic';

import ExportIcon from '../assets/svg/export_dark.svg';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
// import RefreshIcon from '@mui/icons-material/Refresh';

const styles = (theme) => ({
  actions: {
    position: theme.content.positions.absolute,
    top: 8,
    right: -40,
  },
  actionsHidden: {
    display: 'none',
  },
  zoomIcon: {
    marginBottom: 8,
    border: 'none',
    borderRadius: 4,
    background: theme.palette.textWhite,
    cursor: theme.content.cursor.pointer,
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignCenter,
  },
  zoomActionIcon: {
    fontSize: 22,
  },
  resetIcon: {
    fontSize: 20,
    color: theme.palette.textBlack,
  },
  downloadIcon: {
    height: '22px',
    padding: '4px 0',
  },
  resetIconContainer: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignCenter,
    width: 34,
    marginBottom: 8,
    padding: '2px 8px',
    border: 'none',
    borderRadius: 4,
    background: theme.palette.textWhite,
    cursor: theme.content.cursor.pointer,
    position: theme.content.positions.absolute,
    top: 72,
    right: -40,
  },
  resetIconContainerSafari: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignCenter,
    width: 34,
    marginBottom: 8,
    padding: '2px 8px',
    border: 'none',
    borderRadius: 4,
    background: theme.palette.textWhite,
    cursor: theme.content.cursor.pointer,
    position: theme.content.positions.absolute,
    top: 80,
    right: -38,
  },
});

const useStyles = makeStyles(styles);

const FloorPlan = ({ season, fieldLabelLinkClass }) => {
  const params = useParams();
  const classes = useStyles();

  const [openFloorPlan, setOpenFloorPlan] = useState(false);
  // const [isLoaded, setIsLoaded] = useState(false);
  const [index, setIndex] = useState(0);
  const [scale, setScale] = useState(1);
  const [translation, setTranslation] = useState({
    x: 0,
    y: 0,
  });

  const nextStep = () => setIndex((prevState) => prevState + 1);
  const prevStep = () => setIndex((prevState) => prevState - 1);

  const { data, isLoading, isFetching, refetch } = getFloorPlanApi({
    id: params?.id,
    year: season?.split(' ')[0] || 2021,
    name: season?.split(' ')[1] || 'SPRING',
  });
  const handleOpenFloorPlan = () => {
    setOpenFloorPlan(true);
    if (!data) {
      refetch();
    }
  };
  const handleCloseFloorPlan = () => {
    setOpenFloorPlan(false);
    setIndex(0);
    // setIsLoaded(false);
  };

  const handleZoomEffect = (value) => {
    if (value?.scale === 1) {
      setTranslation({ x: 0, y: 0 });
    } else {
      setTranslation(value?.translation);
    }

    setScale(value?.scale);
  };

  const handleZoomReset = () => {
    setScale(1);
    setTranslation({ x: 0, y: 0 });
  };

  const isSafari =
    /constructor/i.test(window.HTMLElement) ||
    (function (p) {
      return p.toString() === '[object SafariRemoteNotification]';
    })(
      !window['safari'] ||
        (typeof safari !== 'undefined' && window['safari'].pushNotification)
    );

  return (
    <>
      <div onClick={() => handleOpenFloorPlan()}>
        <CustomText
          content={'Floor Plan'}
          classes={fieldLabelLinkClass}
          paragraph={false}
          variant={'body1'}
        />
      </div>
      <CustomDialog
        open={openFloorPlan}
        handleOpen={handleOpenFloorPlan}
        handleClose={handleCloseFloorPlan}
        isTransparent={true}
        parentStyle={{
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
          boxSizing: 'border-box',
          // paddingRight: 0,
        }}
      >
        <Button
          style={{
            minWidth: 0,
            color: 'black',
            visibility: index === 0 ? 'hidden' : 'visible',
          }}
          disabled={index === 0}
          onClick={() => {
            if (index === 0) return;
            handleZoomReset();
            prevStep();
          }}
        >
          <ArrowBackIosIcon />
        </Button>
        <div style={{ minHeight: 500, minWidth: 400 }}>
          {(isLoading || isFetching) && !data?.image_urls?.length && (
            <LoadingOverlay
              loader
              text='Fetching Data'
              position='relative'
              height='500px'
              background='transparent'
              color='#fff'
            />
          )}
          {data?.image_urls?.map((image, idx) => {
            if (index === idx) {
              return (
                <div style={{ position: 'relative' }} key={`image-${idx}`}>
                  <MapInteractionCSS
                    key={idx}
                    value={{
                      scale,
                      translation,
                    }}
                    showControls={index === idx}
                    disablePan={scale === 1}
                    minScale={1}
                    maxScale={20}
                    controlsClass={
                      index === idx ? classes.actions : classes.actionsHidden
                    }
                    plusBtnClass={classes.zoomIcon}
                    minusBtnClass={classes.zoomIcon}
                    plusBtnContents={
                      <AddRoundedIcon className={classes.zoomActionIcon} />
                    }
                    minusBtnContents={
                      <RemoveRoundedIcon className={classes.zoomActionIcon} />
                    }
                    onChange={handleZoomEffect}
                  >
                    <img
                      // onLoad={() => setIsLoaded(true)}
                      src={image.url}
                      style={{
                        maxHeight: idx === index ? '640px' : 0,
                        maxWidth: idx === index ? '640px' : 0,
                        borderRadius: 12,
                        objectFit: 'contain',
                      }}
                      alt={'floor_plan'}
                    />
                  </MapInteractionCSS>
                  {/* {!(isLoading || isFetching) && (
                    <IconButton
                      disableRipple
                      disableFocusRipple
                      className={
                        isSafari
                          ? classes.resetIconContainerSafari
                          : classes.resetIconContainer
                      }
                      onClick={handleZoomReset}
                    >
                      <RefreshIcon
                        className={classnames(
                          classes.zoomActionIcon,
                          classes.resetIcon
                        )}
                      />
                    </IconButton>
                  )} */}
                  {!(isLoading || isFetching) && data?.pdf_url?.url && (
                    <a
                      href={data?.pdf_url?.url}
                      target={'_blank'}
                      download
                      rel='noreferrer noopener'
                      className={
                        isSafari
                          ? classes.resetIconContainerSafari
                          : classes.resetIconContainer
                      }
                    >
                      <img
                        src={ExportIcon}
                        className={classnames(
                          classes.zoomActionIcon,
                          classes.downloadIcon
                        )}
                      />
                    </a>
                  )}
                </div>
              );
            } else {
              return (
                <img
                  // onLoad={() => setIsLoaded(true)}
                  key={`image-${idx}`}
                  src={image.url}
                  style={{
                    height: 0,
                    width: 0,
                    display: 'none',
                  }}
                  alt={'floor_plan'}
                />
              );
            }
          })}
        </div>
        <Button
          style={{
            minWidth: 0,
            color: 'black',
            visibility:
              index + 1 === data?.image_urls?.length ? 'hidden' : 'visible',
          }}
          disabled={index + 1 === data?.image_urls?.length}
          onClick={() => {
            if (index + 1 === data?.image_urls?.length) return;
            handleZoomReset();
            nextStep();
          }}
        >
          <ArrowForwardIosIcon />
        </Button>
      </CustomDialog>
    </>
  );
};

export default FloorPlan;
