import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import ReactApexChart from 'react-apexcharts';
import moment from 'moment';
import { getFloorPlanElasticity } from '../../services/store/store';
import { CircularProgress } from '@mui/material';
import { abbreviateGraphNumberK, roundNearestBound } from 'utils';

const styles = (theme) => ({
  chartContainer: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignCenter,
    height: 360,
    width: 720,
    background: theme.palette.textWhite,

    '& .apexcharts-canvas .apexcharts-legend': {
      justifyContent: 'center',

      '& .apexcharts-legend-series .apexcharts-legend-marker': {
        width: '24px !important',
        height: '8px !important',
        marginRight: 8,
      },
    },
  },
});

const useStyles = makeStyles(styles);

const Chart = ({ storeId, floor, department }) => {
  const classes = useStyles();
  const [series, setSeries] = useState([]);

  const [options, setOptions] = useState({
    chart: {
      height: 350,
      type: 'line',
      zoom: {
        enabled: true,
        type: 'x',
        autoScaleYaxis: true,
      },
      toolbar: {
        export: {
          csv: {
            filename: `${storeId}_${floor}_${department}`,
          },
          svg: {
            filename: `${storeId}_${floor}_${department}`,
          },
          png: {
            filename: `${storeId}_${floor}_${department}`,
          },
        },
      },
    },
    colors: ['#5882cc', '#f39e64'],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'straight',
      width: 2,
    },
    legend: {
      position: 'right',
    },
    toolbar: {
      show: false,
    },

    title: {
      text: 'Trend of Revenue and Actual Area across Fiscal Weeks',
      margin: 24,
      style: {
        fontFamily: `'Poppins', sans-serif`,
        fontWeight: 400,
      },
      align: 'center',
    },
    tooltip: {
      x: {
        formatter: (value) => {
          return `${moment(value).subtract(6, 'days').format('MMM D')}-${moment(
            value
          ).format('ll')}`;
        },
      },
      y: {
        formatter: (value) => {
          return value;
        },
      },
    },
    yaxis: [
      {
        title: {
          text: 'Revenue',
          style: {
            fontFamily: `'Poppins', sans-serif`,
            fontWeight: 500,
          },
        },
        labels: {
          style: {
            fontFamily: `'Poppins', sans-serif`,
            fontWeight: 500,
          },
        },
      },
      {
        opposite: true,
        title: {
          text: 'Area',
          style: {
            fontFamily: `'Poppins', sans-serif`,
            fontWeight: 500,
          },
        },
        labels: {
          style: {
            fontFamily: `'Poppins', sans-serif`,
            fontWeight: 500,
          },
        },
      },
    ],
    xaxis: {
      type: 'datetime',
      title: {
        text: 'Fiscal Year - Week',
        style: {
          fontFamily: `'Poppins', sans-serif`,
          fontWeight: 500,
        },
      },
      labels: {
        rotate: 0,
        rotateAlways: true,
        hideOverlappingLabels: true,
        showDuplicates: false,
        style: {
          fontFamily: `'Poppins', sans-serif`,
          fontWeight: 500,
        },
      },
    },
  });

  const { isLoading, data, isFetching, refetch } = getFloorPlanElasticity({
    store_id: storeId,
    floor,
    department,
  });

  useEffect(() => {
    if (storeId) {
      refetch();
    }
  }, [storeId, floor, department]);

  useEffect(() => {
    if (data) {
      const seriesData = [
        {
          name: 'Revenue',
          data: data?.map((dataPoint) => {
            return {
              y: dataPoint.actual_revenue,
              x: moment(
                `${dataPoint.year_week
                  .toString()
                  .substring(0, 4)}W${dataPoint.year_week
                  .toString()
                  .substring(4, 6)}`
              ).format('ll'),
            };
          }),
        },
        {
          name: 'Area',
          data: data?.map((dataPoint) => {
            return {
              y: dataPoint.actual_total_area,
              x: moment(
                `${dataPoint.year_week
                  .toString()
                  .substring(0, 4)}W${dataPoint.year_week
                  .toString()
                  .substring(4, 6)}`
              ).format('ll'),
            };
          }),
        },
      ];

      const optionsData = {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: true,
            type: 'x',
            autoScaleYaxis: true,
          },
        },
        colors: ['#5882cc', '#f39e64'],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'straight',
          width: 2,
        },
        legend: {
          position: 'right',
        },
        toolbar: {
          show: false,
        },
        title: {
          text: 'Trend of Revenue and Actual Area across Fiscal Weeks',
          margin: 24,
          style: {
            fontFamily: `'Poppins', sans-serif`,
            fontWeight: 400,
          },
          align: 'center',
        },
        yaxis: [
          {
            max: roundNearestBound(
              Math.max.apply(
                Math,
                data?.map(function (o) {
                  return o.actual_revenue;
                })
              )
            ),
            min: 0,
            title: {
              text: 'Revenue',
              style: {
                fontFamily: `'Poppins', sans-serif`,
                fontWeight: 500,
              },
            },
            labels: {
              style: {
                fontFamily: `'Poppins', sans-serif`,
                fontWeight: 500,
              },
              formatter: (value) => {
                return '$' + abbreviateGraphNumberK(value);
              },
            },
          },
          {
            max: roundNearestBound(
              Math.max.apply(
                Math,
                data?.map(function (o) {
                  return o.actual_total_area;
                })
              )
            ),
            opposite: true,
            title: {
              text: 'Area',
              style: {
                fontFamily: `'Poppins', sans-serif`,
                fontWeight: 500,
              },
            },
            labels: {
              style: {
                fontFamily: `'Poppins', sans-serif`,
                fontWeight: 500,
              },
            },
          },
        ],
        xaxis: {
          type: 'datetime',
          title: {
            text: 'Fiscal Year - Week',
            style: {
              fontFamily: `'Poppins', sans-serif`,
              fontWeight: 500,
            },
          },
          labels: {
            rotate: 0,
            rotateAlways: true,
            hideOverlappingLabels: true,
            showDuplicates: false,
            style: {
              fontFamily: `'Poppins', sans-serif`,
              fontWeight: 500,
            },
          },
        },
      };

      setSeries(seriesData);
      setOptions(optionsData);
    }
  }, [data]);

  return (
    <div id='chart' className={classes.chartContainer}>
      {isLoading || isFetching ? (
        <CircularProgress />
      ) : (
        <ReactApexChart
          options={options}
          series={series}
          type='line'
          height={340}
          width={700}
        />
      )}
    </div>
  );
};

export default Chart;
