import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BreadCrumb from 'components/BreadCrumb';
import { helperItems } from 'config/constants';

import ElasticityChart1 from '../../assets/images/elasticity_1.png';
import ElasticityChart2 from '../../assets/images/elasticity_2.png';
import OptimizationTable from '../../assets/images/optimization.png';

const styles = (theme) => ({
  accordionRoot: {
    margin: '16px 0',
    boxShadow: `0px 3px 10px #0000000F`,

    '&::before': {
      background: 'transparent !important',
    },
  },
  accordionBody: {
    borderTop: `0.5px solid ${theme.palette.stroke}`,
    padding: '16px',
  },
  header: {
    ...theme.typography.fontSizes.mainHeaderText,
    fontWeight: theme.typography.fontWeight.semiBold,
    color: theme.palette.mainHeadingColor,
  },
  mainDescription: {
    ...theme.typography.fontSizes.linkText,
    fontWeight: theme.typography.fontWeight.semiBold,
    color: theme.palette.textPrimary,
  },
  details: {
    padding: 16,
  },
  subHeader: {
    ...theme.typography.fontSizes.normalText,
    fontWeight: theme.typography.fontWeight.semiBold,
    color: theme.palette.textDark,
    margin: '4px 0',
  },
  subBody: {
    padding: '0px 16px',
  },
  subLabel: {
    ...theme.typography.fontSizes.normalText,
    color: theme.palette.textDark,
    margin: 0,
  },
  itemNote: {
    ...theme.typography.fontSizes.breadCrumbText,
    fontWeight: theme.typography.fontWeight.semiBold,
    color: theme.palette.strokeDark,
    margin: 0,
  },
  dataItems: {
    ...theme.typography.fontSizes.normalText,
    color: theme.palette.textDark,
  },
  chartsContainer: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignCenter,
    margin: 24,
  },
  charts: {
    maxWidth: '50%',
    height: 500,
    margin: '24px',
  },
  table: {
    height: 400,
    margin: '24px',
  },
});

const useStyles = makeStyles(styles);

const FAQ = () => {
  const classes = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();
  const [expanded, setExpanded] = useState(null);

  useEffect(() => {
    if (searchParams?.get('section')) {
      const id = searchParams.get('section');
      const element = document?.getElementById(id);
      setExpanded(id);
      window?.scrollTo({ top: element?.offsetTop || 0, behavior: 'smooth' });
    } else {
      setExpanded(false);
    }
  }, [searchParams]);

  const subRoutes = [
    {
      text: `FAQ's`,
    },
  ];

  const handleChange = (panel) => (event, isExpanded) => {
    let searchParams;

    if (isExpanded) {
      searchParams = new URLSearchParams({ section: panel });
    } else {
      searchParams = new URLSearchParams();
    }

    setSearchParams(searchParams);
  };

  return (
    <>
      <BreadCrumb subRoutes={subRoutes} />
      <div>
        {helperItems?.map((item) => (
          <Accordion
            id={item.id}
            expanded={expanded === item.id}
            onChange={handleChange(item.id)}
            key={item.id}
            classes={{
              root: classes.accordionRoot,
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Typography className={classes.header}>{item.label}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionBody}>
              <Typography className={classes.mainDescription}>
                {item.description}
              </Typography>
              <div className={classes.details}>
                {item.details?.map((detail, detailIdx) => (
                  <div key={`${item.id}-${detail.label}-${detailIdx}`}>
                    <p className={classes.subHeader}>{detail.label}</p>
                    <div className={classes.subBody}>
                      {detail.description && (
                        <p className={classes.subLabel}>{detail.description}</p>
                      )}
                      <ul>
                        {detail.data?.map((dataItem, idx) =>
                          dataItem && typeof dataItem !== 'object' ? (
                            <li
                              key={`${detail.label}-data-${idx}`}
                              className={classes.dataItems}
                            >
                              {dataItem}
                            </li>
                          ) : (
                            <li
                              className={classes.dataItems}
                              key={`${detail.label}-data-${idx}`}
                            >
                              {dataItem?.description}
                              <ul>
                                {dataItem?.data?.map((item, subIndex) => (
                                  <li
                                    key={`${detail.label}-sublist-${subIndex}`}
                                    className={classes.dataItems}
                                  >
                                    {item}
                                  </li>
                                ))}
                              </ul>
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  </div>
                ))}
              </div>

              {item.note && <p className={classes.itemNote}>{item.note}</p>}
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
      <Accordion
        id={'elasticity'}
        expanded={expanded === 'elasticity'}
        onChange={handleChange('elasticity')}
        classes={{
          root: classes.accordionRoot,
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
        >
          <Typography className={classes.header}>{'Elasticity'}</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionBody}>
          <Typography className={classes.mainDescription}>
            {`Metric that is used to measure the change in the deparments's
            expected revenue in relation to the change in the space allocated`}
          </Typography>
          <div className={classes.chartsContainer}>
            <img
              src={ElasticityChart1}
              alt={'chart_1'}
              className={classes.charts}
            />
            <img
              src={ElasticityChart2}
              alt='chart_2'
              className={classes.charts}
            />
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        id={'optimization_level'}
        expanded={expanded === 'optimization_level'}
        onChange={handleChange('optimization_level')}
        classes={{
          root: classes.accordionRoot,
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
        >
          <Typography className={classes.header}>
            {'Level Of Optimization'}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionBody}>
          <Typography className={classes.mainDescription}>
            {`Metric that is used to measure the change in the deparments's
            expected revenue in relation to the change in the space allocated`}
          </Typography>
          <div className={classes.details}>
            <div>
              <p className={classes.subHeader}>{`Intra- Store optimization`}</p>
              <div className={classes.subBody}>
                <p
                  className={classes.subLabel}
                >{`Departments across the same store would be optimized together. (Interchange of space is allowed across different floors or floorplans)`}</p>
              </div>
            </div>
            <div>
              <p
                className={classes.subHeader}
              >{`Intra- Floor optimization `}</p>
              <div className={classes.subBody}>
                <p
                  className={classes.subLabel}
                >{`Departments across the same floor would be optimized together. (No interchange of space between departments across different floors)`}</p>
              </div>
            </div>
            <div>
              <p
                className={classes.subHeader}
              >{`Intra-Floorplan Area Optimization`}</p>
              <div className={classes.subBody}>
                <p
                  className={classes.subLabel}
                >{`Departments across the same floorplan would be optimized together. (No Interchange of space is allowed across floorplans).`}</p>
              </div>
            </div>
          </div>
          <div className={classes.chartsContainer}>
            <img
              src={OptimizationTable}
              alt={'chart_1'}
              className={classes.table}
            />
          </div>
          <p
            className={classes.itemNote}
          >{`* Note (Illustrative example): Based on the level, the rows with the same colour band are optimized together (interchange of space is allowed between departments of the same color band)`}</p>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default FAQ;
