/* eslint-disable no-undef */
import React from 'react';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import MainLayout from 'layouts/MainLayout';
import { theme } from './theme';
import HomePage from './pages/HomePage';
import StoreInfoPage from './pages/StoreInfoPage';
import ConstraintsPage from './pages/ConstraintsPage';
import OptimizerPage from 'pages/OptimizerPage';
import Auth from 'pages/Auth';
import EmptyLayout from 'layouts/EmptyLayout';
import { Provider } from 'react-redux';
import store from './store';
import MetaDataComponent from './HOC/metadata.jsx';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RequiredAuth from 'routes/RequiredAuth';
import FAQ from 'pages/FAQ';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <CssBaseline />
        <div
          style={{ minHeight: '100vh', background: theme.palette.bgPrimary }}
        >
          <QueryClientProvider client={queryClient}>
            <Provider store={store}>
              <MetaDataComponent>
                <Router>
                  <Routes>
                    <Route
                      path='/login'
                      element={
                        <EmptyLayout>
                          <Auth />
                        </EmptyLayout>
                      }
                    />
                    <Route element={<RequiredAuth />}>
                      <Route
                        path='/'
                        element={
                          <MainLayout>
                            <HomePage />
                          </MainLayout>
                        }
                      />
                      <Route
                        path='/store/:id'
                        element={
                          <MainLayout>
                            <StoreInfoPage />
                          </MainLayout>
                        }
                      />
                      <Route
                        path='/constraints/:id'
                        element={
                          <MainLayout>
                            <ConstraintsPage />
                          </MainLayout>
                        }
                      />
                      <Route
                        //type for optimized route would be one of store/department/floor
                        path='/optimized/:id/:type'
                        element={
                          <MainLayout>
                            <OptimizerPage />
                          </MainLayout>
                        }
                      />
                    </Route>
                    <Route
                      path='/faq/'
                      element={
                        <MainLayout>
                          <FAQ />
                        </MainLayout>
                      }
                    />
                    <Route
                      path='*'
                      element={
                        <MainLayout>
                          <h1>Test</h1>
                        </MainLayout>
                      }
                    />
                  </Routes>
                </Router>
              </MetaDataComponent>
            </Provider>

            {window.location.hostname === 'localhost' && (
              <ReactQueryDevtools initialIsOpen={false} />
            )}
          </QueryClientProvider>
        </div>
      </StyledEngineProvider>
      <ToastContainer />
    </ThemeProvider>
  );
}
