import React from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import CustomDialog from 'components/CustomDialog';
import CustomText from 'components/CustomText';
import { getTotalArea } from '../../../services/constraints/constraints';
import { abbreviateNumber } from '../../../utils';

const styles = (theme) => ({
  header: {
    ...theme.typography.fontSizes.normalText,
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignBetweenCenter,
    ...theme.content.flexStyles.flexWrap,
    padding: '8px 0',
    background: `${theme.palette.bgPrimary} 0% 0% no-repeat padding-box`,
    boxShadow: '0px 0px 20px #2283d11a',
    border: `0.25px solid ${theme.palette.textPrimary}`,
    borderRadius: 4,
    margin: 4,
  },
  headerItem: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignCenter,
    padding: '8px 12px',
    minWidth: '50%',
    maxWidth: '100%',
  },
  headerItemFloor: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignCenter,
    borderLeft: `1px solid ${theme.palette.strokeTable}`,
    padding: '8px 12px',
    minWidth: '50%',
    maxWidth: '100%',
  },
  headerLabel: {
    ...theme.typography.fontSizes.normalText,
    color: theme.palette.textDark,
  },
  headerText: {
    ...theme.typography.fontSizes.normalText,
    color: theme.palette.stroke,
    marginLeft: 8,
  },
  tableContainer: {
    margin: '24px 4px',
    boxShadow: '0px 0px 20px #2283d11a',
    borderRadius: 4,
    width: 'auto',
  },
  tableHead: {
    ...theme.typography.fontSizes.normalText,
    borderBottom: `1px solid ${theme.palette.strokeTable}`,
    borderRight: `1px solid ${theme.palette.strokeTable}`,
    padding: '12px',
    color: theme.palette.textTableHeader,
    background: theme.palette.bgTableHeader,
  },
  tableHeadLastCell: {
    ...theme.typography.fontSizes.normalText,
    borderBottom: `1px solid ${theme.palette.strokeTable}`,
    color: theme.palette.textTableHeader,
    padding: '12px',
    background: theme.palette.bgTableHeader,
  },
  tableCell: {
    ...theme.typography.fontSizes.normalText,
    borderBottom: `1px solid ${theme.palette.strokeTable}`,
    borderRight: `1px solid ${theme.palette.strokeTable}`,
    padding: '12px',
    color: theme.palette.stroke,
  },
  tableLastCell: {
    ...theme.typography.fontSizes.normalText,
    borderBottom: `1px solid ${theme.palette.strokeTable}`,
    padding: '12px',
    color: theme.palette.stroke,
  },
});

const useStyles = makeStyles(styles);

const TotalArea = ({ season, handleOpen, handleClose }) => {
  const classes = useStyles();

  const params = useParams();
  const { isLoading, data, isFetching } = getTotalArea({
    store_id: Number(params?.id),
    season,
  });

  return (
    <CustomDialog
      open
      header={`Season: ${season?.year - 1} ${season?.name?.toUpperCase()}`}
      handleOpen={handleOpen}
      handleClose={handleClose}
      isTransparent={false}
    >
      <div style={{ height: 414, width: 564, textAlign: 'center' }}>
        {isLoading || isFetching ? (
          <CircularProgress />
        ) : (
          <div>
            <Box className={classes.header}>
              <div className={classes.headerItem}>
                <CustomText
                  content='Store Total Area:'
                  classes={classes.headerLabel}
                  variant='body1'
                />
                <Tooltip
                  arrow
                  title={`${data?.store_total_area?.toLocaleString()} sq. ft.`}
                >
                  <span>
                    <CustomText
                      content={`${abbreviateNumber(
                        data?.store_total_area
                      )} sq. ft.`}
                      classes={classes.headerText}
                      variant='body1'
                    />
                  </span>
                </Tooltip>
              </div>
              {data?.store_detail?.map((detail) => (
                <div className={classes.headerItemFloor} key={detail.floor}>
                  <CustomText
                    content={`Floor ${detail.floor}:`}
                    classes={classes.headerLabel}
                    variant='body1'
                  />
                  <Tooltip
                    arrow
                    title={`${detail?.floor_area?.toLocaleString()} sq. ft.`}
                  >
                    <span>
                      <CustomText
                        content={`${abbreviateNumber(
                          detail.floor_area
                        )} sq. ft.`}
                        classes={classes.headerText}
                        variant='body1'
                      />
                    </span>
                  </Tooltip>
                </div>
              ))}
            </Box>
            <TableContainer
              classes={{ root: classes.tableContainer }}
              sx={{ height: 304 }}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell
                      classes={{ root: classes.tableHead }}
                      align='center'
                    >
                      Floor
                    </TableCell>
                    <TableCell classes={{ root: classes.tableHead }}>
                      Floor Plan
                    </TableCell>
                    <TableCell
                      classes={{ root: classes.tableHeadLastCell }}
                      align='center'
                    >
                      Area (sq. ft.)
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.store_detail_list?.map((detail) => (
                    <TableRow key={`${detail.floor}-${detail.floor_plan_area}`}>
                      <TableCell
                        classes={{ root: classes.tableCell }}
                        align='center'
                      >
                        {detail.floor}
                      </TableCell>
                      <TableCell
                        classes={{ root: classes.tableCell }}
                        style={{ textTransform: 'uppercase' }}
                      >
                        <Tooltip arrow title={detail.floor_plan_area}>
                          <span>{detail.floor_plan_area}</span>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        classes={{ root: classes.tableLastCell }}
                        align='center'
                      >
                        <Tooltip
                          arrow
                          title={detail?.area_square_feet?.toLocaleString()}
                        >
                          <span>
                            {detail?.area_square_feet?.toLocaleString()}
                          </span>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      </div>
    </CustomDialog>
  );
};

export default TotalArea;
