import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import CustomDialog from 'components/CustomDialog';
// import CustomText from 'components/CustomText';
import { abbreviateNumber, calculateDiff } from 'utils';
import { getOptimizedTotalArea } from 'services/optimizer/optimizer';
import SimpleTabs from 'components/SimpleTabs';

import EmptyIcon from '../assets/images/empty.png';
import DeltaIcon from '../assets/svg/delta.svg';

const styles = (theme) => ({
  header: {
    ...theme.typography.fontSizes.normalText,
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignBetweenCenter,
    ...theme.content.flexStyles.flexWrap,
    padding: '8px 0',
    background: `${theme.palette.bgPrimary} 0% 0% no-repeat padding-box`,
    boxShadow: '0px 0px 20px #2283d11a',
    border: `0.25px solid ${theme.palette.textPrimary}`,
    borderRadius: 4,
    margin: 4,
  },
  headerItem: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignCenter,
    borderLeft: `1px solid ${theme.palette.strokeTable}`,
    padding: '8px 12px',
    minWidth: '50%',
    maxWidth: '100%',
  },
  headerItemFloor: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignCenter,
    borderLeft: `1px solid ${theme.palette.strokeTable}`,
    padding: '8px 12px',
    minWidth: '50%',
    maxWidth: '100%',
  },
  headerLabel: {
    ...theme.typography.fontSizes.normalText,
    color: theme.palette.textDark,
  },
  headerText: {
    ...theme.typography.fontSizes.normalText,
    color: theme.palette.stroke,
    marginLeft: 8,
  },
  tableContainer: {
    margin: '24px 4px',
    boxShadow: '0px 0px 20px #2283d11a',
    borderRadius: 4,
    width: 'auto',
  },
  tableContainerTop: {
    margin: '24px 4px',
    border: `0.25px solid ${theme.palette.textPrimary}`,
    boxShadow: '0px 0px 20px #2283d11a',
    borderRadius: 4,
    width: 'auto',
  },
  tableHead: {
    ...theme.typography.fontSizes.normalText,
    borderBottom: `1px solid ${theme.palette.strokeTable}`,
    borderRight: `1px solid ${theme.palette.strokeTable}`,
    padding: '12px',
    color: theme.palette.textTableHeader,
    background: theme.palette.bgTableHeader,
  },
  tableHeadLastCell: {
    ...theme.typography.fontSizes.normalText,
    borderBottom: `1px solid ${theme.palette.strokeTable}`,
    color: theme.palette.textTableHeader,
    padding: '12px',
    background: theme.palette.bgTableHeader,
  },
  tableCell: {
    ...theme.typography.fontSizes.normalText,
    borderBottom: `1px solid ${theme.palette.strokeTable}`,
    borderRight: `1px solid ${theme.palette.strokeTable}`,
    padding: '12px',
    color: theme.palette.stroke,
  },
  tableLastCell: {
    ...theme.typography.fontSizes.normalText,
    borderBottom: `1px solid ${theme.palette.strokeTable}`,
    padding: '12px',
    color: theme.palette.stroke,
  },
  tableCellTop: {
    ...theme.typography.fontSizes.normalText,
    borderBottom: `none`,
    borderRight: `1px solid ${theme.palette.strokeTable}`,
    padding: '6px',
    color: theme.palette.stroke,
  },
  tableLastCellTop: {
    ...theme.typography.fontSizes.normalText,
    borderBottom: `none`,
    padding: '4px',
    color: theme.palette.stroke,
  },
  tableZeroState: {
    ...theme.content.flexStyles.flexCol,
    ...theme.content.flexStyles.flexAlignCenter,
  },
  tableZeroStateIcon: {
    height: 64,
    margin: 24,
  },
  tableZeroStateText: {
    ...theme.typography.fontSizes.normalText,
    color: theme.palette.stroke,
  },
  infoText: {
    ...theme.typography.fontSizes.normalText,
    fontWeight: theme.typography.fontWeight.semiBold,
    margin: 0,
    color: theme.palette.strokeDark,
  },
  asterix: {
    color: theme.palette.bgBadge,
    fontWeight: theme.typography.fontWeight.semiBold,
  },
});

const useStyles = makeStyles(styles);

const TotalArea = ({ season, isoptimizer, handleOpen, handleClose }) => {
  // Payload is not same as Detail Page, so while using it as common compoeent
  // pass endpoint and paylod as props
  const classes = useStyles();

  const params = useParams();
  const [tabIndex, setTabIndex] = useState(0);
  const [tableData, setTableData] = useState(null);
  const [storeDetails, setStoreDetails] = useState([]);

  const { isLoading, data, isFetching } = getOptimizedTotalArea({
    store_id: Number(params.id),
    payload: { ...season, level_flag: params?.type },
  });

  const handleChange = (_, newValue) => {
    setTabIndex(newValue);
    filterTableData(tabs[newValue]);
  };

  const tabs = [
    {
      label: 'All',
      id: 'all',
    },
    {
      label: 'Optimizer Results',
      id: 'optimizer',
    },
    {
      label: 'Fixed',
      id: 'fixed',
    },
  ];

  const filterTableData = (tab) => {
    let details = [...tableData.store_detail_list];

    if (tab.id === 'fixed') {
      details = details.filter((item) => {
        return item.fix_flag === 1;
      });
    } else if (tab.id === 'optimizer') {
      details = details.filter((item) => {
        return item.fix_flag === 0;
      });
    } else {
      details = details.filter((item) => {
        return item.fix_flag === 2;
      });
    }

    setStoreDetails([...details]);
  };

  useEffect(() => {
    if (data?.store_total_area > 0) {
      setTableData({ ...data });
    }
  }, [data]);

  useEffect(() => {
    if (tableData?.store_total_area > 0) {
      filterTableData(tabs[tabIndex]);
    }
  }, [tableData]);

  return (
    <CustomDialog
      open
      handleOpen={handleOpen}
      handleClose={handleClose}
      isTransparent={false}
      header={`Season: ${season?.year} ${season?.name?.toUpperCase()}`}
    >
      <div
        style={{
          minHeight: 420,
          width: 600,
          textAlign: 'center',
        }}
      >
        {isLoading || isFetching ? (
          <CircularProgress />
        ) : (
          <div>
            <p style={{ marginTop: 0, fontWeight: 500 }}>Area Optimization</p>
            <TableContainer classes={{ root: classes.tableContainerTop }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell
                      classes={{ root: classes.tableHead }}
                      align='center'
                    ></TableCell>
                    <TableCell
                      classes={{ root: classes.tableHead }}
                      align='center'
                    >
                      Optimized Area
                    </TableCell>
                    <TableCell
                      classes={{ root: classes.tableHead }}
                      align='center'
                    >
                      Fixed Area
                    </TableCell>
                    <TableCell
                      classes={{
                        root: classes.tableHeadLastCell,
                      }}
                      align='center'
                    >
                      Total Area
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell
                      classes={{ root: classes.tableCellTop }}
                      align='center'
                    >
                      Store
                    </TableCell>
                    <TableCell
                      classes={{ root: classes.tableCellTop }}
                      align='center'
                      style={{ textTransform: 'uppercase' }}
                    >
                      <Tooltip
                        arrow
                        title={tableData?.optmized_floor_total_area?.toLocaleString()}
                      >
                        <span>
                          {abbreviateNumber(
                            tableData?.optmized_floor_total_area
                          )}
                        </span>
                      </Tooltip>
                    </TableCell>
                    <TableCell
                      classes={{ root: classes.tableCellTop }}
                      align='center'
                    >
                      <Tooltip
                        arrow
                        title={tableData?.fixed_floor_total_area?.toLocaleString()}
                      >
                        <span>
                          {abbreviateNumber(tableData?.fixed_floor_total_area)}
                        </span>
                      </Tooltip>
                    </TableCell>
                    <TableCell
                      classes={{
                        root: classes.tableLastCellTop,
                      }}
                      align='center'
                    >
                      <Tooltip
                        arrow
                        title={tableData?.store_total_area.toLocaleString()}
                      >
                        <span>
                          {abbreviateNumber(tableData?.store_total_area)}
                        </span>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                  {tableData?.store_detail?.map((detail) => (
                    <TableRow key={`${detail?.floor}`}>
                      <TableCell
                        classes={{ root: classes.tableCellTop }}
                        align='center'
                      >
                        {`Floor-${detail.floor}`}
                      </TableCell>
                      <TableCell
                        classes={{ root: classes.tableCellTop }}
                        align='center'
                      >
                        <Tooltip
                          arrow
                          title={detail?.optmized_floor_area?.toLocaleString()}
                        >
                          <span>
                            {abbreviateNumber(detail.optmized_floor_area)}
                          </span>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        classes={{ root: classes.tableCellTop }}
                        align='center'
                      >
                        <Tooltip
                          arrow
                          title={detail?.fixed_floor_area?.toLocaleString()}
                        >
                          <span>
                            {abbreviateNumber(detail.fixed_floor_area)}
                          </span>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        classes={{ root: classes.tableLastCellTop }}
                        align='center'
                      >
                        <Tooltip
                          arrow
                          title={detail?.all_floor_area?.toLocaleString()}
                        >
                          <span>{abbreviateNumber(detail.all_floor_area)}</span>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {isoptimizer ? (
              <Box
                sx={{
                  marginBottom: '16px',
                  padding: '0 12px',
                  borderBottom: '1px solid',
                  color: 'divider',
                }}
              >
                <SimpleTabs
                  items={tabs}
                  value={tabIndex}
                  handleChange={handleChange}
                />
              </Box>
            ) : null}
            <TableContainer
              classes={{ root: classes.tableContainer }}
              sx={{ height: 304 }}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {params?.type === 'floor' ? (
                      <TableCell
                        classes={{ root: classes.tableHead }}
                        align='center'
                      >
                        Floor
                      </TableCell>
                    ) : null}
                    <TableCell classes={{ root: classes.tableHead }}>
                      Floor Plan
                    </TableCell>
                    <TableCell classes={{ root: classes.tableHead }}>
                      <span style={{ whiteSpace: 'pre-wrap' }}>
                        Optimized Area{' '}
                      </span>
                      <span style={{ whiteSpace: 'nowrap' }}>(sq. ft.)</span>
                    </TableCell>
                    <TableCell
                      classes={{
                        root: isoptimizer
                          ? classes.tableHead
                          : classes.tableHeadLastCell,
                      }}
                      align='center'
                    >
                      <span style={{ whiteSpace: 'pre-wrap' }}>LY Area </span>
                      <span style={{ whiteSpace: 'nowrap' }}>(sq. ft.)</span>
                    </TableCell>
                    {isoptimizer && (
                      <TableCell
                        classes={{ root: classes.tableHeadLastCell }}
                        align='center'
                      >
                        <img
                          src={DeltaIcon}
                          alt='change'
                          height={10}
                          style={{ marginRight: 8 }}
                        />
                        Change
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {storeDetails.length > 0 ? (
                    storeDetails?.map((detail, index) => (
                      <TableRow
                        key={`${detail?.floor ? detail.floor : index}-${
                          detail.floor_plan_area
                        }`}
                      >
                        {params?.type === 'floor' ? (
                          <TableCell
                            classes={{ root: classes.tableCell }}
                            align='center'
                          >
                            {detail.floor}
                          </TableCell>
                        ) : null}
                        <TableCell
                          classes={{ root: classes.tableCell }}
                          style={{ textTransform: 'uppercase' }}
                        >
                          <Tooltip arrow title={detail.floor_plan_area}>
                            <span>
                              {detail.floor_plan_area}
                              {isoptimizer &&
                                (detail.fix_flag === 1 ||
                                  (detail.fix_flag === 2 &&
                                    !detail?.only_optimized)) && (
                                  <span className={classes.asterix}>{'*'}</span>
                                )}
                            </span>
                          </Tooltip>
                        </TableCell>
                        <TableCell
                          classes={{ root: classes.tableCell }}
                          align='center'
                        >
                          <Tooltip
                            arrow
                            title={detail?.optimized_area?.toLocaleString()}
                          >
                            <span>
                              {abbreviateNumber(detail.optimized_area)}
                            </span>
                          </Tooltip>
                        </TableCell>
                        <TableCell
                          classes={{
                            root: classes.tableCell,
                          }}
                          align='center'
                        >
                          <Tooltip
                            arrow
                            title={detail.ly_area?.toLocaleString()}
                          >
                            <span>{abbreviateNumber(detail.ly_area)}</span>
                          </Tooltip>
                        </TableCell>
                        {isoptimizer && (
                          <TableCell
                            classes={{
                              root: classes.tableLastCell,
                            }}
                            align='center'
                          >
                            <Tooltip
                              arrow
                              title={calculateDiff(
                                detail?.optimized_area,
                                detail.ly_area
                              ).toLocaleString()}
                            >
                              <span>
                                {abbreviateNumber(
                                  calculateDiff(
                                    detail?.optimized_area,
                                    detail.ly_area
                                  )
                                )}
                              </span>
                            </Tooltip>
                          </TableCell>
                        )}
                      </TableRow>
                    ))
                  ) : (
                    <TableCell
                      colSpan={params?.type === 'floor' ? 5 : 4}
                      style={{ height: 260 }}
                      classes={{ root: classes.tableCell }}
                      align='center'
                    >
                      <div className={classes.tableZeroState}>
                        <img
                          src={EmptyIcon}
                          alt={'empty'}
                          className={classes.tableZeroStateIcon}
                        />
                        <p className={classes.tableZeroStateText}>No Data</p>
                      </div>
                    </TableCell>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {isoptimizer && (
              <p className={classes.infoText}>
                {' '}
                <span className={classes.asterix}>*</span> - Floor plans having
                departments with fixed area
              </p>
            )}
          </div>
        )}
      </div>
    </CustomDialog>
  );
};

export default TotalArea;
