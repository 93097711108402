import React, { useEffect, useReducer } from 'react';
import { useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import { getStoreFilters } from '../../../services/stores/storeFilters';
import { customFilter } from '../../../utils';

import DataHeader from './DataHeader';
import DataFilters from './DataFilter';

const initialState = {
  regionOptions: [],
  stateOptions: [],
  climateOptions: [],
  seasonOptions: [],
  filters: null,
  regions: [],
  states: [],
  climates: [],
  storeNumber: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_DATA':
      return {
        ...state,
        regionOptions: action.payload.region,
        stateOptions: action.payload.state,
        climateOptions: action.payload.climate,
        seasonOptions: action.payload.season,
        filters: action.payload,
      };
    case 'SET_REGION': {
      const regions = action.payload;
      const stateOptions = customFilter(state.filters.state, 'region', regions);
      const states = customFilter(state.states, 'value', stateOptions);
      let climateOptions = customFilter(
        state.filters.climate,
        'region',
        regions
      );
      climateOptions = customFilter(climateOptions, 'state', states);
      const climates = customFilter(state.climates, 'value', climateOptions);
      let storeNumber = customFilter(state.storeNumber, 'region', regions);
      storeNumber = customFilter(storeNumber, 'state', states);
      storeNumber = customFilter(storeNumber, 'climate', climates);

      return {
        ...state,
        stateOptions,
        climateOptions,
        states,
        climates,
        regions,
        storeNumber,
      };
    }
    case 'SET_STATE': {
      const states = action.payload;
      let climateOptions = customFilter(
        state.filters.climate,
        'region',
        state.regions
      );
      climateOptions = customFilter(climateOptions, 'state', states);
      const climates = customFilter(state.climates, 'value', climateOptions);
      let storeNumber = customFilter(state.storeNumber, 'state', states);
      storeNumber = customFilter(storeNumber, 'climate', climates);

      return {
        ...state,
        climateOptions,
        states,
        climates,
        storeNumber,
      };
    }
    case 'SET_CLIMATE': {
      const climates = action.payload;
      let storeNumber = customFilter(state.storeNumber, 'climate', climates);

      return {
        ...state,
        climates,
        storeNumber,
      };
    }
    case 'SET_STORE_NUMBER': {
      return {
        ...state,
        storeNumber: action.payload,
      };
    }
    case 'RESET_FILTERS': {
      return {
        ...state,
        stateOptions: state.filters.state,
        climateOptions: state.filters.climate,
        states: [],
        climates: [],
        regions: [],
        storeNumber: [],
      };
    }
    default:
      return state;
  }
};

const StoreListFilters = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const seasonParams = searchParams.get('season');

  const [state, dispatch] = useReducer(reducer, initialState);
  const { filterActionsClass, setPayload, setSeason, season, filters } = props;

  const onRegionSelect = (_, value) => {
    dispatch({
      type: 'SET_REGION',
      payload: value,
    });
  };

  const onStateSelect = (_, value) => {
    dispatch({
      type: 'SET_STATE',
      payload: value,
    });
  };

  const onClimateSelect = (_, value) => {
    dispatch({
      type: 'SET_CLIMATE',
      payload: value,
    });
  };

  const setStoreNumber = (_, stores) => {
    dispatch({
      type: 'SET_STORE_NUMBER',
      payload: stores,
    });
  };

  const onSeasonSelect = (_, value) => {
    if (value?.label) {
      const searchParams = new URLSearchParams({ season: value.label });
      setSearchParams(searchParams);
    }
  };

  const onFilter = () => {
    setPayload({
      store_number: state?.storeNumber?.map(({ store_number }) => store_number),
      region: state?.regions?.map(({ value }) => value),
      state: state?.states?.map(({ value }) => value),
      climate: state?.climates?.map(({ value }) => value),
    });
  };

  const onReset = () => {
    dispatch({
      type: 'RESET_FILTERS',
      payload: data,
    });
    setPayload({
      store_number: [],
      region: [],
      state: [],
      climate: [],
    });
  };

  const { data, refetch, isLoading } = getStoreFilters('filters');

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (data) {
      let season = data?.season?.find(
        (seasonOption) =>
          seasonOption?.label?.toLowerCase() === seasonParams?.toLowerCase()
      );

      if (!season?.year) {
        season =
          data?.season?.find((seasonOption) => seasonOption?.selected) ||
          data?.season[0];
      }

      setSeason(season);
      dispatch({
        type: 'SET_DATA',
        payload: data,
      });
    }
  }, [data, seasonParams]);

  return (
    <>
      <DataFilters
        isLoading={isLoading}
        filterActionsClass={filterActionsClass}
        regions={state.regions}
        states={state.states}
        climates={state.climates}
        storeNumber={state.storeNumber}
        regionOptions={state.regionOptions}
        stateOptions={state.stateOptions}
        climateOptions={state.climateOptions}
        onRegionSelect={onRegionSelect}
        onStateSelect={onStateSelect}
        onClimateSelect={onClimateSelect}
        setStoreNumber={setStoreNumber}
        onApplyFilter={onFilter}
        onResetFilter={onReset}
      />
      <DataHeader
        season={season}
        seasonOptions={state.seasonOptions}
        onSeasonSelect={onSeasonSelect}
        filters={filters}
      />
    </>
  );
};

StoreListFilters.propTypes = {
  filterActionsClass: PropTypes.string,
  setSeason: PropTypes.func,
  season: PropTypes.object,
};

export default StoreListFilters;
