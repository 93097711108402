import * as React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import LinearProgress from '@mui/material/LinearProgress';
import { visuallyHidden } from '@mui/utils';
import { makeStyles } from '@mui/styles';
import SortIcon from '../../assets/svg/sort.svg';
import { calculateStickyLeft } from '../../utils';
import MultiSelect from 'components/MultiSelect';
import SimpleSearch from 'components/SimpleSearch';
import Helper from 'components/Helper';

import DeltaIcon from '../../assets/svg/delta.svg';
import { ReactComponent as UncheckedIcon } from '../../assets/svg/unchecked.svg';
import { ReactComponent as CheckedIcon } from '../../assets/svg/check.svg';

const styles = (theme) => ({
  tableHeadContainer: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignCenter,
  },
  customTableCell: {
    ...theme.typography.fontSizes.normalText,
    borderBottom: `1px solid ${theme.palette.strokeTable}`,
    borderRight: `2px solid ${theme.palette.strokeTable}`,
    color: theme.palette.textTableHeader,
    background: theme.palette.bgTableHeader,
  },
  customTableLastCell: {
    ...theme.typography.fontSizes.normalText,
    color: theme.palette.textTableHeader,
    borderBottom: `1px solid ${theme.palette.strokeTable}`,
    background: theme.palette.bgTableHeader,
  },
  customTableSubHead: {
    background: theme.palette.bgTableHeaderDark,
  },
  customTabBorderBottom: {
    borderBottom: `2px solid ${theme.palette.strokeTable}`,
  },
  label: {
    ...theme.content.flexStyles.flexCol,
    ...theme.typography.fontSizes.normalText,
    color: theme.palette.textTableHeader,
  },
  subLabel: {
    ...theme.typography.fontSizes.subText,
    fontWeight: theme.typography.fontWeight.light,
    color: theme.palette.textTableHeader,
  },
  sortIcon: {
    height: 20,
    margin: 0,
  },
  checkBoxIcon: {
    fontSize: '10px !important',
    color: theme.palette.stroke,
    background: 'transparent !important',
  },
  loader: {
    zIndex: 2,
    height: 2,
    padding: 0,
    border: 'none',
    background: 'transparent',
  },
  loaderBar: {
    background: theme.palette.stroke,
  },
});

const useStyles = makeStyles(styles);

function EnhancedTableHead(props) {
  const classes = useStyles();
  const {
    order,
    orderBy,
    onRequestSort,
    selectColumn,
    rows,
    headCells,
    isLoading,
    id,
    onSearch,
    text,
    filters,
    onFilter,
    data,
    selectedColumns,
  } = props;

  const searchHandler = (event) => {
    onSearch(event.target.value);
  };

  const onSelect = (selectKey, values) => {
    onFilter(selectKey, values);
  };

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  function checkVersion() {
    try {
      const version = navigator.appVersion;
      const versionText = version.split(' ');
      let versionLabel = versionText.find((label) => label.includes('Version'));
      versionLabel = versionLabel.split('/')[1];
      versionLabel = parseFloat(versionLabel);

      return versionLabel;
    } catch (err) {
      console.log('Error in finding version');
    }
  }

  const getHeight = (id) => {
    const isSafari =
      /constructor/i.test(window.HTMLElement) ||
      (function (p) {
        return p.toString() === '[object SafariRemoteNotification]';
      })(
        !window['safari'] ||
          (typeof safari !== 'undefined' && window['safari'].pushNotification)
      );

    let height = document?.getElementById(id)?.offsetHeight;

    if (isSafari) {
      const version = checkVersion();
      if (version >= 15.1 && version < 15.4) {
        height = 2 * height;
      }
    }

    return height;
  };

  const tableCell = (headCell) => {
    const headerLabel = headCell.label ? (
      <span>
        {headCell.label?.split(' ').map((elem, i) => (
          <span
            key={`${elem}-${i}`}
            style={{ whiteSpace: 'pre-wrap', wordBreak: 'normal' }}
          >
            {elem}{' '}
          </span>
        ))}
      </span>
    ) : (
      ''
    );

    return (
      <>
        {!headCell.canSortChildren ? (
          <TableSortLabel
            active={orderBy === headCell.id || orderBy === headCell.sortBy}
            direction={
              orderBy === headCell.id || orderBy === headCell.sortBy
                ? order
                : 'asc'
            }
            onClick={createSortHandler(headCell.sortBy || headCell.id)}
            IconComponent={(props) => (
              <img src={SortIcon} alt='arrow' {...props} />
            )}
            classes={{
              icon: classes.sortIcon,
            }}
          >
            <span className={classes.label}>
              {headCell.type === 'delta' ? (
                <span>
                  <img
                    src={DeltaIcon}
                    alt='change'
                    height={10}
                    style={{ marginRight: 8 }}
                  />
                  Change
                </span>
              ) : (
                <>
                  <div className={classes.tableHeadContainer}>
                    <div>{headerLabel}</div>
                    {headCell?.helperText && (
                      <Helper
                        title={headCell?.helperText}
                        link={headCell.helperLink}
                      />
                    )}
                  </div>

                  {rows?.length && headCell.suffixId
                    ? rows[0][headCell.suffixId]
                    : ''}
                </>
              )}
              <span className={classes.subLabel}>
                {headCell.subLabel && `(${headCell.subLabel})`}
              </span>
              {headCell?.type === 'checkbox' && (
                <Checkbox
                  name={`${headCell.id}`}
                  checked={selectedColumns?.indexOf(headCell.id) > -1}
                  icon={<UncheckedIcon />}
                  checkedIcon={<CheckedIcon />}
                  className={classes.checkBoxIcon}
                  onChange={() => selectColumn(headCell.id)}
                />
              )}
            </span>
            {orderBy === headCell.id ? (
              <Box component='span' sx={visuallyHidden}>
                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
              </Box>
            ) : null}
          </TableSortLabel>
        ) : (
          <span className={classes.label}>
            {headCell.type === 'delta' ? (
              <span>
                <img
                  src={DeltaIcon}
                  alt='change'
                  height={10}
                  style={{ marginRight: 8 }}
                />
                Change
              </span>
            ) : (
              <div className={classes.tableHeadContainer}>
                <div>{`${headCell.label || ''} ${
                  rows?.length && headCell.suffixId
                    ? rows[0][headCell.suffixId]
                    : ''
                }`}</div>
                {headCell?.helperText && (
                  <Helper
                    title={headCell?.helperText}
                    link={headCell.helperLink}
                  />
                )}
              </div>
            )}

            <span className={classes.subLabel}>
              {headCell.subLabel && `(${headCell.subLabel})`}
            </span>
            {headCell?.type === 'checkbox' && (
              <Checkbox
                name={`${headCell.id}`}
                checked={selectedColumns?.indexOf(headCell.id) > -1}
                icon={<UncheckedIcon />}
                checkedIcon={<CheckedIcon />}
                className={classes.checkBoxIcon}
                onChange={() => selectColumn(headCell.id)}
              />
            )}
          </span>
        )}
        {headCell.isSearchable && (
          <SimpleSearch
            text={text}
            width={162.88}
            maxHeight={24}
            onChange={searchHandler}
          />
        )}
        {headCell.isSelectable && (
          <MultiSelect
            textStyle='uppercase'
            selectedValues={filters[headCell.id] || []}
            options={[
              ...new Set(
                data
                  ?.filter((elem) => {
                    if (elem[headCell.id]) {
                      return ![
                        'Agg. Fixed Departments',
                        'Agg. Optimized Departments',
                      ].includes(elem[headCell.id]);
                    }
                    return elem[headCell.id];
                  })
                  ?.map((elem) => elem[headCell.id])
              ),
            ]?.map((val) => ({ label: val, value: val }))}
            limitTags={1}
            width={156}
            maxHeight={32}
            onSelect={(_, values) => onSelect(headCell.id, values)}
          />
        )}
      </>
    );
  };

  return (
    <TableHead id={id}>
      <TableRow id={'first-row'} style={{ height: 34 }}>
        {headCells?.map(
          (headCell, index) =>
            headCell.isVisible && (
              <TableCell
                variant='head'
                id={headCell.id}
                key={headCell.id}
                align={
                  headCell.type === 'number' || headCell.type === 'delta'
                    ? 'center'
                    : 'left'
                }
                padding={headCell.disablePadding ? 'none' : 'normal'}
                sortDirection={orderBy === headCell.id ? order : false}
                rowSpan={headCell.group ? 1 : 2}
                colSpan={headCell?.colSpan}
                classes={{
                  root:
                    index === headCells?.length - 1
                      ? headCell.group
                        ? classnames(
                            classes.customTableLastCell,
                            classes.customTabBorderBottom
                          )
                        : classes.customTableLastCell
                      : headCell.group
                      ? classnames(
                          classes.customTableCell,
                          classes.customTabBorderBottom
                        )
                      : classes.customTableCell,
                }}
                style={{
                  padding: headCell.padding || '8px 4px 4px',
                  background:
                    ['range', 'checkbox', 'input'].indexOf(headCell.type) >
                      -1 && '#D5EAFF',
                  boxSizing: 'border-box',
                  wordBreak: 'break-word',
                  minWidth: headCell.minWidth,
                  textAlign: 'center',
                  top: 0,
                  borderBottomWidth:
                    headCell.group && !headCell.canSortChildren && '1px',
                  left: headCell.isSticky
                    ? calculateStickyLeft(headCells, index)
                    : 'inherit',
                  zIndex: headCell.isSticky ? 2 : 1,
                }}
                sx={{ top: 0 }}
              >
                {tableCell(headCell)}
              </TableCell>
            )
        )}
      </TableRow>

      <TableRow id={'second-row'}>
        {headCells?.map((headCell, headIndex) =>
          headCell.group && headCell.isVisible
            ? headCell.subColumns?.map((subColumn, index) =>
                subColumn.isVisible ? (
                  <TableCell
                    variant='head'
                    key={subColumn.id}
                    align={
                      subColumn.type === 'number' || subColumn.type === 'delta'
                        ? 'center'
                        : 'left'
                    }
                    padding={subColumn.disablePadding ? 'none' : 'normal'}
                    colSpan={subColumn.colSpan}
                    sortDirection={orderBy === subColumn.id ? order : false}
                    classes={{
                      root:
                        headIndex === headCells?.length - 1 &&
                        index === headCell.subColumns?.length - 1
                          ? classes.customTableLastCell
                          : classes.customTableCell,
                    }}
                    sx={{
                      top: getHeight('first-row'),
                    }}
                    style={{
                      padding: '8px 6px',
                      boxSizing: 'border-box',
                      wordBreak: 'break-word',
                      minWidth: subColumn.minWidth ? subColumn.minWidth : null,
                      textAlign: 'center',
                      zIndex: 1,
                      background: subColumn.canSortChildren && '#BAC8D3',
                      fontWeight: subColumn.canSortChildren && 300,
                    }}
                  >
                    {tableCell(subColumn)}
                  </TableCell>
                ) : null
              )
            : null
        )}
      </TableRow>
      <TableRow className={classes.loader}>
        <TableCell
          className={classes.loader}
          sx={{ top: document.getElementById(headCells[0].id)?.offsetHeight }}
          colSpan={headCells?.length}
        >
          {isLoading && rows?.length === 0 ? (
            <LinearProgress
              classes={{
                root: classes.loader,
                barColorPrimary: classes.loaderBar,
              }}
            />
          ) : null}
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

export default EnhancedTableHead;

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  headCells: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      disablePadding: PropTypes.bool.isRequired,
      label: PropTypes.string.isRequired,
      isSearchable: PropTypes.bool.isRequired,
      isSelectable: PropTypes.bool.isRequired,
    })
  ).isRequired,
};
