import { helperTexts } from 'config/constants';

const commonHeadCells = [
  {
    id: 'elasticity',
    sortBy: 'space_elasticity_tag_number',
    column_name: 'elasticity',
    type: 'chart',
    newFlag: 'new_dept_flag',
    isVisible: true,
    isLink: false,
    isSearchable: false,
    isSelectable: false,
    disablePadding: false,
    textTransform: 'uppercase',
    colSpan: 1,
    minWidth: 116,
    colWidth: '13%',
    label: 'Elasticity',
    rightIcon: 'test',
    helperText: helperTexts.elasticity.text,
    helperLink: helperTexts.elasticity.link,
  },
  {
    id: 'elasticity_confidence',
    sortBy: 'elastic_confidence_number',
    column_name: 'elasticity_confidence',
    type: 'elasticity',
    newFlag: 'new_dept_flag',
    isVisible: true,
    isLink: false,
    isSearchable: false,
    isSelectable: false,
    disablePadding: false,
    textTransform: 'uppercase',
    colSpan: 1,
    minWidth: 128,
    colWidth: '12%',
    label: 'Elasticity Confidence',
    helperText: helperTexts.confidence.text,
    helperLink: helperTexts.confidence.link,
  },
  {
    id: 'sister_store_name',
    column_name: 'sister_store_name',
    sortBy: 'sister_store_number',
    type: 'text',
    prefixId: 'sister_store_number',
    newFlag: 'new_dept_flag',
    isVisible: true,
    isLink: false,
    isSearchable: false,
    isSelectable: false,
    disablePadding: false,
    textTransform: 'uppercase',
    colSpan: 1,
    minWidth: 200,
    colWidth: '10%',
    label: 'Sister Store',
    helperText: helperTexts.sister_store.text,
    helperLink: helperTexts.sister_store.link,
  },
  {
    id: 'cluster',
    column_name: 'cluster',
    type: 'text',
    sortBy: 'assortmentNum',
    newFlag: 'new_dept_flag',
    isVisible: true,
    isLink: false,
    isSearchable: false,
    isSelectable: false,
    disablePadding: false,
    prefix: 'TIER ',
    prefixId: 'tier',
    colSpan: 1,
    minWidth: 152,
    colWidth: '12%',
    label: 'Tier Cluster',
    helperText: helperTexts.tier_cluster.text,
    helperLink: helperTexts.tier_cluster.link,
  },
  {
    id: 'store_dept_index',
    column_name: 'store_dept_index',
    type: 'text',
    newFlag: 'new_dept_flag',
    isVisible: false,
    isLink: false,
    isSearchable: false,
    isSelectable: false,
    disablePadding: false,
    colSpan: 1,
    minWidth: 152,
    colWidth: '6%',
    label: 'Store Dept. Index',
    helperText: helperTexts.store_dept_index.text,
    helperLink: helperTexts.store_dept_index.link,
  },
  {
    id: 'dept_index',
    column_name: 'dept_index',
    type: 'number',
    newFlag: 'new_dept_flag',
    isVisible: false,
    isLink: false,
    isSearchable: false,
    isSelectable: false,
    disablePadding: false,
    colSpan: 1,
    minWidth: 96,
    colWidth: '6%',
    label: 'Dept Index',
    helperText: helperTexts.dept_index.text,
    helperLink: helperTexts.dept_index.link,
  },
  {
    id: 'rev_area_index',
    column_name: 'rev_area_index',
    type: 'number',
    newFlag: 'new_dept_flag',
    isVisible: false,
    isLink: false,
    isSearchable: false,
    isSelectable: false,
    disablePadding: false,
    colSpan: 1,
    minWidth: 160,
    colWidth: '6%',
    label: 'Revenue Area Index',
    helperText: helperTexts.revenue_area_index.text,
    helperLink: helperTexts.revenue_area_index.link,
  },
  {
    id: 'Total Area',
    sortBy: 'optimized_area',
    column_name: 'optimized_area',
    type: 'text',
    newFlag: 'new_dept_flag',
    isVisible: true,
    isLink: false,
    isSearchable: false,
    isSelectable: false,
    disablePadding: false,
    minWidth: 112,
    label: 'Total Area',
    group: true,
    canSortChildren: true,
    colSpan: 3,
    subColumns: [
      {
        id: 'optimized_area',
        column_name: 'optimized_area',
        type: 'numeric',
        newFlag: 'new_dept_flag',
        isVisible: true,
        isSearchable: false,
        isSelectable: false,
        disablePadding: false,
        colSpan: 1,
        minWidth: 112,
        textTransform: 'uppercase',
        colWidth: '7%',
        label: `Store`,
        subLabel: 'A',
      },
      {
        id: 'ly_actual_total_area',
        column_name: 'ly_actual_total_area',
        type: 'numeric',
        newFlag: 'new_dept_flag',
        isVisible: true,
        isSearchable: false,
        isSelectable: false,
        disablePadding: false,
        colSpan: 1,
        minWidth: 112,
        textTransform: 'uppercase',
        colWidth: '7%',
        label: `LY`,
        subLabel: 'B',
      },
      {
        id: 'compare_actual_total_area',
        column_name: 'compare_actual_total_area',
        type: 'numeric',
        newFlag: 'new_dept_flag',
        isVisible: false,
        isSearchable: false,
        isSelectable: false,
        disablePadding: false,
        colSpan: 1,
        minWidth: 104,
        textTransform: 'uppercase',
        colWidth: '7%',
        subLabel: 'C',
      },
      {
        id: 'delta_total_area',
        column_name: 'delta_total_area',
        type: 'delta',
        newFlag: 'new_dept_flag',
        isVisible: true,
        isSearchable: false,
        isSelectable: false,
        disablePadding: false,
        colSpan: 1,
        minWidth: 96,
        textTransform: 'uppercase',
        colWidth: '7%',
        label: 'Delta',
      },
    ],
  },
  {
    id: 'Revenue',
    column_name: 'optimized_revenue',
    sortBy: 'optimized_revenue',
    type: 'number',
    newFlag: 'new_dept_flag',
    isVisible: true,
    isLink: false,
    isSearchable: false,
    isSelectable: false,
    disablePadding: false,
    minWidth: 112,
    prefix: '$',
    label: 'Revenue',
    group: true,
    canSortChildren: true,
    colSpan: 3,
    subColumns: [
      {
        id: 'optimized_revenue',
        column_name: 'optimized_revenue',
        type: 'number',
        newFlag: 'new_dept_flag',
        isVisible: true,
        isSearchable: false,
        isSelectable: false,
        disablePadding: false,
        colSpan: 1,
        minWidth: 112,
        textTransform: 'uppercase',
        prefix: '$',
        colWidth: '7%',
        label: `Store`,
        subLabel: 'A',
      },
      {
        id: 'ly_actual_revenue',
        column_name: 'ly_actual_revenue',
        type: 'number',
        newFlag: 'new_dept_flag',
        isVisible: true,
        isSearchable: false,
        isSelectable: false,
        disablePadding: false,
        colSpan: 1,
        minWidth: 112,
        textTransform: 'uppercase',
        prefix: '$',
        colWidth: '7%',
        label: `LY`,
        subLabel: 'B',
      },
      {
        id: 'compare_actual_revenue',
        column_name: 'compare_actual_revenue',
        type: 'number',
        newFlag: 'new_dept_flag',
        isVisible: false,
        isSearchable: false,
        isSelectable: false,
        disablePadding: false,
        colSpan: 1,
        minWidth: 104,
        textTransform: 'uppercase',
        prefix: '$',
        colWidth: '7%',
        subLabel: 'C',
      },
      {
        id: 'delta_revenue',
        column_name: 'delta_revenue',
        type: 'delta',
        newFlag: 'new_dept_flag',
        isVisible: true,
        isSearchable: false,
        isSelectable: false,
        disablePadding: false,
        colSpan: 1,
        minWidth: 96,
        textTransform: 'uppercase',
        prefix: '$',
        colWidth: '7%',
        label: 'Delta',
      },
    ],
  },
  {
    id: 'Revenue/ Sq ft',
    column_name: 'optimized_revenue_persqft',
    sortBy: 'optimized_revenue_persqft',
    type: 'number',
    newFlag: 'new_dept_flag',
    isVisible: true,
    isLink: false,
    isSearchable: false,
    isSelectable: false,
    disablePadding: false,
    minWidth: 140,
    label: 'Revenue/ Sq ft',
    prefix: '$',
    group: true,
    canSortChildren: true,
    colSpan: 3,
    subColumns: [
      {
        id: 'optimized_revenue_persqft',
        column_name: 'optimized_revenue_persqft',
        type: 'number',
        newFlag: 'new_dept_flag',
        isVisible: true,
        isSearchable: false,
        isSelectable: false,
        disablePadding: false,
        colSpan: 1,
        minWidth: 112,
        textTransform: 'uppercase',
        prefix: '$',
        colWidth: '7%',
        label: `Store`,
        subLabel: 'A',
      },
      {
        id: 'ly_revenue_persqft',
        column_name: 'ly_revenue_persqft',
        type: 'number',
        newFlag: 'new_dept_flag',
        isVisible: true,
        isSearchable: false,
        isSelectable: false,
        disablePadding: false,
        colSpan: 1,
        minWidth: 112,
        textTransform: 'uppercase',
        prefix: '$',
        colWidth: '7%',
        label: `LY`,
        subLabel: 'B',
      },
      {
        id: 'compare_revenue_persqft',
        column_name: 'compare_revenue_persqft',
        type: 'number',
        newFlag: 'new_dept_flag',
        isVisible: false,
        isSearchable: false,
        isSelectable: false,
        disablePadding: false,
        colSpan: 1,
        minWidth: 104,
        textTransform: 'uppercase',
        prefix: '$',
        colWidth: '7%',
        subLabel: 'C',
      },
      {
        id: 'delta_revenue_persqft',
        column_name: 'delta_revenue_persqft',
        type: 'delta',
        newFlag: 'new_dept_flag',
        isVisible: true,
        isSearchable: false,
        isSelectable: false,
        disablePadding: false,
        colSpan: 1,
        minWidth: 96,
        textTransform: 'uppercase',
        prefix: '$',
        colWidth: '7%',
        label: 'Delta',
      },
    ],
  },
  {
    id: 'Margin',
    column_name: 'optimized_margin',
    sortBy: 'optimized_margin',
    type: 'number',
    newFlag: 'new_dept_flag',
    isVisible: false,
    isLink: false,
    isSearchable: false,
    isSelectable: false,
    disablePadding: false,
    minWidth: 128,
    label: 'Margin',
    prefix: '$',
    group: true,
    canSortChildren: true,
    colSpan: 3,
    subColumns: [
      {
        id: 'optimized_margin',
        column_name: 'optimized_margin',
        type: 'number',
        newFlag: 'new_dept_flag',
        isVisible: true,
        isSearchable: false,
        isSelectable: false,
        disablePadding: false,
        colSpan: 1,
        minWidth: 112,
        textTransform: 'uppercase',
        prefix: '$',
        colWidth: '7%',
        label: `Store`,
        subLabel: 'A',
      },
      {
        id: 'ly_actual_margin',
        column_name: 'ly_actual_margin',
        type: 'number',
        newFlag: 'new_dept_flag',
        isVisible: true,
        isSearchable: false,
        isSelectable: false,
        disablePadding: false,
        colSpan: 1,
        minWidth: 112,
        textTransform: 'uppercase',
        prefix: '$',
        colWidth: '7%',
        label: `LY`,
        subLabel: 'B',
      },
      {
        id: 'compare_actual_margin',
        column_name: 'compare_actual_margin',
        type: 'number',
        newFlag: 'new_dept_flag',
        isVisible: false,
        isSearchable: false,
        isSelectable: false,
        disablePadding: false,
        colSpan: 1,
        minWidth: 104,
        textTransform: 'uppercase',
        prefix: '$',
        colWidth: '7%',
        subLabel: 'C',
      },
      {
        id: 'delta_margin',
        column_name: 'delta_margin',
        type: 'delta',
        newFlag: 'new_dept_flag',
        isVisible: true,
        isSearchable: false,
        isSelectable: false,
        disablePadding: false,
        colSpan: 1,
        minWidth: 96,
        textTransform: 'uppercase',
        prefix: '$',
        colWidth: '7%',
        label: 'Delta',
      },
    ],
  },
  {
    id: 'Margin/ Sq ft',
    column_name: 'optimized_margin_persqft',
    sortBy: 'optimized_margin_persqft',
    type: 'number',
    newFlag: 'new_dept_flag',
    isVisible: false,
    isLink: false,
    isSearchable: false,
    isSelectable: false,
    disablePadding: false,
    minWidth: 132,
    label: 'Margin/ Sq ft',
    prefix: '$',
    group: true,
    canSortChildren: true,
    colSpan: 3,
    subColumns: [
      {
        id: 'optimized_margin_persqft',
        column_name: 'optimized_margin_persqft',
        type: 'number',
        newFlag: 'new_dept_flag',
        isVisible: true,
        isSearchable: false,
        isSelectable: false,
        disablePadding: false,
        colSpan: 1,
        minWidth: 112,
        textTransform: 'uppercase',
        prefix: '$',
        colWidth: '7%',
        label: `Store`,
        subLabel: 'A',
      },
      {
        id: 'ly_margin_persqft',
        column_name: 'ly_margin_persqft',
        type: 'number',
        newFlag: 'new_dept_flag',
        isVisible: true,
        isSearchable: false,
        isSelectable: false,
        disablePadding: false,
        colSpan: 1,
        minWidth: 112,
        textTransform: 'uppercase',
        prefix: '$',
        colWidth: '7%',
        label: `LY`,
        subLabel: 'B',
      },
      {
        id: 'compare_margin_persqft',
        column_name: 'compare_margin_persqft',
        type: 'number',
        newFlag: 'new_dept_flag',
        isVisible: false,
        isSearchable: false,
        isSelectable: false,
        disablePadding: false,
        colSpan: 1,
        minWidth: 104,
        textTransform: 'uppercase',
        prefix: '$',
        colWidth: '7%',
        subLabel: 'C',
      },
      {
        id: 'delta_margin_persqft',
        column_name: 'delta_margin_persqft',
        type: 'delta',
        newFlag: 'new_dept_flag',
        isVisible: true,
        isSearchable: false,
        isSelectable: false,
        disablePadding: false,
        colSpan: 1,
        minWidth: 96,
        textTransform: 'uppercase',
        prefix: '$',
        colWidth: '7%',
        label: 'Delta',
      },
    ],
  },
];

export const storeHeadCells = [
  {
    id: 'floor_plan_area',
    column_name: 'floor_plan_area',
    type: 'text',
    newFlag: 'new_dept_flag',
    isVisible: true,
    isSticky: true,
    isLink: false,
    isSearchable: false,
    isSelectable: true,
    disablePadding: false,
    textTransform: 'uppercase',
    colSpan: 1,
    aggregatedColSpan: 2,
    minWidth: 200,
    colWidth: '12%',
    label: 'Floor Plan area',
  },
  {
    id: 'department',
    column_name: 'department',
    type: 'text',
    newFlag: 'new_dept_flag',
    isVisible: true,
    isLink: false,
    isSticky: true,
    isSearchable: false,
    isSelectable: true,
    disablePadding: false,
    textTransform: 'uppercase',
    colSpan: 1,
    aggregatedColSpan: 0,
    minWidth: 250,
    colWidth: '12%',
    label: 'Dept.',
    showIsFixed: true,
  },

  ...commonHeadCells,
];

export const floorPlanAreaHeadCells = [
  {
    id: 'floor_plan_area',
    column_name: 'floor_plan_area',
    type: 'text',
    newFlag: 'new_dept_flag',
    isVisible: true,
    isSticky: true,
    isLink: false,
    isSearchable: false,
    isSelectable: true,
    disablePadding: false,
    textTransform: 'uppercase',
    aggregatedColSpan: 2,
    colSpan: 1,
    minWidth: 200,
    colWidth: '12%',
    label: 'Floor Plan area',
  },
  {
    id: 'department',
    column_name: 'department',
    type: 'text',
    newFlag: 'new_dept_flag',
    isVisible: true,
    isLink: false,
    isSticky: true,
    isSearchable: false,
    isSelectable: true,
    disablePadding: false,
    textTransform: 'uppercase',
    colSpan: 1,
    aggregatedColSpan: 0,
    minWidth: 250,
    colWidth: '12%',
    label: 'Dept.',
    showIsFixed: true,
  },
  ...commonHeadCells,
];

export const floorHeadCells = [
  {
    id: 'floor_str',
    sortBy: 'floor',
    column_name: 'floor_str',
    type: 'text',
    newFlag: 'new_dept_flag',
    isVisible: true,
    isSticky: true,
    isLink: false,
    isSearchable: false,
    isSelectable: true,
    disablePadding: false,
    textTransform: 'uppercase',
    aggregatedColSpan: 2,
    colSpan: 1,
    minWidth: 180,
    colWidth: '12%',
    label: 'Floor',
  },
  {
    id: 'department',
    column_name: 'department',
    type: 'text',
    newFlag: 'new_dept_flag',
    isVisible: true,
    isLink: false,
    isSticky: true,
    isSearchable: false,
    isSelectable: true,
    disablePadding: false,
    textTransform: 'uppercase',
    aggregatedColSpan: 0,
    colSpan: 1,
    minWidth: 250,
    colWidth: '12%',
    label: 'Dept.',
    showIsFixed: true,
  },
  ...commonHeadCells,
];
