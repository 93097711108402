import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from '@mui/material';

import CustomText from 'components/CustomText';

import SyncIcon from '../assets/svg/sync.svg';
import SyncPrimaryIcon from '../assets/svg/syncPrimary.svg';
import FloorLevels from './FloorLevels';
import RunOptimizer from './RunOptimizer';
import { runOptimizer } from 'services/constraints/constraints';
import NoSavePrompt from './NoSavePrompt';
import FloorPlans from './FloorPlans';

const styles = (theme) => ({
  container: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignBetweenCenter,
    flexWrap: theme.content.flexStyles.flexWrap.wrap,
  },
  actionContainer: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignStartCenter,
    cursor: theme.content.cursor.pointer,
    width: 'fit-content',
  },
  actionIconContainer: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignStartCenter,
    cursor: theme.content.cursor.pointer,
    padding: 8,
    width: 'fit-content',
  },
  content: {
    ...theme.typography.fontSizes.normalText,
    color: theme.palette.textPrimary,
    letterSpacing: '-0.42px',
    marginRight: 8,
    whiteSpace: 'nowrap',
  },
  actionIcon: {
    width: 16,
    height: 18,
  },
});

const useStyles = makeStyles(styles);

const RunOptimizerContainer = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const {
    showIcon,
    showLabel,
    isActionButton,
    isLySeason,
    storeId,
    floors,
    storeData,
    season,
    lySeason,
    istableDataChanged = () => null,
    isConstraint,
    saveConstraints,
  } = props;

  const [openRunOptimizer, setOpenRunOptimizer] = useState(false);
  const [openNoSavePrompt, setOpenNoSavePrompt] = useState(false);
  const [openFloorLevels, setOpenFloorLevels] = useState(false);
  const [openFloorPlan, setOpenFloorPlan] = useState(false);
  const [nextSeason, setNextSeason] = useState(null);
  const [levelFlag, setLevelFlag] = useState(null);
  const [optimizationFloors, setOptimizationFloors] = useState([]);
  const [optimizationFloorPlans, setOptimizationFloorPlans] = useState([]);

  const handleOpenSavePrompt = () => {
    setOpenNoSavePrompt(true);
  };

  const handleCloseSavePrompt = () => {
    setOpenNoSavePrompt(false);
  };

  const handleOpenRunOptimizer = () => {
    setOpenRunOptimizer(true);
  };

  const handleCloseRunOptimizer = () => {
    setOpenRunOptimizer(false);
  };

  const handleOpenFloorLevels = () => {
    setOpenFloorLevels(true);
  };

  const handleCloseFloorLevels = () => {
    setOpenFloorLevels(false);
  };

  const handleOpenFloorPlan = () => {
    setOpenFloorPlan(true);
  };

  const handleCloseFloorPlan = () => {
    setOpenFloorPlan(false);
  };

  const handleOptimizer = (level_flag, season, floors, floorPlans) => {
    optimizeData(level_flag, season, floors, floorPlans);
  };

  const handleOptimizationByStore = (season, floors, floorPlans) => {
    optimizeData('store', season, floors, floorPlans);
  };

  const handleOptimizationByFloorPlan = (season, floors, floorPlans) => {
    setNextSeason(season);
    setOptimizationFloorPlans(floorPlans);
    setOptimizationFloors(floors);
    handleCloseRunOptimizer();
    setTimeout(() => {
      handleOpenFloorPlan();
    }, 100);
  };

  const handleOptimizationByFloors = (season, floors, floorPlans) => {
    setNextSeason(season);
    setOptimizationFloorPlans(floorPlans);
    setOptimizationFloors(floors);
    handleCloseRunOptimizer();
    setTimeout(() => {
      handleOpenFloorLevels();
    }, 100);
  };

  const successHandler = (payload) => {
    if (levelFlag === 'floor') {
      handleCloseFloorLevels();
    } else if (levelFlag === 'floor_plan') {
      handleCloseFloorLevels();
    } else {
      handleCloseRunOptimizer();
    }

    navigate(
      `/optimized/${payload?.storeId}/${
        payload?.level_flag
      }/?optimized_season=${payload?.season?.label}&ly_season=${
        isLySeason ? season?.label : lySeason
      }`
    );
  };

  const optimizeData = (level_flag, season, floors, floorPlans) => {
    setLevelFlag(level_flag);
    mutateOptimizer({
      season,
      floors,
      floor_plan: floorPlans,
      storeId,
      level_flag,
    });
  };

  const onComplete = () => {
    handleCloseSavePrompt();

    handleOpenRunOptimizer();
  };

  const { mutate: mutateOptimizer, isLoading } = runOptimizer(successHandler);

  return (
    <>
      <div
        className={
          !showLabel ? classes.actionIconContainer : classes.actionContainer
        }
        onClick={() => {
          if (
            istableDataChanged() &&
            isConstraint &&
            storeData?.length &&
            !localStorage.getItem(`no-prompt`)
          ) {
            handleOpenSavePrompt();
          } else {
            if (isConstraint && istableDataChanged()) {
              saveConstraints(onComplete);
            } else {
              handleOpenRunOptimizer();
            }
          }
        }}
      >
        {showLabel && (
          <CustomText
            content={'Run Optimizer'}
            variant={'body1'}
            classes={classes.content}
          />
        )}
        {showIcon && (
          <Tooltip
            arrow
            title={'Run Optimizer'}
            disableFocusListener={!isActionButton}
            disableHoverListener={!isActionButton}
          >
            <img
              src={isActionButton ? SyncIcon : SyncPrimaryIcon}
              alt='area'
              className={!isActionButton ? classes.actionIcon : ''}
            />
          </Tooltip>
        )}
      </div>

      {openNoSavePrompt && (
        <NoSavePrompt
          floors={floors}
          handleOpen={() => {
            handleCloseSavePrompt();
            handleOpenRunOptimizer();
          }}
          handleSave={() => {
            saveConstraints(onComplete);
          }}
          handleClose={handleCloseSavePrompt}
          isLoading={isLoading}
          season={season}
        />
      )}

      {openRunOptimizer && (
        <RunOptimizer
          isLySeason={isLySeason}
          season={season}
          storeId={storeId}
          floors={floors}
          handleOpen={handleOpenRunOptimizer}
          handleClose={handleCloseRunOptimizer}
          isLoading={isLoading}
          handleOptimizationByStore={handleOptimizationByStore}
          handleOptimizationByFloors={handleOptimizationByFloors}
          handleOptimizationByFloorPlan={handleOptimizationByFloorPlan}
        />
      )}
      {openFloorLevels && nextSeason?.year && (
        <FloorLevels
          season={nextSeason}
          floorOptions={optimizationFloors}
          floorPlanOptions={optimizationFloorPlans}
          handleOpen={handleOpenFloorLevels}
          handleClose={handleCloseFloorLevels}
          isLoading={isLoading}
          optimizationFloors={optimizationFloors}
          optimizationFloorPlans={optimizationFloorPlans}
          runOptimizer={handleOptimizer}
        />
      )}
      {openFloorPlan && nextSeason?.year && (
        <FloorPlans
          season={nextSeason}
          floorOptions={optimizationFloors}
          floorPlanOptions={optimizationFloorPlans}
          handleOpen={handleOpenFloorPlan}
          handleClose={handleCloseFloorPlan}
          isLoading={isLoading}
          optimizationFloors={optimizationFloors}
          optimizationFloorPlans={optimizationFloorPlans}
          runOptimizer={handleOptimizer}
        />
      )}
    </>
  );
};

export default RunOptimizerContainer;
