import React from 'react';
import { makeStyles } from '@mui/styles';
import SingleSelect from 'components/SingleSelect';
import StoreFilterActions from './StoreFilterActions';

const styles = (theme) => ({
  container: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignBetweenCenter,
    width: '100%',
  },
});

const useStyles = makeStyles(styles);

const StoreModelInfo = ({
  seasonOptions,
  season,
  lySeason,
  storeDetails,
  storeData,
  onSeasonSelect,
  saveConstraints,
  optimizeData,
  isLoadingFilters,
  istableDataChanged,
  disabled,
  toolTipText,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <SingleSelect
        label={'Season'}
        placeholder={'Select Season'}
        selectedValue={season}
        options={seasonOptions}
        optionLabelKey={'label'}
        loading={isLoadingFilters}
        width={156}
        maxHeight={36}
        textTransform='capitalize'
        onSelect={onSeasonSelect}
      />
      <StoreFilterActions
        season={season}
        lySeason={lySeason}
        seasonOptions={seasonOptions}
        storeDetails={storeDetails}
        storeData={storeData}
        saveConstraints={saveConstraints}
        optimizeData={optimizeData}
        istableDataChanged={istableDataChanged}
        disabled={disabled}
        toolTipText={toolTipText}
      />
    </div>
  );
};

export default StoreModelInfo;
