import React from 'react';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import { Close } from '@mui/icons-material';
import CustomText from 'components/CustomText';
import CustomButton from 'components/CustomButton';

const styles = (theme) => ({
  dialog: {
    backgroundColor: 'rgba(0,0,0,0.3)',
    '& .MuiBackdrop-root': {
      backdropFilter: 'blur(8px)',
      background: theme.palette.bgTransparent,
    },
  },
  dialogContent: { padding: 12, overflow: theme.content.overflow.auto },
  dialogPaperTransparent: {
    boxShadow: 'none',
    background: theme.palette.bgTransparent,
    borderRadius: 12,
    maxWidth: 'none',
  },
  dialogPaper: {
    background: theme.palette.bgPrimary,
    boxShadow: '0px 0px 40px #2283D126',
    border: `1px solid ${theme.palette.textPrimary}`,
    borderRadius: 12,
    maxWidth: 'none',
    overflow: theme.content.overflow.hidden,
  },
  headerTransparent: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignCenter,
    height: 36,
    position: theme.content.positions.relative,
    boxSizing: theme.content.boxSizing.borderBox,
  },
  header: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignCenter,
    height: 36,
    position: theme.content.positions.relative,
    borderBottom: `0.25px solid ${theme.palette.stroke}`,
    boxSizing: theme.content.boxSizing.borderBox,
  },
  headerText: {
    ...theme.typography.fontSizes.normalText,
    color: theme.palette.stroke,
  },
  closeContainer: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignCenter,
    position: theme.content.positions.absolute,
    top: 8,
    right: 8,
    height: 20,
    width: 20,
    background: theme.palette.bgBadgeLight,
    borderRadius: '50%',
    cursor: theme.content.cursor.pointer,
  },
  closeIcon: {
    color: theme.palette.bgBadgeDark,
    fontSize: 18,
  },
  actionFooterTransparent: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignCenter,
    height: 48,
    position: theme.content.positions.relative,
    boxSizing: theme.content.boxSizing.borderBox,
  },
  actionFooter: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignCenter,
    height: 48,
    position: theme.content.positions.relative,
    borderTop: `0.25px solid ${theme.palette.stroke}`,
    boxSizing: theme.content.boxSizing.borderBox,
  },
});

const useStyles = makeStyles(styles);

const CustomDialog = (props) => {
  const {
    children,
    open,
    header,
    footer,
    showButton,
    isTransparent,
    handleButtonClick,
    disableClose,
    handleClose,
    parentStyle,
  } = props;
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={disableClose ? null : handleClose}
      classes={{
        root: classes.dialog,
        paper: isTransparent
          ? classes.dialogPaperTransparent
          : classes.dialogPaper,
      }}
    >
      <div
        className={isTransparent ? classes.headerTransparent : classes.header}
      >
        {header && (
          <CustomText
            content={header}
            variant={'body1'}
            classes={classes.headerText}
          />
        )}

        {!disableClose && (
          <div className={classes.closeContainer} onClick={() => handleClose()}>
            <Close className={classes.closeIcon} />
          </div>
        )}
      </div>
      <div className={classes.dialogContent} style={parentStyle}>
        {children}
      </div>
      {footer ? (
        <div
          className={
            isTransparent
              ? classes.actionFooterTransparent
              : classes.actionFooter
          }
        >
          {showButton && (
            <CustomButton
              isPrimary={true}
              variant='contained'
              height={32}
              width={120}
              label='Optimize'
              onButtonClick={() => handleButtonClick()}
            />
          )}
        </div>
      ) : null}
    </Dialog>
  );
};

CustomDialog.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool,
  isTransparent: PropTypes.bool,
  handleOpen: PropTypes.func,
  handleClose: PropTypes.func,
};

export default CustomDialog;
