import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import CustomText from '../CustomText';

const styles = (theme) => ({
  fieldLabel: {
    ...theme.typography.fontSizes.normalText,
    marginBottom: '4px',
    color: theme.palette.textSecondary,
    fontWeight: theme.typography.fontWeight.medium,
  },
  form: {
    margin: '8px 0',
    '& .MuiOutlinedInput-notchedOutline': {
      border: `0.5px solid ${theme.palette.stroke} !important`,
      borderRadius: '2px',
    },
  },
  autocompleteRoot: (props) => ({
    padding: '0 !important',
    paddingRight: '8px !important',
    maxHeight: props.maxHeight,
  }),
  popupIcon: {
    color: theme.palette.textDark,
    width: 12,
    background: 'transparent !important',
  },
  inputField: (props) => ({
    ...theme.typography.fontSizes.selectText,
    maxWidth: '82%',
    padding: `6.5px 0px 6.5px 8px !important`,
    boxSizing: theme.content.boxSizing.borderBox,
    height: '2.25rem',
    color: theme.palette.textDark,
    textTransform: theme.typography.textTransform[props.textTransform],

    '&::placeholder': {
      opacity: 1,
    },
  }),
  autoCompletePaper: (props) => ({
    marginTop: '0.15rem',
    width: props.width,
    border: `0.5px solid ${theme.palette.stroke}`,
    borderRadius: '2px',
    boxShadow: 'none',
  }),
  autoCompleteNoOption: {
    ...theme.typography.fontSizes.selectText,
    color: theme.palette.textDark,
    paddingLeft: '12px',
    background: 'transparent',
  },
  autoCompleteOption: (props) => ({
    ...theme.typography.fontSizes.selectText,
    color: theme.palette.textDark,
    paddingLeft: '10px !important',
    background: 'transparent',
    textTransform: theme.typography.textTransform[props.textTransform],

    '&::before': {
      content: '""',
      minHeight: '1rem',
      minWidth: '1rem',
      borderRadius: '50%',
      border: `1px solid ${theme.palette.stroke}`,
      marginRight: 8,
    },

    '&[aria-selected="true"]': {
      background: 'transparent !important',
      '&.Mui-focused': {
        background: 'rgba(0, 0, 0, 0.04) !important',
      },
      '&.Mui-focusVisible': {
        background: 'rgba(0, 0, 0, 0.12) !important',
      },
      '&::before': {
        content: '""',
        minHeight: '1rem',
        minWidth: '1rem',
        borderRadius: '50%',
        padding: 1,
        backgroundClip: theme.content.boxSizing.contentBox,
        background: theme.palette.textPrimary,
        border: `1px solid ${theme.palette.stroke}`,
        marginRight: 8,
      },
    },
  }),
  clearIcon: {
    display: theme.content.display.none,
  },
  icon: {
    position: theme.content.positions.absolute,
    right: 16,
    color: theme.palette.stroke,
  },
});

const useStyles = makeStyles(styles);

const SingleSelect = (props) => {
  const {
    label,
    placeholder,
    selectedValue,
    options,
    optionLabelKey,
    isDisabled,
    loading,
    width,
    maxHeight,
    popperHeight,
    textTransform,
    onSelect,
  } = props;
  const styleProps = {
    width: `${width}px`,
    maxHeight: `${maxHeight}px`,
    textTransform,
  };
  const classes = useStyles(styleProps);

  return (
    <div>
      <CustomText
        content={label}
        classes={classes.fieldLabel}
        paragraph={false}
        variant={'body1'}
      />
      <FormControl
        sx={{ m: 1, width, maxHeight }}
        size='small'
        className={classes.form}
      >
        <Autocomplete
          ListboxProps={{ style: { maxHeight: popperHeight || 'default' } }}
          value={selectedValue}
          disableClearable={true}
          getOptionLabel={(option) => option[optionLabelKey]}
          isOptionEqualToValue={(option, value) =>
            option[optionLabelKey] === value[optionLabelKey]
          }
          blurOnSelect
          options={options}
          disabled={isDisabled}
          loading={loading}
          onChange={onSelect}
          classes={{
            paper: classes.autoCompletePaper,
            option: classes.autoCompleteOption,
            noOptions: classes.autoCompleteNoOption,
            loading: classes.autoCompleteNoOption,
            popupIndicator: classes.popupIcon,
            clearIndicator: classes.clearIcon,
          }}
          renderInput={(params) => {
            params.inputProps.autoComplete = 'new-password';
            return (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: loading ? (
                    <CircularProgress
                      classes={{ root: classes.icon }}
                      size={16}
                    />
                  ) : (
                    params.InputProps.endAdornment
                  ),
                  placeholder: placeholder,
                  classes: {
                    root: classes.autocompleteRoot,
                    input: classes.inputField,
                  },
                }}
              />
            );
          }}
        />
      </FormControl>
    </div>
  );
};

SingleSelect.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  selectedValue: PropTypes.object,
  options: PropTypes.array,
  optionLabelKey: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  width: PropTypes.number,
  maxHeight: PropTypes.number,
  popperHeight: PropTypes.number,
  textTransform: PropTypes.string,
  onSelect: PropTypes.func,
};

export default SingleSelect;
