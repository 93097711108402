import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import CustomButton from '../../../components/CustomButton';
import CustomText from '../../../components/CustomText';
import RunOptimizerContainer from 'molecules/RunOptimizerContainer';

import SaveIcon from '../../../assets/svg/save.svg';
import AreaIcon from '../../../assets/svg/area.svg';
import TotalArea from './TotalArea';

const styles = (theme) => ({
  container: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignBetweenCenter,
    flexWrap: theme.content.flexStyles.flexWrap.wrap,
  },
  actionContainer: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignStartCenter,
    cursor: theme.content.cursor.pointer,
    margin: 16,
    width: 'fit-content',
  },
  content: {
    ...theme.typography.fontSizes.normalText,
    color: theme.palette.textPrimary,
    letterSpacing: '-0.42px',
    marginRight: 8,
  },
  actionIcon: {
    width: 16,
    height: 18,
  },
});

const useStyles = makeStyles(styles);

const StoreFilterActions = ({
  season,
  lySeason,
  storeDetails,
  storeData,
  saveConstraints,
  istableDataChanged,
  disabled,
  toolTipText,
}) => {
  const classes = useStyles();

  const [openArea, setOpenArea] = useState(false);

  const handleOpenArea = () => {
    setOpenArea(true);
  };

  const handleCloseArea = () => {
    setOpenArea(false);
  };

  return (
    <div className={classes.container}>
      <div className={classes.actionContainer} onClick={() => handleOpenArea()}>
        <CustomText
          content={'Total Area'}
          variant={'body1'}
          classes={classes.content}
        />
        <img src={AreaIcon} alt='area' className={classes.actionIcon} />
      </div>
      <div style={{ margin: 16 }}>
        <RunOptimizerContainer
          showIcon
          showLabel
          storeId={storeDetails?.store_number}
          floors={storeDetails?.no_of_floor}
          storeData={storeData}
          season={season}
          lySeason={lySeason}
          istableDataChanged={istableDataChanged}
          isConstraint
          saveConstraints={saveConstraints}
        />
      </div>

      <CustomButton
        disabled={disabled}
        toolTipText={toolTipText}
        isPrimary={true}
        variant='contained'
        endIcon={<img src={SaveIcon} alt='download' height={20} width={20} />}
        height={36}
        width={96}
        label='Save'
        onButtonClick={() => saveConstraints()}
      />
      {openArea && (
        <TotalArea
          season={season}
          handleOpen={handleOpenArea}
          handleClose={handleCloseArea}
          isTransparent={false}
        />
      )}
    </div>
  );
};

export default StoreFilterActions;
