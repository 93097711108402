import { useQuery } from 'react-query';
import { FLOORS_STRING } from '../../config/constants';
import axiosInstance from 'Utils/axios';

const fetchStoreData = ({ season, compareAgainst, store_id }) => {
  const reqestPayload = {
    season,
    compare_against: compareAgainst,
  };

  return axiosInstance.post(
    `department/store-master/${store_id}/`,
    reqestPayload
  );
};

const fetchStoreDeptFilters = ({ season, store_id }) => {
  const reqestPayload = {
    season,
  };

  return axiosInstance.post(`department/filters/${store_id}/`, reqestPayload);
};

const fetchtotalArea = ({ season = {}, store_id }) => {
  const reqestPayload = {
    season: {
      year: season?.year,
      name: season?.name,
    },
  };

  return axiosInstance.post(
    `department/total_area/${store_id}/`,
    reqestPayload
  );
};

const fetchFloorPlanData = ({ store_id, floor, department }) => {
  const requestPayload = {
    floor,
    department,
  };

  return axiosInstance.post(
    `department/floor_plan/${store_id}/`,
    requestPayload
  );
};

export const getStoreData = (payload) => {
  return useQuery(['store', payload], () => fetchStoreData(payload), {
    enabled: false,
    select: (data) => {
      return data.data.data;
    },
  });
};

//Dummy Data
export const getStoreConstraintsData = (payload) => {
  return useQuery(
    ['store-constraints', payload],
    () => fetchStoreData(payload),
    {
      enabled: false,
      select: (data) => {
        const store_data = data.data.data.map((store) => {
          let area = 1427;
          let step = 50;
          store.areas = [];
          store.step_sizes = [];
          store.floor_str = FLOORS_STRING[store.floor];

          while (area <= 5877) {
            store.areas.push(area);
            area += 50;
          }

          while (step <= 500) {
            store.step_sizes.push(step);
            step += 50;
          }

          store.min_area = store.areas[0];
          store.max_area = store.areas[store.areas.length - 1];
          store.step_size = 50;
          store.min_area_index = 0;
          store.max_area_index = 89;
          store.step_size_index = 0;
          store.fixed_area = store.actual_total_area;
          store.disable_fixed_area = true;

          store.fix_area = false;

          return store;
        });

        return store_data;
      },
    }
  );
};

export const getStoreDeptFilters = (payload) => {
  return useQuery(
    ['store-department-filters', payload],
    () => fetchStoreDeptFilters(payload),
    {
      enabled: false,
      select: (data) => {
        const storeDepartmentFilters = data.data.data;

        if (storeDepartmentFilters.store_detail) {
          storeDepartmentFilters.store_detail.title = `${storeDepartmentFilters.store_detail.store_number}-${storeDepartmentFilters.store_detail.store_name}`;
        }
        storeDepartmentFilters.metric = storeDepartmentFilters.metric.map(
          (metricItem) => {
            metricItem.value = metricItem.name;
            metricItem.label = metricItem.name;

            return metricItem;
          }
        );

        storeDepartmentFilters.compare_against =
          storeDepartmentFilters.compare_against.map((compareOption) => {
            compareOption.label = compareOption.name;

            return compareOption;
          });

        storeDepartmentFilters.season = storeDepartmentFilters.season.map(
          (seasonItem) => {
            seasonItem.label = `${seasonItem.year} ${seasonItem.name}`;
            return seasonItem;
          }
        );

        return storeDepartmentFilters;
      },
    }
  );
};

export const getFloorPlanElasticity = (payload) => {
  return useQuery(
    ['floor-plan-elasticity', payload],
    () => fetchFloorPlanData(payload),
    {
      enabled: false,
      select: (data) => {
        const floorPlanElasticity = data.data.data;
        return floorPlanElasticity;
      },
    }
  );
};

export const getTotalArea = (payload) => {
  return useQuery(['total-area', payload], () => fetchtotalArea(payload), {
    enabled: true,
    select: (data) => {
      const total_area_data = data.data.data;
      return total_area_data;
    },
  });
};
