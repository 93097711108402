import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import CustomDialog from 'components/CustomDialog';
import CustomText from 'components/CustomText';
import AutoCompleteSearch from 'components/AutoCompleteSearch';
import { getStoreList } from '../../../services/store/storeFilter';

const styles = (theme) => ({
  linkButton: {
    textDecoration: 'none',
  },
  buttonCard: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignBetweenCenter,
    marginTop: 16,
    padding: '8px 16px 8px 24px',
  },
  buttonText: {
    ...theme.typography.fontSizes.normalText,
    color: theme.palette.textDark,
    margin: 0,
  },
  arrowIcon: {
    color: theme.palette.textPrimary,
  },
});

const useStyles = makeStyles(styles);

const AnotherStore = ({
  storeId,
  handleOpen,
  handleClose,
  setCompareAgaintFilter,
  filterHeadCellsForCompare,
}) => {
  const classes = useStyles();

  const onStoreSearch = (_, value) => {
    if (value?.store_number) {
      setCompareAgaintFilter({ store_number: value?.store_number });
      setTimeout(() => {
        filterHeadCellsForCompare(value?.store_number);
        handleClose();
      }, 0);
    }
  };

  const {
    isLoading,
    data = [],
    refetch,
  } = getStoreList({
    text: '',
    region: [],
    state: [],
    climate: [],
  });

  useEffect(() => {
    refetch();
  }, []);

  return (
    <CustomDialog
      open
      handleOpen={handleOpen}
      handleClose={() => handleClose({ value: null, label: 'None' })}
      isTransparent={false}
    >
      <div style={{ height: 304, width: 280, padding: '0 12px' }}>
        <CustomText
          content={'Store: '}
          classes={classes.fieldLabel}
          paragraph={false}
          variant={'body1'}
        />
        <AutoCompleteSearch
          label='Store'
          selectedValue={null}
          placeholder='Enter Store Name'
          options={data.filter(({ store_number }) => store_number !== storeId)}
          multiple={false}
          loading={isLoading}
          showLabel={false}
          textTransform='uppercase'
          width={'100%'}
          maxWidth={194}
          maxHeight={36}
          textColor={'stroke'}
          onChange={onStoreSearch}
          popperHeight={220}
          disableCloseOnSelect={false}
        />
      </div>
    </CustomDialog>
  );
};

export default AnotherStore;
