import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import CustomCard from 'components/CustomCard';
import { headCells } from './data';
import Table from 'components/Table';
import StoreListFilters from './components/StoreListFilters';
import TableAction from './components/TableAction';

import { getStoresData } from '../../services/stores/stores';
import BreadCrumb from 'components/BreadCrumb';
import LoadingOverlay from 'components/LoadingOverlay';
import { getHeight } from 'utils';

const styles = (theme) => ({
  tableCard: {
    ...theme.content.card,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    width: '100%',
    marginBottom: 16,
  },
  filterActions: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignCenter,
    flexWrap: theme.content.flexStyles.flexWrap.wrap,
  },
});

const useStyles = makeStyles(styles);

const HomePage = () => {
  const [season, setSeason] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [filters, setFilters] = useState({
    store_number: [],
    region: [],
    state: [],
    climate: [],
  });

  const classes = useStyles();

  const { isLoading, data, isFetching, refetch } = getStoresData({
    season,
    filters,
  });

  useEffect(() => {
    if (season?.year) {
      refetch();
    }
  }, [season, filters]);

  useEffect(() => {
    if (data?.length) {
      setTableData([...data]);
    }
  }, [data]);

  const tableAction = (_, __, storeId, floors) => {
    return <TableAction storeId={storeId} floors={floors} season={season} />;
  };

  return (
    <>
      <BreadCrumb />
      <StoreListFilters
        filterActionsClass={classes.filterActions}
        setSeason={setSeason}
        setPayload={setFilters}
        season={season}
        filters={filters}
      />
      <CustomCard cardStyles={classes.tableCard}>
        <LoadingOverlay
          loader={isFetching || isLoading || !season?.year}
          text='Fetching Data'
          position='absolute'
          minHeight={512}
          maxHeight={`calc(100vh - ${getHeight('table-container')})`}
        />
        <Table
          headCells={headCells}
          data={tableData}
          tableAction={tableAction}
          tableHeight={460}
          isLoading={isLoading || isFetching}
          id='stores-table'
          urlPrefix={'/store'}
          queryParams={season ? `season=${season.label}` : ''}
        />
      </CustomCard>
    </>
  );
};

export default HomePage;
