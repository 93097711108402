//TODO Remove the file once api's are created.
export const nullCompareOption = {
  value: null,
  label: 'None',
};

export const headCells = [
  {
    id: 'store_name',
    sortBy: 'store_number',
    column_name: 'store_name',
    type: 'text',
    isVisible: true,
    isLink: true,
    isSearchable: true,
    isSelectable: false,
    disablePadding: false,
    textTransform: 'uppercase',
    prefixId: 'store_number',
    colSpan: 1,
    minWidth: 180,
    colWidth: '20%',
    label: 'Store',
  },
  {
    id: 'area_square_feet',
    column_name: 'area_square_feet',
    type: 'number',
    isVisible: true,
    isLink: false,
    isSearchable: false,
    isSelectable: false,
    disablePadding: false,
    textTransform: 'uppercase',
    colSpan: 1,
    minWidth: 96,
    colWidth: '10%',
    label: 'Total Area (Sq. Ft.)',
  },
  {
    id: 'actual_revenue',
    column_name: 'actual_revenue',
    type: 'number',
    isVisible: true,
    isLink: false,
    isSearchable: false,
    isSelectable: false,
    disablePadding: false,
    textTransform: 'uppercase',
    prefix: '$',
    colSpan: 1,
    minWidth: 96,
    colWidth: '8%',
    label: '$ Revenue',
  },
  {
    id: 'actual_revenue_per_squarefeet',
    column_name: 'actual_revenue_per_squarefeet',
    type: 'number',
    isVisible: true,
    isLink: false,
    isSearchable: false,
    isSelectable: false,
    disablePadding: false,
    textTransform: 'uppercase',
    prefix: '$',
    colSpan: 1,
    minWidth: 96,
    colWidth: '9%',
    label: '$ Revenue/ Sq.Ft',
  },
  {
    id: 'actual_margin',
    column_name: 'actual_margin',
    type: 'number',
    isVisible: true,
    isLink: false,
    isSearchable: false,
    isSelectable: false,
    disablePadding: false,
    textTransform: 'uppercase',
    prefix: '$',
    colSpan: 1,
    minWidth: 96,
    colWidth: '7%',
    label: '$Margin',
  },
  {
    id: 'actual_margin_per_squarefeet',
    column_name: 'actual_margin_per_squarefeet',
    type: 'number',
    isVisible: true,
    isLink: false,
    isSearchable: false,
    isSelectable: false,
    disablePadding: false,
    textTransform: 'uppercase',
    prefix: '$',
    colSpan: 1,
    minWidth: 120,
    colWidth: '10%',
    label: '$Margin/ Sq.Ft',
  },
  {
    id: 'no_of_floor',
    column_name: 'no_of_floor',
    type: 'number',
    isVisible: true,
    isLink: false,
    isSearchable: false,
    isSelectable: false,
    disablePadding: false,
    textTransform: 'inherit',
    colSpan: 1,
    minWidth: 96,
    colWidth: '6%',
    label: '#Floors',
  },
  {
    id: 'no_of_departments',
    column_name: 'no_of_departments',
    type: 'number',
    isVisible: true,
    isLink: false,
    isSearchable: false,
    isSelectable: false,
    disablePadding: false,
    textTransform: 'inherit',
    colSpan: 1,
    minWidth: 96,
    colWidth: '6%',
    label: '#Dept.',
  },
  {
    id: 'last_remodel',
    padding: '12px 4px',
    column_name: 'last_remodel',
    type: 'text',
    isVisible: true,
    isLink: false,
    isSearchable: false,
    isSelectable: false,
    disablePadding: false,
    colSpan: 2,
    textTransform: 'inherit',
    minWidth: 180,
    label: 'Last ReModelled',
    group: true,
    canSortChildren: true,
    subColumns: [
      {
        id: 'last_remodelled_date',
        column_name: 'last_remodelled_date',
        type: 'text',
        isVisible: true,
        isSearchable: false,
        isSelectable: false,
        disablePadding: false,
        colSpan: 1,
        textTransform: 'inherit',
        colWidth: '7%',
        label: 'Date',
      },
      {
        id: 'last_remodelled_name',
        column_name: 'last_remodelled_name',
        type: 'text',
        isVisible: true,
        isSearchable: false,
        isSelectable: false,
        disablePadding: false,
        colSpan: 1,
        textTransform: 'inherit',
        colWidth: '7%',
        label: 'Name',
      },
    ],
  },
  {
    id: 'actions',
    column_name: 'actions',
    type: 'actions',
    isVisible: true,
    isLink: false,
    isSearchable: false,
    isSelectable: false,
    disablePadding: false,
    canSortChildren: true,
    textTransform: 'inherit',
    colSpan: 1,
    minWidth: '9rem',
    colWidth: '10%',
    label: 'Actions',
  },
];
