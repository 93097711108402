import React from 'react';
import { makeStyles } from '@mui/styles';
import CustomCard from 'components/CustomCard';
import SingleSelect from 'components/SingleSelect';
import Export from 'molecules/Export';

const styles = (theme) => ({
  tableCard: {
    ...theme.content.card,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    width: '100%',
    marginTop: 16,
  },
  dataHeader: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignBetweenCenter,
    flexWrap: theme.content.flexStyles.flexWrap.wrap,
    padding: '24px 32px',
    width: '100%',
  },
});

const useStyles = makeStyles(styles);

const DataHeader = ({ season, seasonOptions, onSeasonSelect, filters }) => {
  const classes = useStyles();

  return (
    <CustomCard cardStyles={classes.tableCard}>
      <div className={classes.dataHeader}>
        <SingleSelect
          label={'Season'}
          placeholder={'Select Season'}
          selectedValue={season}
          options={seasonOptions}
          optionLabelKey={'label'}
          width={156}
          maxHeight={36}
          textTransform='capitalize'
          onSelect={onSeasonSelect}
        />
        <Export
          reqestPayload={{
            page: 1,
            limit: 1000,
            search: '',
            order: 1,
            order_by: '',
            season,
            filters,
          }}
          endpoint={`store-master-list/export/`}
        />
      </div>
    </CustomCard>
  );
};

export default DataHeader;
