/**
 * App Global Styles, Themes, Constants go below
 */

export const FLOORS_STRING = {
  0: 'GROUND',
  1: 'FIRST',
  2: 'SECOND',
  3: 'THIRD',
  4: 'FOURTH',
  5: 'FIFTH',
  6: 'SIXTH',
  7: 'SEVENTH',
  8: 'EIGHT',
  9: 'NINTH',
  10: 'TENTH',
};

export const helperTexts = {
  store_dept_index: {
    text: `Department's rank (by revenue) for the store and season`,
    link: null,
  },
  dept_index: {
    text: `Departmen'ts rank (by revenue) across all stores for the season`,
    link: null,
  },
  revenue_area_index: {
    text: `Department's rank (by revenue per sqft) for the store and season`,
    link: null,
  },
  elasticity: {
    text: `Metric that is used to measure the change in the deparments's expected revenue in relation to the change in the space allocated`,
    link: `faq/?section=elasticity`,
  },
  sister_store: {
    text: `Store with the most similar metrics in terms of performance and demographics for the department`,
    link: `faq/?section=sister_store`,
  },
  tier_cluster: {
    text: `Set of stores with similar assortment and performance for the same season`,
    link: `faq/?section=tier_cluster`,
  },
  confidence: {
    text: `Elasticity confidence associated with the space recommendations for the respective department and store (Optimization is done only for High/Medium cases)`,
    link: `faq/?section=confidence`,
  },
  intra_store_optimization: {
    text: `Departments across the same store would be optimized together. (Interchange of space is allowed across different floors or floorplans)`,
    link: `faq/?section=optimization_level`,
  },
  intra_floor_optimization: {
    text: `Departments across the same floor would be optimized together. (No interchange of space between departments across different floors)`,
    link: `faq/?section=optimization_level`,
  },
  intra_floor_plan_optimization: {
    text: `Departments across the same floorplan would be optimized together. (No Interchange of space is allowed across floorplans).`,
    link: `faq/?section=optimization_level`,
  },
  min_area: {
    text: `Minimum area considered for optimization (within the set of constraints)`,
    link: null,
  },
  max_area: {
    text: `Maximum area considered for optimization  (within the set of constraints)`,
    link: null,
  },
  fixed_area: {
    text: ` The departments/rows for which the fixed checkbox is selected will not be optimized and the user input area will be shown in the result`,
    link: null,
  },
  delete: {
    text: `The department is dropped from optimization but its area would be consumed by other departments`,
    link: null,
  },
  add: {
    text: `This is a user input and optimization runs with the assumption that the existing set of departments will have to donate space in order to accommodate the new department's area`,
    link: null,
  },
};

export const helperItems = [
  {
    id: 'sister_store',
    label: 'Sister Stores',
    description: `Store with the most similar metrics in terms of performance and demographics for the department`,
    details: [
      {
        label: 'Objectives',
        data: [
          `For identification of similar stores based on its performance in the previous year`,
          `This is done so that users can get the ability to compare similar performing stores`,
        ],
      },
      {
        label: 'Methodology',
        description: `Based on the stores which are a part of the same tier-cluster, a weighted score is calculated. Metrics used for weighted score calculation are:`,
        data: [
          `Geographical distance (to respective store) `,
          `Revenue of stores in comparison`,
          `Total space alloted to the department (in sqft)`,
        ],
      },
    ],
  },
  {
    id: 'tier_cluster',
    label: 'Tier Cluster',
    description: `Set of stores with similar assortment and performance for the same season`,
    details: [
      {
        label: 'Objectives',
        data: [
          `To identify similar set of stores based on assortment and performance metrics`,
          `For departments in which there is no historical space change, a tier-cluster level median elasticity will be imputed`,
        ],
      },
      {
        label: 'Tiering Process',
        description: `Based on the stores which are a part of the same tier-cluster, a weighted score is calculated. Metrics used for weighted score calculation are:`,
        data: [
          {
            description: `The Tier clusters are created based on two variables: `,
            data: [`Style count`, `Inventory Units`],
          },
          `Four tiers are created from here. 1 being highest tiering and 4 being the lowest tiering`,
        ],
      },
      {
        label: 'Clustering',
        description:
          'Based on the tiers obtained from above step, K-means clustering is done based on performance variables. Each tier would be split into ~5 clusters',
      },
    ],
  },
  {
    id: 'confidence',
    label: 'Elasticity Confidence',
    description: `Elasticity confidence associated with the space recommendations for the respective department and store (Optimization is done only for High/Medium cases)`,
    details: [
      {
        label: 'High',
        description: `Stores across the same tier cluster should have :`,
        data: [
          `Total number of modelable stores should be more than 5`,
          `% of modelable stores should be 50%`,
          `Absolute difference between mean elasticity and median elasticity across stores should be less than 0.25 (Implies that the range of elasticity distribution isnt too much)`,
        ],
      },
      {
        label: 'Medium',
        description: `Stores across the same tier cluster should have : `,
        data: [
          `Total number of modelable stores should be more than 5`,
          `% of modelable stores should be 25%`,
          `Absolute difference between mean elasticity and median elasticity across stores should be less than 0.6`,
        ],
      },
      {
        label: 'Low',
        description: 'All the stores which don’t follow the above conditions',
      },
    ],
    note: `* The criterion for a store-department combination to be modellable is that there should be atleast 2  distinct space changes and the range of space change is >-4% `,
  },
];
