import React from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { IconButton, Tooltip } from '@mui/material';
import SettingsIcon from '../../../assets/svg/settings.svg';
import RunOptimizerContainer from 'molecules/RunOptimizerContainer';
import LastOptimizedResultsContainer from 'molecules/LastOptimizedResultsContainer';
const styles = (theme) => ({
  actionContainer: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignBetweenCenter,
  },
  actionIcon: {
    cursor: theme.content.cursor.pointer,
    transition: 'all 0.3s',
    '&:active': {
      transform: 'scale(0.8)',
    },
  },
});

const useStyles = makeStyles(styles);
const TableAction = ({ storeId, floors, season }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const redirectToConstraints = () => {
    navigate(
      `/constraints/${storeId}/?ly_season=${season?.year} ${season?.name}`
    );
  };

  return (
    <div className={classes.actionContainer}>
      <LastOptimizedResultsContainer
        showIcon
        isActionButton
        isLySeason
        storeId={storeId}
        season={season}
      />
      <Tooltip arrow title={`Constraints`}>
        <IconButton
          disableRipple
          className={classes.actionIcon}
          onClick={() => redirectToConstraints()}
        >
          <img src={SettingsIcon} alt={'settings'} />
        </IconButton>
      </Tooltip>

      <RunOptimizerContainer
        showIcon
        isActionButton
        isLySeason
        storeId={storeId}
        floors={floors}
        storeData={{}}
        season={season}
      />
    </div>
  );
};

export default TableAction;
