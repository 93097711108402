import React from 'react';

const ErrorFallback = () => {
  return (
    <div role='alert'>
      <p>Something went wrong:</p>
      <span
        to='/'
        onClick={() => (window.location.href = '/')}
        style={{
          color: '#202062',
          textDecoration: 'underline',
          cursor: 'pointer',
        }}
      >
        Go to HomePage
      </span>
    </div>
  );
};

export default ErrorFallback;
