import { helperTexts } from 'config/constants';

export const headCells = [
  {
    id: 'floor_str',
    sortBy: 'floor',
    column_name: 'floor_str',
    type: 'text',
    newFlag: 'new_dept_flag',
    addedFlag: 'is_added',
    deleteFlag: 'delete_flag',
    index: 'department_index',
    isVisible: true,
    isSticky: false,
    isLink: false,
    isSearchable: false,
    isSelectable: false,
    disablePadding: false,
    textTransform: 'uppercase',
    colSpan: 1,
    minWidth: 80,
    colWidth: '5%',
    label: 'Floor',
  },
  {
    id: 'floor_plan_area',
    column_name: 'floor_plan_area',
    type: 'text',
    newFlag: 'new_dept_flag',
    addedFlag: 'is_added',
    deleteFlag: 'delete_flag',
    index: 'department_index',
    isVisible: true,
    isSticky: false,
    isLink: false,
    isSearchable: false,
    isSelectable: false,
    disablePadding: false,
    textTransform: 'uppercase',
    colSpan: 1,
    minWidth: 80,
    colWidth: '9%',
    label: 'Floor Plan Dept.',
  },
  {
    id: 'department',
    column_name: 'department',
    type: 'text',
    newFlag: 'new_dept_flag',
    addedFlag: 'is_added',
    deleteFlag: 'delete_flag',
    index: 'department_index',
    isVisible: true,
    isLink: false,
    isSticky: false,
    isSearchable: false,
    isSelectable: false,
    disablePadding: false,
    textTransform: 'uppercase',
    colSpan: 1,
    minWidth: 88,
    colWidth: '10%',
    label: 'Department',
  },
  {
    id: 'final_elasticity_tag',
    sortBy: 'final_elasticity_tag_number',
    column_name: 'final_elasticity_tag',
    type: 'chart',
    newFlag: 'new_dept_flag',
    addedFlag: 'is_added',
    deleteFlag: 'delete_flag',
    index: 'department_index',
    isVisible: true,
    isLink: false,
    isSearchable: false,
    isSelectable: false,
    disablePadding: false,
    textTransform: 'uppercase',
    colSpan: 1,
    minWidth: 80,
    colWidth: '8%',
    label: 'Elasticity',
    rightIcon: 'test',
    helperText: helperTexts.elasticity.text,
    helperLink: helperTexts.elasticity.link,
  },
  {
    id: 'final_tagging',
    sortBy: 'final_tagging_number',
    column_name: 'final_tagging',
    type: 'elasticity',
    newFlag: 'new_dept_flag',
    addedFlag: 'is_added',
    deleteFlag: 'delete_flag',
    index: 'department_index',
    isVisible: true,
    isLink: false,
    isSearchable: false,
    isSelectable: false,
    disablePadding: false,
    textTransform: 'uppercase',
    colSpan: 1,
    minWidth: 88,
    colWidth: '10%',
    label: 'Elasticity Confidence',
    helperText: helperTexts.confidence.text,
    helperLink: helperTexts.confidence.link,
  },
  {
    sortBy: 'ly_area_int',
    id: 'ly_area_int',
    column_name: 'ly_area_int',
    type: 'numeric',
    newFlag: 'new_dept_flag',
    addedFlag: 'is_added',
    deleteFlag: 'delete_flag',
    index: 'department_index',
    isVisible: true,
    isLink: false,
    isSearchable: false,
    isSelectable: false,
    disablePadding: false,
    colSpan: 1,
    minWidth: 80,
    colWidth: '7%',
    textTransform: 'uppercase',
    label: 'LY Area (Sq. ft.)',
  },
  {
    id: 'ly_revenue_int',
    column_name: 'ly_revenue_int',
    type: 'number',
    sortBy: 'ly_revenue_int',
    newFlag: 'new_dept_flag',
    addedFlag: 'is_added',
    deleteFlag: 'delete_flag',
    index: 'department_index',
    isVisible: true,
    isLink: false,
    isSearchable: false,
    isSelectable: false,
    disablePadding: false,
    colSpan: 1,
    minWidth: 80,
    colWidth: '8%',
    textTransform: 'uppercase',
    prefix: '$',
    label: 'LY Revenue',
  },
  {
    id: 'user_min_area',
    sortBy: 'user_min_area',
    column_name: 'user_min_area',
    range: 'sim_area_array',
    rangeIndex: 'min_area_index',
    maxRangeIndex: 'max_area_index',
    toggleId: 'fix_flag',
    type: 'range',
    newFlag: 'new_dept_flag',
    addedFlag: 'is_added',
    deleteFlag: 'delete_flag',
    index: 'department_index',
    isVisible: true,
    isLink: false,
    isSearchable: false,
    isSelectable: false,
    disablePadding: false,
    textTransform: 'uppercase',
    colSpan: 1,
    minWidth: 114,
    colWidth: '8%',
    label: 'Min Area',
    helperText: helperTexts.min_area.text,
    helperLink: helperTexts.min_area.link,
  },
  {
    id: 'user_max_area',
    sortBy: 'user_max_area',
    column_name: 'user_max_area',
    range: 'sim_area_array',
    rangeIndex: 'max_area_index',
    minRangeIndex: 'min_area_index',
    toggleId: 'fix_flag',
    type: 'range',
    newFlag: 'new_dept_flag',
    addedFlag: 'is_added',
    deleteFlag: 'delete_flag',
    index: 'department_index',
    isVisible: true,
    isLink: false,
    isSearchable: false,
    isSelectable: false,
    disablePadding: false,
    textTransform: 'uppercase',
    colSpan: 1,
    minWidth: 114,
    colWidth: '9%',
    label: 'Max Area',
    helperText: helperTexts.max_area.text,
    helperLink: helperTexts.max_area.link,
  },
  {
    id: 'step_size',
    sortBy: 'step_size',
    column_name: 'step_size',
    range: 'step_size_array',
    rangeIndex: 'step_size_index',
    toggleId: 'fix_flag',
    type: 'range',
    newFlag: 'new_dept_flag',
    addedFlag: 'is_added',
    deleteFlag: 'delete_flag',
    index: 'department_index',
    isVisible: true,
    isLink: false,
    isSearchable: false,
    isSelectable: false,
    disablePadding: false,
    textTransform: 'uppercase',
    colSpan: 1,
    minWidth: 112,
    colWidth: '6%',
    label: 'Simulated Step Size',
  },
  {
    id: 'fix_flag',
    sortBy: 'fix_flag',
    column_name: 'fix_flag',
    type: 'checkbox',
    newFlag: 'new_dept_flag',
    addedFlag: 'is_added',
    deleteFlag: 'delete_flag',
    index: 'department_index',
    elasiticity: 'final_elasticity_tag',
    elasiticityConfidence: 'final_tagging',
    isVisible: true,
    isLink: false,
    isSearchable: false,
    isSelectable: false,
    disablePadding: false,
    canSortChildren: true,
    textTransform: 'uppercase',
    colSpan: 1,
    minWidth: 64,
    colWidth: '4%',
    label: 'Fixed',
    helperText: helperTexts.fixed_area.text,
    helperLink: helperTexts.fixed_area.link,
  },
  {
    id: 'user_fix_area',
    sortBy: 'user_fix_area',
    column_name: 'user_fix_area',
    disabled: 'disable_user_fix_area',
    showId: 'fix_flag',
    type: 'input',
    newFlag: 'new_dept_flag',
    addedFlag: 'is_added',
    deleteFlag: 'delete_flag',
    index: 'department_index',
    isVisible: true,
    isLink: false,
    isSearchable: false,
    isSelectable: false,
    disablePadding: false,
    canSortChildren: true,
    textTransform: 'uppercase',
    colSpan: 1,
    minWidth: 112,
    colWidth: '13%',
    label: 'Fixed Area',
  },
  {
    id: 'actions',
    column_name: 'actions',
    type: 'actions',
    newFlag: 'new_dept_flag',
    addedFlag: 'is_added',
    deleteFlag: 'delete_flag',
    index: 'department_index',
    isVisible: true,
    isLink: false,
    isSearchable: false,
    isSelectable: false,
    disablePadding: false,
    canSortChildren: true,
    textTransform: 'inherit',
    colSpan: 1,
    minWidth: 72,
    colWidth: '3%',
    label: 'Action',
    helperText: helperTexts.delete.text,
    helperLink: helperTexts.delete.link,
  },
];
