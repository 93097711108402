import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import CustomText from '../../../components/CustomText';
import RunOptimizerContainer from 'molecules/RunOptimizerContainer';

import AreaIcon from '../../../assets/svg/area.svg';
import SettingsIcon from '../../../assets/svg/settingsPrimary.svg';
import TotalArea from './TotalArea';
import Export from 'molecules/Export';
import LastOptimizedResultsContainer from 'molecules/LastOptimizedResultsContainer';

const styles = (theme) => ({
  actionContainer: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignStartCenter,
    cursor: theme.content.cursor.pointer,
    width: 'fit-content',
  },
  content: {
    ...theme.typography.fontSizes.normalText,
    color: theme.palette.textPrimary,
    letterSpacing: '-0.42px',
    marginRight: 8,
  },
  actionIcon: {
    width: 16,
    height: 18,
  },
});

const useStyles = makeStyles(styles);

const StoreFilterActions = ({
  season,
  storeDetails,
  storeData,
  compareAgainstFilter,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams();

  const [openArea, setOpenArea] = useState(false);

  const handleOpenArea = () => {
    setOpenArea(true);
  };

  const handleCloseArea = () => {
    setOpenArea(false);
  };

  const setConstraints = () => {
    storeDetails &&
      navigate(
        `/constraints/${storeDetails?.store_number}/?ly_season=${season?.year} ${season?.name}`
      );
  };

  return (
    <Grid
      container
      spacing={1}
      columns={{ xs: 12, sm: 12, md: 12, lg: 15, xl: 15 }}
      alignItems={'center'}
      justifyContent={'flex-end'}
    >
      <Grid item xs={6} md={4} lg={2.5} xl={2.7}>
        <div
          className={classes.actionContainer}
          onClick={() => handleOpenArea()}
        >
          <CustomText
            content={'Total Area'}
            variant={'body1'}
            classes={classes.content}
          />
          <img src={AreaIcon} alt='area' className={classes.actionIcon} />
        </div>
      </Grid>
      <Grid item xs={6} md={4} lg={4.2} xl={4.3}>
        <div className={classes.actionContainer}>
          <LastOptimizedResultsContainer
            showIcon
            showLabel
            storeId={storeDetails?.store_number}
            season={season}
          />
        </div>
      </Grid>
      <Grid item xs={6} md={4} lg={2.6} xl={2.7}>
        <div
          className={classes.actionContainer}
          onClick={() => setConstraints()}
        >
          <CustomText
            content={'Constraints'}
            variant={'body1'}
            classes={classes.content}
          />
          <img src={SettingsIcon} alt='area' className={classes.actionIcon} />
        </div>
      </Grid>
      <Grid item xs={6} md={4} lg={2.7} xl={2.8}>
        <div className={classes.actionContainer}>
          <RunOptimizerContainer
            showIcon
            showLabel
            isLySeason
            storeId={storeDetails?.store_number}
            floors={storeDetails?.no_of_floor}
            storeData={storeData}
            season={season}
          />
        </div>
      </Grid>
      <Grid item xs={6} md={4} lg={3} xl={2.5}>
        <Export
          reqestPayload={{
            season: { year: String(season?.year), name: season?.name },
            compare_against: compareAgainstFilter,
          }}
          endpoint={`department/store-master/${params?.id}/export/`}
        />
      </Grid>
      {openArea && (
        <TotalArea
          season={season}
          handleOpen={handleOpenArea}
          handleClose={handleCloseArea}
          isTransparent={false}
        />
      )}
    </Grid>
  );
};

export default StoreFilterActions;
