import Toast from 'components/Toast';
import { useQuery } from 'react-query';
import axiosInstance from 'Utils/axios';

const exportApi = (reqestPayload, endpoint) => {
  return axiosInstance.post(endpoint, reqestPayload);
};

export const floorPlanApi = (payload) => {
  return axiosInstance.get(
    `department/floor_plan/${payload.id}/${payload.year}/${payload.name}/image/`
  );
};

export const getExportApi = (payload, endpoint) => {
  return useQuery([endpoint, payload], () => exportApi(payload, endpoint), {
    enabled: false,
    onSuccess: (data) => {
      let a = document.createElement('a');
      let csvContent =
        'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(data.data);
      a.href = csvContent;
      a.download = `${payload.season.name}_${payload.season.year}_stores.csv`;
      a.click();
    },
  });
};

export const getFloorPlanApi = (payload) => {
  return useQuery(['floor-plan-layout', payload], () => floorPlanApi(payload), {
    enabled: false,
    select: (data) => {
      return data?.data;
    },
    onError: () => {
      Toast('Something Went Wrong', 'error');
    },
  });
};
