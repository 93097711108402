import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import CustomText from '../CustomText';
import { ReactComponent as UncheckedIcon } from '../../assets/svg/unchecked.svg';
import { ReactComponent as CheckedIcon } from '../../assets/svg/check.svg';

const styles = (theme) => ({
  fieldLabel: {
    ...theme.typography.fontSizes.normalText,
    marginBottom: '4px',
    color: theme.palette.textSecondary,
    fontWeight: theme.typography.fontWeight.medium,
  },
  form: {
    margin: '8px 0',
    '& .MuiOutlinedInput-notchedOutline': {
      border: `0.5px solid ${theme.palette.stroke} !important`,
      borderRadius: '2px',
    },
  },
  autocompleteRoot: (props) => ({
    ...theme.typography.fontSizes.selectText,
    color: theme.palette.textDark,
    maxHeight: props.maxHeight,
    padding: '0 8px !important',
    paddingRight: '32px !important',
    background: theme.palette.textWhite,
  }),
  autocompleteCheckOption: (props) => ({
    ...theme.typography.fontSizes.selectText,
    color: theme.palette.textDark,
    textTransform: theme.typography.textTransform[props.textTransform],
    padding: '0 !important',
    paddingRight: '8px !important',
    cursor: theme.content.cursor.pointer,

    '&[aria-selected="true"]': {
      background: 'transparent !important',
      '&.Mui-focused': {
        background: 'rgba(0, 0, 0, 0.04) !important',
      },
      '&.Mui-focusVisible': {
        background: 'rgba(0, 0, 0, 0.12) !important',
      },
    },
  }),
  autoCompleteRenderContent: (props) => ({
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignBetweenCenter,
    maxWidth: '40%',
    margin: 0,
    whiteSpace: 'nowrap',
    overflow: theme.content.overflow.hidden,
    textTransform: theme.typography.textTransform[props.textTransform],
    textOverflow: 'ellipsis',
  }),
  autoCompleteRenderContentValue: (props) => ({
    ...theme.typography.fontSizes.selectText,
    color: theme.palette.textDark,
    whiteSpace: 'nowrap',
    overflow: theme.content.overflow.hidden,
    textTransform: theme.typography.textTransform[props.textTransform],
    textOverflow: 'ellipsis',
  }),
  autoCompleteRenderContentTag: {
    ...theme.typography.fontSizes.subText,
    marginLeft: 4,
    padding: 4,
    borderRadius: 4,
    color: theme.palette.textDark,
    background: theme.palette.bgTransparent,
  },
  checkBoxIcon: {
    fontSize: '10px !important',
    color: theme.palette.stroke,
    background: 'transparent !important',
  },
  inputFieldEmpty: {
    ...theme.typography.fontSizes.selectText,
    padding: `0 !important`,
    boxSizing: theme.content.boxSizing.borderBox,
    height: '2.25rem',
    color: theme.palette.textDark,

    '&::placeholder': {
      opacity: 1,
    },
  },
  inputField: {
    ...theme.typography.fontSizes.selectText,
    padding: `0 6px !important`,
    maxWidth: '56%',
    boxSizing: theme.content.boxSizing.borderBox,
    height: '2.25rem',
    color: theme.palette.textDark,
    opacity: 1,

    '&::placeholder': {
      opacity: 1,
    },
  },
  disabled: {
    '& .Mui-disabled': {
      WebkitTextFillColor: `${theme.palette.textDark} !important`,
    },
  },
  autoCompletePaper: (props) => ({
    marginTop: '0.15rem',
    width: props.width,
    border: `0.5px solid ${theme.palette.stroke}`,
    borderRadius: '2px',
    boxShadow: 'none',
  }),
  autoCompleteNoOption: {
    ...theme.typography.fontSizes.selectText,
    color: theme.palette.textDark,
    paddingLeft: '12px',
    background: 'transparent',
  },
  autoCompleteLoadingOption: {
    ...theme.typography.fontSizes.selectText,
    color: theme.palette.stroke,
    paddingLeft: '12px',
    background: 'transparent',
  },
  autoCompleteOption: (props) => ({
    ...theme.typography.fontSizes.selectText,
    color: theme.palette.textDark,
    paddingLeft: '12px',
    background: 'transparent',
    textTransform: theme.typography.textTransform[props.textTransform],

    '&[aria-selected="true"]': {
      background: 'transparent !important',
    },
  }),
  clearIcon: {
    width: 14,
    marginRight: 8,
    background: 'none !important',

    '& .MuiSvgIcon-root': {
      height: '14px',
      width: '14px',
    },
  },
  popupIndicator: {
    width: 12,
    background: 'none !important',
  },
  tooltipPopper: (props) => ({
    textTransform: theme.typography.textTransform[props.textTransform],
  }),
});

const useStyles = makeStyles(styles);

const MultiSelect = (props) => {
  const {
    label,
    placeholder,
    selectedValues,
    options,
    limitTags,
    disableSearch,
    loading,
    width,
    maxHeight,
    textTransform,
    onSelect,
    textStyle,
  } = props;

  const styleProps = {
    width: `${width}px`,
    maxHeight: `${maxHeight}px`,
    textTransform,
  };
  const classes = useStyles(styleProps);
  const [open, setOpen] = useState(false);

  const customFilterOption = (options) => options;

  return (
    <div>
      <CustomText
        content={label}
        classes={classes.fieldLabel}
        paragraph={false}
        variant={'body1'}
      />
      <FormControl
        sx={{ m: 1, width, maxHeight }}
        size='small'
        className={classes.form}
      >
        <Autocomplete
          ListboxProps={{
            style: { textTransform: textStyle || 'inherit' },
          }}
          style={{ textTransform: textStyle || 'inherit' }}
          open={open}
          multiple
          value={selectedValues}
          loading={loading}
          disableCloseOnSelect
          options={options}
          onChange={onSelect}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          isOptionEqualToValue={(option, value) => option.label === value.label}
          filterOptions={disableSearch && customFilterOption}
          classes={{
            paper: classes.autoCompletePaper,
            option: classes.autoCompleteOption,
            noOptions: classes.autoCompleteNoOption,
            loading: classes.autoCompleteLoadingOption,
            clearIndicator: classes.clearIcon,
            popupIndicator: classes.popupIndicator,
          }}
          renderTags={(value) => (
            <div className={classes.autoCompleteRenderContent}>
              <Tooltip
                arrow
                disableFocusListener={open}
                disableHoverListener={open}
                title={`${value?.map((option) => {
                  return ` ${option.value}`;
                })}`}
                classes={{ popper: classes.tooltipPopper }}
              >
                <div className={classes.autoCompleteRenderContentValue}>
                  {value?.map((option, index) =>
                    index < limitTags
                      ? `${option.value}${
                          index === value?.length - 1 ? '' : ', '
                        }`
                      : null
                  )}
                </div>
              </Tooltip>

              {value?.length > limitTags ? (
                <span className={classes.autoCompleteRenderContentTag}>{`+${
                  value?.length - 1
                }`}</span>
              ) : null}
            </div>
          )}
          renderOption={(props, option, { selected }) => (
            <li
              {...props}
              className={classnames(
                'MuiAutocomplete-option',
                classes.autocompleteCheckOption
              )}
            >
              <Checkbox
                icon={<UncheckedIcon />}
                checkedIcon={<CheckedIcon />}
                checked={selected}
                className={classes.checkBoxIcon}
              />
              {option.value}
            </li>
          )}
          renderInput={(params) => {
            params.inputProps.autoComplete = 'new-password';
            return (
              <TextField
                {...params}
                maxRows={1}
                autoComplete='off'
                disabled={disableSearch}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: params.InputProps.endAdornment,
                  placeholder: selectedValues?.length === 0 ? placeholder : '',
                  classes: {
                    root: classes.autocompleteRoot,
                    disabled: classes.disabled,
                    input:
                      selectedValues?.length > 0
                        ? classes.inputField
                        : classes.inputFieldEmpty,
                  },
                }}
              />
            );
          }}
        />
      </FormControl>
    </div>
  );
};

MultiSelect.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  selectedValues: PropTypes.array,
  options: PropTypes.array,
  limitTags: PropTypes.number,
  disableSearch: PropTypes.bool,
  loading: PropTypes.bool,
  width: PropTypes.number,
  maxHeight: PropTypes.number,
  textTransform: PropTypes.string,
  onSelect: PropTypes.func,
};

export default MultiSelect;
