import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Checkbox from '@mui/material/Checkbox';
import CustomDialog from 'components/CustomDialog';
import CustomText from 'components/CustomText';
import Loader from 'components/Loader';

import { ReactComponent as UncheckedIcon } from 'assets/svg/unchecked.svg';
import { ReactComponent as CheckedIcon } from 'assets/svg/check.svg';

const styles = (theme) => ({
  floorsContainer: {
    ...theme.content.flexStyles.flexCol,
    ...theme.content.flexStyles.flexAlignBetweenStart,
  },
  floorItem: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignStartCenter,
    margin: '12px 0',
  },
  labelText: {
    ...theme.typography.fontSizes.normalText,
    color: theme.palette.stroke,
    marginTop: '16px',
  },
  text: {
    ...theme.typography.fontSizes.normalText,
    color: theme.palette.textDark,
  },
  checkBoxIcon: {
    fontSize: '10px !important',
    color: theme.palette.stroke,
    background: 'transparent !important',
    marginRight: 8,
  },
  listContainer: {
    width: '100%',
  },
  optmizeButton: {
    marginLeft: 12,
  },
});

const useStyles = makeStyles(styles);

const FloorPlans = ({
  season,
  floorOptions,
  floorPlanOptions,
  handleOpen,
  handleClose,
  isLoading,
  runOptimizer,
}) => {
  const classes = useStyles();
  const [selectedFloorPlans, setSelectedFloorPlans] = useState([]);

  const onSelectChange = (event) => {
    const value = event.target.name;
    const values = selectedFloorPlans;
    const index = values?.indexOf(value);

    if (index > -1) {
      values?.splice(index, 1);
    } else {
      values?.push(value);
    }

    setSelectedFloorPlans([...values]);
  };

  const handleButtonClick = () => {
    runOptimizer('floor_plan', season, floorOptions, selectedFloorPlans);
  };

  useEffect(() => {
    if (floorPlanOptions?.length) {
      setSelectedFloorPlans([...floorPlanOptions]);
    }
  }, [floorPlanOptions?.length]);

  return (
    <CustomDialog
      open
      handleOpen={handleOpen}
      handleClose={handleClose}
      disableClose={isLoading}
      handleButtonClick={handleButtonClick}
      footer={true}
      showButton={
        selectedFloorPlans?.length > 0 && floorOptions?.length > 0 && !isLoading
      }
      isTransparent={false}
    >
      <div style={{ height: 256, width: 256 }}>
        {isLoading ? (
          <Loader />
        ) : (
          <div className={classes.floorsContainer}>
            <CustomText
              content={'Select Floor Plans'}
              variant='body1'
              classes={classes.labelText}
            />
            <div className={classes.listContainer}>
              {floorPlanOptions?.map((floorPlan, index) => {
                return (
                  <div
                    key={`floor-${floorPlan}-${index}`}
                    className={classes.floorItem}
                  >
                    <Checkbox
                      name={`${floorPlan}`}
                      checked={selectedFloorPlans?.indexOf(floorPlan) > -1}
                      icon={<UncheckedIcon />}
                      checkedIcon={<CheckedIcon />}
                      className={classes.checkBoxIcon}
                      onChange={(e) => onSelectChange(e)}
                    />
                    <CustomText
                      content={floorPlan}
                      variant='body1'
                      classes={classes.text}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </CustomDialog>
  );
};

export default FloorPlans;
