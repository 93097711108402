import React from 'react';
import { makeStyles } from '@mui/styles';

import HomeIcon from '../../assets/svg/home.svg';
import CustomText from 'components/CustomText';
import { Link } from 'react-router-dom';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';

const styles = (theme) => ({
  container: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignStartCenter,
    margin: '0 0 24px 0',
  },
  text: {
    ...theme.typography.fontSizes.breadCrumbText,
    margin: '0 8px',
    color: theme.palette.bgBreadCrumbIcon,
    fontSize: `0.866rem`,
    lineHeight: `0.888rem`,
  },
  link: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignStartCenter,
    textDecoration: 'none',
  },
  arrow: {
    color: theme.palette.bgBreadCrumbIcon,
  },
});

const useStyles = makeStyles(styles);

const BreadCrumb = ({ subRoutes, queryParams }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Link
        to={queryParams && queryParams !== '' ? `/?season=${queryParams}` : '/'}
        className={classes.link}
      >
        <img src={HomeIcon} alt='home' style={{ height: 12 }} />
        <CustomText content={'Store'} classes={classes.text} variant='body1' />
      </Link>
      {subRoutes?.map((route) =>
        route?.text ? (
          route?.link ? (
            <Link to={route.link} className={classes.link} key={route.text}>
              <KeyboardArrowRightRoundedIcon className={classes.arrow} />
              <CustomText
                content={route.text}
                classes={classes.text}
                variant='body1'
              />
            </Link>
          ) : (
            <div className={classes.link} key={route.text}>
              <KeyboardArrowRightRoundedIcon className={classes.arrow} />
              <CustomText
                content={route.text}
                classes={classes.text}
                variant='body1'
              />
            </div>
          )
        ) : null
      )}
    </div>
  );
};

export default BreadCrumb;
