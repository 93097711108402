import React, { useEffect } from 'react';
import { verifyTokenStatus } from '../actions/authActions';
import { connect } from 'react-redux';
import { firebaseobj } from 'pages/Auth/firebase';

const MetaDataComponent = (props) => {
  const checkUsrSession = () => {
    props.checkUserSession();
  };
  // const fetchDomainDetails = () => {
  //   let loc = new URL(window.location.origin);
  //   props.fetchTenantInfo(loc.hostname);
  // };
  // useEffect(() => {
  //   fetchDomainDetails();
  // }, []);
  useEffect(() => {
    if (props.isTenantInfoFetched) {
      firebaseobj.auth().tenantId =
        props.tenantDetails['google_tenant_identity'];
      checkUsrSession();
    }
    firebaseobj.auth().tenantId = 'dsg-spacesmart-cgrda'; //Temporary hardcoding
    checkUsrSession();
  }, [props.isTenantInfoFetched]);
  return <>{props.children}</>;
};

const mapStateToProps = (state) => ({
  isTenantInfoFetched: state.authReducer.isTenantInfoFetched,
  tenantDetails: state.authReducer.tenantId,
});
const mapActionToProps = {
  // fetchTenantInfo: getTenantId,
  checkUserSession: verifyTokenStatus,
};

export default connect(mapStateToProps, mapActionToProps)(MetaDataComponent);
