import React from 'react';

const Copyright = (props) => {
  const { year, logo, backgroundImage } = { ...props };
  return (
    <div
      id='leftImgDiv'
      className='h-md-100'
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <img className='logo-img' src={logo} style={{ visibility: 'hidden' }} />
      <div className='app-info-container'>
        <h1 className=''>IA Smart Platform</h1>
        <p className='copyright-text'>©copyright@impactanalytics{year}</p>
      </div>
    </div>
  );
};

export default Copyright;
