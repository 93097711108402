import { useQuery } from 'react-query';
import axiosInstance from 'Utils/axios';

const fetchStoreFilters = ({ season, filters }) => {
  const reqestPayload = {
    page: 1,
    limit: 1000,
    search: '', // ask backend to remove
    order: 1,
    order_by: '',
    season,
    filters,
  };
  return axiosInstance.post(`store-master-list/`, reqestPayload);
};

export const getStoresData = (payload) => {
  return useQuery(['stores', payload], () => fetchStoreFilters(payload), {
    enabled: false,
    select: (data) => {
      const stores = data.data.data;
      return stores;
    },
  });
};
