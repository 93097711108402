import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import CustomDialog from 'components/CustomDialog';
import SingleSelect from 'components/SingleSelect';
import { KeyboardArrowRight } from '@mui/icons-material';
import CustomCard from 'components/CustomCard';
import { getSeasons } from 'services/optimizer/optimizer';
import Loader from 'components/Loader';

const styles = (theme) => ({
  linkButton: {
    textDecoration: 'none',
  },
  buttonCardHovered: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignBetweenCenter,
    background: theme.palette.textPrimary,
    marginTop: 16,
    padding: '6px 16px 6px 24px',
  },
  buttonCard: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignBetweenCenter,
    marginTop: 16,
    padding: '6px 16px 6px 24px',
  },
  buttonTextHovered: {
    ...theme.typography.fontSizes.normalText,
    color: theme.palette.textWhite,
    margin: 0,
  },
  buttonText: {
    ...theme.typography.fontSizes.normalText,
    color: theme.palette.textDark,
    margin: 0,
  },
  arrowIconHovered: {
    color: theme.palette.textWhite,
  },
  arrowIcon: {
    color: theme.palette.textPrimary,
  },
  zeroState: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignCenter,
    ...theme.typography.fontSizes.normalText,
    color: theme.palette.stroke,
    height: '100%',
  },
});

const useStyles = makeStyles(styles);

const LastOptimizedResults = ({ season, storeId, handleOpen, handleClose }) => {
  const classes = useStyles();
  const [seasonValue, setSeasonValue] = useState(null);
  const [isHovered, setIsHovered] = useState(false);

  const onSeasonSelect = (_, value) => {
    setSeasonValue(value);
  };

  const {
    isLoading,
    data: seasons,
    isFetching,
    refetch,
  } = getSeasons({
    season: {
      year: season?.year,
      name: season?.name,
    },
    store_id: storeId,
  });

  useEffect(() => {
    if (seasons?.length) {
      const defaultSeason = seasons.find((season) => season.selected);

      if (defaultSeason?.year) {
        setSeasonValue(defaultSeason);
      }
    }
  }, [seasons]);

  useEffect(() => {
    if (season?.year) {
      refetch();
    }
  }, [season]);

  return (
    <CustomDialog
      open
      handleOpen={handleOpen}
      handleClose={handleClose}
      isTransparent={false}
    >
      <div style={{ height: 304, width: 280, padding: '0 12px' }}>
        {isLoading ? (
          <Loader />
        ) : seasons?.length > 0 ? (
          <div>
            <SingleSelect
              label={'Season'}
              placeholder={'Select Season'}
              selectedValue={seasonValue}
              options={seasons || []}
              optionLabelKey={'label'}
              loading={isLoading || isFetching}
              width={156}
              maxHeight={36}
              textTransform='capitalize'
              onSelect={onSeasonSelect}
            />
            {seasonValue?.year && (
              <Link
                to={`/optimized/${storeId}/${seasonValue?.level_flag}/?optimized_season=${seasonValue?.label}&ly_season=${season?.year} ${season?.name}`}
                className={classes.linkButton}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <CustomCard
                  cardStyles={
                    isHovered ? classes.buttonCardHovered : classes.buttonCard
                  }
                >
                  <p
                    className={
                      isHovered ? classes.buttonTextHovered : classes.buttonText
                    }
                  >
                    Results
                  </p>
                  <KeyboardArrowRight
                    className={
                      isHovered ? classes.arrowIconHovered : classes.arrowIcon
                    }
                  />
                </CustomCard>
              </Link>
            )}
          </div>
        ) : (
          <div className={classes.zeroState}>No Optimization Results Found</div>
        )}
      </div>
    </CustomDialog>
  );
};

export default LastOptimizedResults;
