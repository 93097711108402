import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import CustomCard from 'components/CustomCard';
import StoreModelInfo from './components/StoreModelInfo';
import StoreConstraints from './components/StoreConstraints';
import Loader from './components/Loader';
import BreadCrumb from 'components/BreadCrumb';

import {
  getConstraintFilters,
  runOptimizer,
  saveStoreConstraints,
  fetchStoreConstraints,
} from '../../services/constraints/constraints';
import { customSearch } from '../../utils';
import Toast from 'components/Toast';
import { FLOORS_STRING } from 'config/constants';

const styles = (theme) => ({
  loader: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignCenter,
    height: 492,
    width: '100%',
    color: theme.palette.textPrimary,
  },
  contentCard: {
    ...theme.content.card,
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignBetweenCenter,
    width: '100%',
    margin: '16px 0 32px 0',
    padding: '24px 32px',
  },
  filterActions: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignCenter,
    flexWrap: theme.content.flexStyles.flexWrap.wrap,
  },
});

const useStyles = makeStyles(styles);

const ConstraintsPage = () => {
  const classes = useStyles();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const season = searchParams.get('ly_season');
  const [data, setData] = useState([]);
  const [newDepartments, setNewDepartments] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [storeDetails, setStoreDetails] = useState(null);
  const [seasonOptions, setSeasonOptions] = useState([]);
  const [seasonValue, setSeasonValue] = useState(null);
  const [isOptimizerRunning, setIsOptimizerRunning] = useState(false);

  const selectAll = (key) => {
    let tableData = [];
    const index = selectedColumns?.indexOf(key);

    tableData = data?.map((item) => {
      if (
        item.final_elasticity_tag &&
        item.final_tagging !== 'Low' &&
        !item.new_dept_flag
      ) {
        item[key] = index > -1 ? 0 : 1;
      }
      item.user_dept_selected = 1;
      return { ...item };
    });
    if (index > -1) {
      selectedColumns?.splice(selectedColumns.indexOf(key), 1);
    } else {
      selectedColumns?.push(key);
    }
    setData([...tableData]);
    setSelectedColumns([...selectedColumns]);
  };

  const setFixedArea = (key, index, value, search, disableField) => {
    const tableData = [...data];
    let departmentOrderIndex = index;
    const tableCurrentObj = tableData?.find((department, orderIndex) => {
      departmentOrderIndex = orderIndex;
      return department.department_index === index;
    });
    if (search) {
      tableCurrentObj[key] = customSearch(
        tableCurrentObj?.sim_area_array,
        value
      );

      if (tableCurrentObj[key] !== parseInt(value) && key === 'user_fix_area') {
        Toast('Area has been set to the closest simulation value', 'info');
      }
    } else {
      tableCurrentObj[key] = value;
    }

    tableCurrentObj.disable_user_fix_area = disableField;
    tableCurrentObj.user_dept_selected = 1;
    tableData[departmentOrderIndex] = { ...tableCurrentObj };
    setData([...tableData]);
  };

  const increaseValue = (dataKey, indexKey, valueKey, index) => {
    const tableData = [...data];
    let departmentOrderIndex = index;
    const tableCurrentObj = tableData?.find((department, orderIndex) => {
      departmentOrderIndex = orderIndex;
      return department.department_index === index;
    });
    tableCurrentObj[indexKey] = Math.min(
      tableCurrentObj[dataKey]?.length - 1,
      tableCurrentObj[indexKey] + 1
    );

    tableCurrentObj[valueKey] =
      tableCurrentObj[dataKey][tableCurrentObj[indexKey]];
    tableCurrentObj.user_dept_selected = 1;

    tableData[departmentOrderIndex] = { ...tableCurrentObj };
    setData([...tableData]);
  };

  const decreaseValue = (dataKey, indexKey, valueKey, index) => {
    const tableData = [...data];
    let departmentOrderIndex = index;
    const tableCurrentObj = tableData?.find((department, orderIndex) => {
      departmentOrderIndex = orderIndex;
      return department.department_index === index;
    });
    tableCurrentObj[indexKey] = Math.max(0, tableCurrentObj[indexKey] - 1);

    tableCurrentObj[valueKey] =
      tableCurrentObj[dataKey][tableCurrentObj[indexKey]];
    tableCurrentObj.user_dept_selected = 1;

    tableData[departmentOrderIndex] = { ...tableCurrentObj };
    setData([...tableData]);
  };

  const toggleEditable = (key, index) => {
    const tableData = [...data];
    let departmentOrderIndex = index;
    const tableCurrentObj = tableData?.find((department, orderIndex) => {
      departmentOrderIndex = orderIndex;
      return department.department_index === index;
    });
    tableCurrentObj[key] = !tableCurrentObj[key];

    tableData[departmentOrderIndex] = { ...tableCurrentObj };
    setData([...tableData]);
  };

  const toggleFixableArea = (disabled, key, index) => {
    const tableData = [...data];
    let departmentOrderIndex = index;
    const tableCurrentObj = tableData?.find((department, orderIndex) => {
      departmentOrderIndex = orderIndex;
      return department.department_index === index;
    });
    if (disabled) {
      Toast(
        tableCurrentObj?.new_dept_flag
          ? 'Unable to perform action on newly added departments'
          : 'Unable to perform action on departments with low elasticity',
        'info'
      );
    } else {
      tableCurrentObj[key] = tableCurrentObj[key] === 0 ? 1 : 0;
      tableCurrentObj.user_dept_selected = 1;

      if (!tableCurrentObj[key]) {
        selectedColumns?.splice(selectedColumns.indexOf(key), 1);
      }
    }

    tableData[departmentOrderIndex] = { ...tableCurrentObj };
    setData([...tableData]);
    setSelectedColumns([...selectedColumns]);
  };

  const onSeasonSelect = (_, value) => {
    if (value?.label) {
      setSeasonValue(value);
    }
  };

  const addNewDepartment = (floor, department) => {
    const newDepartment = { ...department };

    newDepartment.floor = floor?.value;
    newDepartment.floor_str = floor?.label;
    newDepartment.is_added = true;
    newDepartment.disable_user_fix_area = true;
    newDepartment.user_dept_selected = 1;
    newDepartment.department_index = data?.length;

    const departments = [...newDepartments]?.filter((department) => {
      return department.department !== newDepartment?.department;
    });

    const allDepartments = [...data, newDepartment];
    setData(allDepartments);
    setNewDepartments([...departments]);
  };

  const addDepartment = (index) => {
    const departments = [...data];
    let departmentOrderIndex = index;
    const departmentObj = departments?.find((department, departmentOrder) => {
      departmentOrderIndex = departmentOrder;
      return department.department_index === index;
    });

    departmentObj.delete_flag = 0;
    departmentObj.user_dept_selected = 1;
    departments[departmentOrderIndex] = { ...departmentObj };

    setData([...departments]);
  };

  const removeDepartment = (index) => {
    const departments = [...data];
    let departmentOrderIndex = index;
    const departmentObj = departments?.find((department, departmentOrder) => {
      departmentOrderIndex = departmentOrder;
      return department.department_index === index;
    });

    if (departmentObj?.is_added) {
      departments.splice(departmentOrderIndex, 1);
      departmentObj.is_added = false;
      departmentObj.floor = null;
      departmentObj.user_dept_selected = 0;
      departmentObj.new_dept_index = newDepartments?.length;
      delete departmentObj.department_index;
      delete departmentObj.floor_str;

      setNewDepartments([...newDepartments, departmentObj]);
    } else {
      departmentObj.delete_flag = 1;
      departmentObj.user_dept_selected = 1;
      departments[departmentOrderIndex] = departmentObj;
    }

    setData([...departments]);
  };

  const saveConstraints = (cb) => {
    mutateConstraints({
      storeId: storeDetails?.store_number,
      season: seasonValue,
      data,
      cb,
    });
  };

  const optimizeData = (level_flag, floors) => {
    mutateOptimizer({
      data,
      season: seasonValue,
      floors,
      storeId: params?.id,
      level_flag,
    });
  };

  const onComplete = () => {
    refetchStoreDepartmentDetails();
  };

  const [loading, setLoading] = useState(false);
  const [constraintData, setConstraintData] = useState([]);

  const refetchStoreDepartmentDetails = async () => {
    try {
      setLoading(true);

      const response = await fetchStoreConstraints({
        season: {
          year: seasonValue?.year,
          name: seasonValue?.name,
        },
        store_id: params?.id,
      });

      response.data.data.forEach((store, index) => {
        store.department_index = index;
        store.store_number = params?.id;
        store.floor_str = FLOORS_STRING[store.floor];
        store.min_area_index = store?.sim_area_array?.indexOf(
          store.user_min_area
        );
        store.max_area_index = store?.sim_area_array?.indexOf(
          store.user_max_area
        );
        store.user_fix_area = store.user_fix_area || parseInt(store.ly_area);

        store.user_fix_area = customSearch(
          store.sim_area_array,
          store.user_fix_area
        );
        store.disable_user_fix_area = true;

        if (store.new_dept_flag === 0) {
          store.ly_area_int = parseInt(store.ly_area);
          store.ly_revenue_int = parseInt(store.ly_revenue);
          store.step_size =
            store.new_department_flag === 1 ||
            !store.final_elasticity_tag ||
            store.final_tagging === 'Low'
              ? null
              : (store?.step_size_array?.length && store.step_size_array[0]) ||
                store?.step_size;
          store.step_size_index = 0;
        } else {
          store.final_elasticity_tag = null;
          store.final_elasticity_tag_number = null;
          store.final_tagging = null;
          store.final_tagging_number = null;
        }
      });

      response.data.new_department.forEach((dept, index) => {
        dept.is_added = false;
        dept.new_dept_index = index;
        dept.step_size_array = dept.step_size_array || [];
        dept.user_fix_area = parseInt(dept.ly_area);
        dept.user_fix_area = customSearch(
          dept.sim_area_array,
          dept.user_fix_area
        );
        dept.disable_user_fix_area = false;
      });
      setConstraintData(response?.data?.data);
      setData(response?.data?.data);
      setNewDepartments(response?.data?.new_department);
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   refetchStoreDepartmentDetails();
  // }, []);

  // const {
  //   isLoading: isLoadingStoreDepartmentDetails,
  //   data: storeDepartmentDetails,
  //   isFetching: isFetchingStoreDepartmentDetails,
  //   refetch: refetchStoreDepartmentDetails,
  // } = getStoreConstraintsData({
  // season: {
  //   year: seasonValue?.year,
  //   name: seasonValue?.name,
  // },
  //   store_id: params?.id,
  // });

  const {
    isLoading: isLoadingConstraintFilters,
    data: constraintFilters,
    isFetching: isFetchingConstraintFilters,
    refetch: refetchConstraintFilters,
  } = getConstraintFilters({
    season: {
      year: season?.split(' ')[0],
      name: season?.split(' ')[1],
    },
    store_id: params?.id,
  });

  const { mutate: mutateConstraints, isLoading: isSavingConstraints } =
    saveStoreConstraints(onComplete);

  const { mutate: mutateOptimizer } = runOptimizer();

  useEffect(() => {
    if (seasonOptions?.length) {
      let season = seasonOptions.find((item) => item.selected);

      if (!season) {
        season = seasonOptions[0];
      }

      setSeasonValue(season);
    }
  }, [seasonOptions]);

  useEffect(() => {
    if (constraintFilters?.future_seasons) {
      setSeasonOptions(constraintFilters.future_seasons);
      setStoreDetails(constraintFilters.store_detail);
    }
  }, [constraintFilters]);

  useEffect(() => {
    if (seasonValue?.year) {
      refetchStoreDepartmentDetails();
    }
  }, [seasonValue]);

  useEffect(() => {
    refetchConstraintFilters();
  }, [searchParams]);

  useEffect(() => {
    setTimeout(() => {
      setIsOptimizerRunning(false);
    }, 2000);
  });

  const subRoutes = [
    {
      text: constraintFilters?.store_detail?.store_number
        ? ` View Store: ${
            constraintFilters?.store_detail?.store_number
          }-${constraintFilters?.store_detail?.store_name?.toUpperCase()}`
        : null,
      link: constraintFilters?.store_detail?.store_number
        ? `/store/${constraintFilters.store_detail.store_number}/?season=${season}`
        : null,
    },
    {
      text: constraintFilters?.store_detail?.store_number
        ? 'Constraints'
        : null,
    },
  ];

  const compareData = () => {
    try {
      return constraintData !== data;
    } catch (e) {
      return false;
    }
  };

  return (
    <>
      <BreadCrumb subRoutes={subRoutes} queryParams={season} />
      <div style={{ position: 'relative' }}>
        {isOptimizerRunning && <Loader />}
        <CustomCard cardStyles={classes.contentCard}>
          <StoreModelInfo
            istableDataChanged={compareData}
            seasonOptions={seasonOptions}
            season={seasonValue}
            lySeason={season}
            storeDetails={storeDetails}
            storeData={data}
            onSeasonSelect={onSeasonSelect}
            saveConstraints={saveConstraints}
            optimizeData={optimizeData}
            isLoadingFilters={
              isLoadingConstraintFilters ||
              isFetchingConstraintFilters ||
              isSavingConstraints
            }
            disabled={!data?.length || !compareData()}
            toolTipText={'Change constraints to enable save button'}
          />
        </CustomCard>
        <StoreConstraints
          storeDepartmentDetails={data}
          season={seasonValue}
          newDepartments={newDepartments}
          storeDetails={storeDetails}
          selectedColumns={selectedColumns}
          addNewDepartment={addNewDepartment}
          addDepartment={addDepartment}
          removeDepartment={removeDepartment}
          increaseValue={increaseValue}
          decreaseValue={decreaseValue}
          selectAll={selectAll}
          setFixedArea={setFixedArea}
          toggleEditable={toggleEditable}
          toggleFixableArea={toggleFixableArea}
          isLoadingTableData={loading || isSavingConstraints}
        />
      </div>
    </>
  );
};

export default ConstraintsPage;
