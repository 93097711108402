import {
  SET_CURRENT_USER,
  LOGOUT_CURRENT_USER,
  LOADER,
  // TENANT_FETCH_FAILED,
  // TENANT_FETCH_INIT,
  // TENANT_FETCH_SUCCESS,
  CHECK_USER_SESSION_INIT,
  ADD_SNACK,
} from './types';
// import axiosinstance from '../Utils/axios';
import firebaseobj from '../pages/Auth/firebase';
import Toast from 'components/Toast';
import api from 'services/api';
const fetchUserProviders = (userEmail) => {
  return firebaseobj.auth().fetchSignInMethodsForEmail(userEmail);
};

// Login - Post User Token
export const loginUser = (userData) => async (dispatch) => {
  dispatch({
    type: LOADER,
    payload: {
      status: true,
      text: 'Authenticating user...',
    },
  });
  const providers = await fetchUserProviders(userData.email);
  if (providers.length == 0) {
    Toast(
      'There is no user record corresponding to this identifier. The user may have been deleted',
      'error'
    );
    dispatch({
      type: ADD_SNACK,
      payload: {
        message:
          'There is no user record corresponding to this identifier. The user may have been deleted',
        options: {
          variant: 'error',
        },
      },
    });
    dispatch({
      type: LOADER,
      payload: {
        status: false,
        text: 'Loading...',
      },
    });
    return;
  }
  if (!providers.includes('password')) {
    Toast(`User Record is already associated with ${providers[0]}`, 'error');
    dispatch({
      type: ADD_SNACK,
      payload: {
        message: `User Record is already associated with ${providers[0]}`,
        options: {
          variant: 'error',
        },
      },
    });
    dispatch({
      type: LOADER,
      payload: {
        status: false,
        text: 'Loading...',
      },
    });
    return;
  }
  firebaseobj
    .auth()
    .signInWithEmailAndPassword(userData.email, userData.password)
    .catch((error) => {
      dispatch({
        type: LOADER,
        payload: {
          status: false,
          text: 'Loading...',
        },
      });
      Toast(error.message, 'error');

      dispatch({
        type: ADD_SNACK,
        payload: {
          message: error.message,
          options: {
            variant: 'error',
          },
        },
      });
    });
};

export const setUser = () => (dispatch) => {
  dispatch({
    type: SET_CURRENT_USER,
    payload: {
      isAuthenticated: true,
      isTokenVerified: true,
      user: {
        token: localStorage.getItem('token'),
        name: localStorage.getItem('name'),
      },
    },
  });
};
// Login - Post User Token
export const hidrateUser = () => (dispatch) => {
  if (localStorage.getItem('token') && localStorage.getItem('user')) {
    dispatch({
      type: SET_CURRENT_USER,
      payload: {
        token: localStorage.getItem('token'),
        user: JSON.parse(localStorage.getItem('user')),
      },
    });
  } else {
    dispatch({
      type: LOGOUT_CURRENT_USER,
    });
  }
};

export const checkUserProvider = (userEmail) => () => {
  return firebaseobj.auth().fetchSignInMethodsForEmail(userEmail);
};

// Once Backend is setup, call this api
export const refreshToken = async (user) => {
  return new Promise((resolve, reject) => {
    user.getIdToken().then((token) => {
      api({
        url: '/spacesmart/v1/user/verify-token/',
        method: 'POST',
        data: {
          token: token,
        },
        headers: {
          Authorization: token,
        },
      })
        .then((data) => {
          if (data.data.data.message == 'Token verified') {
            // localStorage.setItem('token', token);
            localStorage.setItem('name', user.email);

            localStorage.setItem(
              'session.expiry',
              user.toJSON().stsTokenManager.expirationTime
            );
            resolve([token, data]);
          } else {
            firebaseSignOut();
            reject();
          }
        })
        .catch(() => {
          firebaseSignOut();
          reject();
        });
    });
  });
};

// export const refreshToken = async (user) => {
//   return new Promise((resolve, reject) => {
//     if (user) {
//       const data = {
//         status: true,
//         status_code: 200,
//         data: { data: { message: 'Token verified', landing_page: '/' } },
//       };
//       // localStorage.setItem('token', token);
//       localStorage.setItem('name', user.email);

//       // localStorage.setItem(
//       //   'session.expiry',
//       //   user.toJSON().stsTokenManager.expirationTime
//       // );
//       resolve([user.token, data]);
//     } else {
//       firebaseSignOut();
//       reject();
//     }
//   });
// };
export const verifyTokenStatus = () => async (dispatch) => {
  dispatch({
    type: CHECK_USER_SESSION_INIT,
  });
  firebaseobj.auth().onAuthStateChanged((user) => {
    if (user) {
      dispatch({
        type: LOADER,
        payload: {
          status: true,
          text: 'Authenticating user...',
        },
      });
      refreshToken(user)
        .then((response) => {
          const token = response[0];
          const data = response[1];
          dispatch({
            type: SET_CURRENT_USER,
            payload: {
              isAuthenticated: true,
              isTokenVerified: true,
              user: {
                token: token,
                name: user.email,
              },
              landingPage: data.data.data.landing_page,
            },
          });

          dispatch({
            type: LOADER,
            payload: {
              status: false,
              text: 'Loading...',
            },
          });
        })
        .catch(() => {
          dispatch({
            type: SET_CURRENT_USER,
            payload: {
              isAuthenticated: false,
              isTokenVerified: true,
              user: {},
            },
          });
          Toast(`Unable to verify the token`, 'error');

          dispatch({
            type: ADD_SNACK,
            payload: {
              message: `Unable to verify the token`,
              options: {
                variant: 'error',
              },
            },
          });
          dispatch({
            type: LOADER,
            payload: {
              status: false,
              text: 'Loading...',
            },
          });
        });
    } else {
      dispatch({
        type: SET_CURRENT_USER,
        payload: {
          isAuthenticated: false,
          isTokenVerified: true,
          user: {},
        },
      });
    }
  });
};

const firebaseSignOut = () => {
  return firebaseobj.auth().signOut();
};
export const logoutUser = (token) => (dispatch) => {
  firebaseSignOut()
    .then(() => {
      localStorage.removeItem('token', token);
      localStorage.removeItem('session.expiry');
    })
    .catch(() => {
      return;
      // An error happened.
    });
  dispatch({
    type: LOGOUT_CURRENT_USER,
  });
  dispatch({
    type: LOADER,
    payload: {
      status: false,
    },
  });
};

// export const getTenantId = (hostname) => (dispatch) => {
//   //Logic to fetch tenantID from backend using axios
//   dispatch({
//     type: TENANT_FETCH_INIT,
//   });
//   return axiosinstance
//     .get(`${FETCH_TENANT}?domain=${hostname}`)
//     .then((data) => {
//       dispatch({
//         type: TENANT_FETCH_SUCCESS,
//         payload: data.data.data.results[0],
//       });
//     })
//     .catch(() => {
//       //Handling the error
//       dispatch({
//         type: TENANT_FETCH_FAILED,
//       });
//     });
// };

export const forgotPassword = (emailAddress) => (dispatch) => {
  firebaseobj
    .auth()
    .sendPasswordResetEmail(emailAddress)
    .then(function () {
      // Email sent.
      Toast(
        `Password reset link sent to the registered email successfully!`,
        'error'
      );

      dispatch({
        type: ADD_SNACK,
        payload: {
          message: `Password reset link sent to the registered email successfully!`,
          options: {
            variant: 'success',
          },
        },
      });
    })
    .catch(function (error) {
      // An error happened.
      Toast(error.message, 'error');
      dispatch({
        type: ADD_SNACK,
        payload: {
          message: error.message,
          options: {
            variant: 'success',
          },
        },
      });
    });
};
