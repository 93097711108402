import React from 'react';
import { makeStyles } from '@mui/styles';
import { IconButton, Tooltip } from '@mui/material';

import DeleteIcon from '../../../assets/svg/delete.svg';
import AddIcon from '../../../assets/svg/add_filled.svg';
const styles = (theme) => ({
  actionContainer: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignCenter,
  },
  actionIcon: {
    cursor: theme.content.cursor.pointer,
    transition: 'all 0.3s',
    '&:active': {
      transform: 'scale(0.8)',
    },
  },
});

const useStyles = makeStyles(styles);
const TableAction = ({ index, isDeleted, addDepartment, removeDepartment }) => {
  const classes = useStyles();

  const onAddDepartment = () => {
    addDepartment(index);
  };

  const onRemoveDepartment = () => {
    removeDepartment(index);
  };

  return (
    <div className={classes.actionContainer}>
      {isDeleted ? (
        <Tooltip arrow title={`Add Back Department`}>
          <IconButton
            disableRipple
            onClick={() => onAddDepartment()}
            className={classes.actionIcon}
          >
            <img src={AddIcon} alt={'list'} />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip arrow title={`Delete Department`}>
          <IconButton
            disableRipple
            onClick={() => onRemoveDepartment()}
            className={classes.actionIcon}
          >
            <img src={DeleteIcon} alt={'list'} />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};

export default TableAction;
