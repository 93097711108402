import React from 'react';
import { Grid } from '@mui/material';
import MultiSelect from 'components/MultiSelect';
import SingleSelect from 'components/SingleSelect';

const StoreFilters = ({
  isoptimizer,
  metrics,
  compareAgainst,
  season,
  deltaChangeValue,
  metricsOptions,
  deltaOptions,
  compareOptions,
  seasonOptions,
  onMetricsSelect,
  handleDeltaChange,
  onCompareSelect,
  onSeasonSelect,
}) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={6} lg={isoptimizer ? 3 : 4}>
        <MultiSelect
          label={'Metrics'}
          placeholder={'Select Metrics'}
          selectedValues={metrics}
          options={metricsOptions}
          limitTags={1}
          width={156}
          maxHeight={36}
          onSelect={onMetricsSelect}
        />
      </Grid>
      <Grid item xs={6} lg={isoptimizer ? 3 : 4}>
        <SingleSelect
          label={'Compare Against'}
          placeholder={'Select Season'}
          selectedValue={compareAgainst}
          options={compareOptions}
          optionLabelKey={'label'}
          width={156}
          maxHeight={36}
          onSelect={onCompareSelect}
        />
      </Grid>
      {isoptimizer && (
        <Grid item xs={6} lg={isoptimizer ? 3 : 4}>
          <SingleSelect
            label={'Delta Change From'}
            placeholder={'Select Delta Change Option'}
            selectedValue={deltaChangeValue}
            options={deltaOptions}
            optionLabelKey={'label'}
            width={156}
            maxHeight={36}
            textTransform='capitalize'
            onSelect={handleDeltaChange}
          />
        </Grid>
      )}
      <Grid item xs={6} lg={isoptimizer ? 3 : 4}>
        <SingleSelect
          label={'Season'}
          placeholder={'Select Season'}
          selectedValue={season}
          options={seasonOptions}
          optionLabelKey={'label'}
          width={156}
          maxHeight={36}
          textTransform='capitalize'
          onSelect={onSeasonSelect}
        />
      </Grid>
    </Grid>
  );
};

export default StoreFilters;
