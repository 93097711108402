import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import CustomCard from 'components/CustomCard';
import StoreModelInfo from './StoreModelInfo';
import StoreDetailsFilters from './StoreDetailsFilters';
import BreadCrumb from 'components/BreadCrumb';
import OptimisedModelInfo from './OptimizerModelInfo';
import { FLOORS_STRING } from 'config/constants';
import { calculateDiff, sumArrOfObjects } from 'utils';

const styles = (theme) => ({
  loader: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignCenter,
    height: 492,
    width: '100%',
    color: theme.palette.textPrimary,
  },
  contentCard: {
    ...theme.content.card,
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignBetweenCenter,
    width: '100%',
    margin: '16px 0 32px 0',
    padding: '24px 32px',
  },
  filterActions: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignCenter,
    flexWrap: theme.content.flexStyles.flexWrap.wrap,
  },
});

const useStyles = makeStyles(styles);

const staticCompare = [
  { label: 'LLY', name: 'LLY', value: 'lly' },
  { label: 'Region Average', name: 'Region Average', value: 'region_average' },
  {
    label: 'Category Average',
    name: 'Category Average',
    value: 'category_average',
  },
  { label: 'Sister Store', name: 'Sister Store', value: 'sister_store' },
  {
    label: 'Tier Cluster',
    name: 'Tier Cluster',
    value: 'assortment_tier',
  },
  {
    label: 'Prototype Store',
    name: 'Prototype Store',
    value: 'prototype_store',
  },
];

const StoreDetailContainer = ({
  getStoreData,
  getStoreDeptFilters,
  headCells,
  isoptimizer,
  exportEndpoint,
}) => {
  const classes = useStyles();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const season = isoptimizer
    ? searchParams.get('optimized_season')
    : searchParams.get('season');
  const lySeason = searchParams.get('ly_season');

  const [compareAgainst, setCompareAgainst] = useState({});
  const [metricOptions, setMetricOptions] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [deltaChangeOptions, setDeltaChangeOptions] = useState([
    {
      label: 'LY',
      value: 'ly',
    },
  ]);
  const [deltaChangeValue, setDeltaChangeValue] = useState({
    label: 'LY',
    value: 'ly',
  });

  const {
    isLoading: isLoadingStoreDepartmentDetails,
    data: storeDepartmentDetails,
    isFetching: isFetchingStoreDepartmentDetails,
    refetch: refetchStoreDepartmentDetails,
  } = getStoreData({
    season: {
      year: season?.split(' ')[0],
      name: season?.split(' ')[1],
    },
    compareAgainst,
    store_id: params?.id,
    level_flag: params?.type,
  });

  const {
    isLoading: isLoadingStoreDeptFilters,
    data: storeDeptFilters = [],
    isFetching: isFetchingStoreDeptFilters,
    refetch: refetchStoreDeptFilters,
  } = getStoreDeptFilters({
    season: {
      year: season?.split(' ')[0],
      name: season?.split(' ')[1],
    },
    store_id: params?.id,
  });

  const handleDeltaChange = (_, value) => {
    setDeltaChangeValue(value);
  };

  const handleSubtotal = (data, isLy) => {
    let fixedData = [];
    const optimizedData = [];

    let countOfFixedColums = 0;
    let countOfOptimizedColumns = 0;

    // Segregating Fixed and Optimized Data
    for (let elem of data) {
      if (elem.fix_flag) {
        if (elem.new_dept_flag === 0) {
          countOfFixedColums++;
        }
        fixedData.push(elem);
      } else {
        if (elem.new_dept_flag === 0) {
          countOfOptimizedColumns++;
        }
        optimizedData.push(elem);
      }
    }

    const fixedSum = sumArrOfObjects(fixedData);
    fixedSum.floor_plan_area = 'Agg. Fixed Departments';
    fixedSum.floor_str = 'Agg. Fixed Departments';
    fixedSum.floor = null;
    fixedSum.department = null;
    fixedSum.tier = null;
    fixedSum.cluster = null;
    fixedSum.dept_index = null;
    fixedSum.elastic_confidence_number = null;
    fixedSum.elasticity = null;
    fixedSum.elasticity_confidence = null;
    fixedSum.fix_flag = null;
    fixedSum.new_dept_flag = null;
    fixedSum.rev_area_index = null;
    fixedSum.space_elasticity_tag_number = null;
    fixedSum.store_dept_index = null;
    fixedSum.isTotal = true;
    fixedSum.fixed = true;
    if (Number(fixedSum?.optimized_revenue_persqft)) {
      fixedSum.optimized_revenue_persqft = parseFloat(
        (fixedSum.optimized_revenue_persqft / countOfFixedColums).toFixed(2)
      );
    }
    if (Number(fixedSum?.optimized_margin_persqft)) {
      fixedSum.optimized_margin_persqft = parseFloat(
        (fixedSum.optimized_margin_persqft / countOfFixedColums).toFixed(2)
      );
    }
    if (Number(fixedSum?.ly_revenue_persqft)) {
      fixedSum.ly_revenue_persqft = parseFloat(
        (fixedSum.ly_revenue_persqft / countOfFixedColums).toFixed(2)
      );
    }
    if (Number(fixedSum?.ly_margin_persqft)) {
      fixedSum.ly_margin_persqft = parseFloat(
        (fixedSum.ly_margin_persqft / countOfFixedColums).toFixed(2)
      );
    }
    if (Number(fixedSum?.compare_margin_persqft)) {
      fixedSum.compare_margin_persqft = parseFloat(
        (fixedSum.compare_margin_persqft / countOfFixedColums).toFixed(2)
      );
    }
    if (Number(fixedSum?.compare_margin_persqft)) {
      fixedSum.compare_margin_persqft = parseFloat(
        (fixedSum.compare_margin_persqft / countOfFixedColums).toFixed(2)
      );
    }

    const optimizedSum = sumArrOfObjects(optimizedData);
    optimizedSum.floor_plan_area = 'Agg. Optimized Departments';
    optimizedSum.floor_str = 'Agg. Optimized Departments';
    optimizedSum.floor = null;
    optimizedSum.department = null;
    optimizedSum.tier = null;
    optimizedSum.cluster = null;
    optimizedSum.dept_index = null;
    optimizedSum.elastic_confidence_number = null;
    optimizedSum.elasticity = null;
    optimizedSum.elasticity_confidence = null;
    optimizedSum.fix_flag = null;
    optimizedSum.new_dept_flag = null;
    optimizedSum.rev_area_index = null;
    optimizedSum.space_elasticity_tag_number = null;
    optimizedSum.store_dept_index = null;
    optimizedSum.isTotal = true;
    optimizedSum.fixed = false;
    if (Number(optimizedSum?.optimized_revenue_persqft)) {
      optimizedSum.optimized_revenue_persqft = parseFloat(
        (
          optimizedSum.optimized_revenue_persqft / countOfOptimizedColumns
        ).toFixed(2)
      );
    }
    if (Number(optimizedSum?.optimized_margin_persqft)) {
      optimizedSum.optimized_margin_persqft = parseFloat(
        (
          optimizedSum.optimized_margin_persqft / countOfOptimizedColumns
        ).toFixed(2)
      );
    }
    if (Number(optimizedSum?.ly_revenue_persqft)) {
      optimizedSum.ly_revenue_persqft = parseFloat(
        (optimizedSum.ly_revenue_persqft / countOfOptimizedColumns).toFixed(2)
      );
    }
    if (Number(optimizedSum?.ly_margin_persqft)) {
      optimizedSum.ly_margin_persqft = parseFloat(
        (optimizedSum.ly_margin_persqft / countOfOptimizedColumns).toFixed(2)
      );
    }
    if (Number(optimizedSum?.compare_margin_persqft)) {
      optimizedSum.compare_margin_persqft = parseFloat(
        (optimizedSum.compare_margin_persqft / countOfOptimizedColumns).toFixed(
          2
        )
      );
    }
    if (Number(optimizedSum?.compare_margin_persqft)) {
      optimizedSum.compare_margin_persqft = parseFloat(
        (optimizedSum.compare_margin_persqft / countOfOptimizedColumns).toFixed(
          2
        )
      );
    }

    if (isLy) {
      fixedSum.delta_total_area = calculateDiff(
        fixedSum.optimized_area,
        fixedSum.ly_actual_total_area
      );
      fixedSum.delta_revenue = calculateDiff(
        fixedSum.optimized_revenue,
        fixedSum.ly_actual_revenue
      );
      fixedSum.delta_revenue_persqft = calculateDiff(
        fixedSum.optimized_revenue_persqft,
        fixedSum.ly_revenue_persqft
      );
      fixedSum.delta_margin = calculateDiff(
        fixedSum.optimized_margin,
        fixedSum.ly_actual_margin
      );
      fixedSum.delta_margin_persqft = calculateDiff(
        fixedSum.optimized_margin_persqft,
        fixedSum.ly_margin_persqft
      );

      optimizedSum.delta_total_area = calculateDiff(
        optimizedSum.optimized_area,
        optimizedSum.ly_actual_total_area
      );
      optimizedSum.delta_revenue = calculateDiff(
        optimizedSum.optimized_revenue,
        optimizedSum.ly_actual_revenue
      );
      optimizedSum.delta_revenue_persqft = calculateDiff(
        optimizedSum.optimized_revenue_persqft,
        optimizedSum.ly_revenue_persqft
      );
      optimizedSum.delta_margin = calculateDiff(
        optimizedSum.optimized_margin,
        optimizedSum.ly_actual_margin
      );
      optimizedSum.delta_margin_persqft = calculateDiff(
        optimizedSum.optimized_margin_persqft,
        optimizedSum.ly_margin_persqft
      );
    } else {
      fixedSum.delta_total_area = calculateDiff(
        fixedSum.optimized_area,
        fixedSum.compare_actual_total_area
      );
      fixedSum.delta_revenue = calculateDiff(
        fixedSum.optimized_revenue,
        fixedSum.compare_actual_revenue
      );
      fixedSum.delta_revenue_persqft = calculateDiff(
        fixedSum.optimized_revenue_persqft,
        fixedSum.compare_revenue_persqft
      );
      fixedSum.delta_margin = calculateDiff(
        fixedSum.optimized_margin,
        fixedSum.compare_actual_margin
      );
      fixedSum.delta_margin_persqft = calculateDiff(
        fixedSum.optimized_margin_persqft,
        fixedSum.compare_margin_persqft
      );

      optimizedSum.delta_total_area = calculateDiff(
        optimizedSum.optimized_area,
        optimizedSum.compare_actual_total_area
      );
      optimizedSum.delta_revenue = calculateDiff(
        optimizedSum.optimized_revenue,
        optimizedSum.compare_actual_revenue
      );
      optimizedSum.delta_revenue_persqft = calculateDiff(
        optimizedSum.optimized_revenue_persqft,
        optimizedSum.compare_revenue_persqft
      );
      optimizedSum.delta_margin = calculateDiff(
        optimizedSum.optimized_margin,
        optimizedSum.compare_actual_margin
      );
      optimizedSum.delta_margin_persqft = calculateDiff(
        optimizedSum.optimized_margin_persqft,
        optimizedSum.compare_margin_persqft
      );
    }

    setTableData([
      ...optimizedData,
      { ...optimizedSum },
      ...fixedData,
      { ...fixedSum },
    ]);
  };

  const setDataWithDiffAgainstLy = (storeDepartmentDetails) => {
    const newData = storeDepartmentDetails.filter(({ isTotal }) => !isTotal);
    const data = newData.map((store) => {
      const newStore = { ...store };
      newStore.floor_str = FLOORS_STRING[store.floor];
      newStore.optimized_revenue_persqft = parseInt(
        store.optimized_revenue_persqft
      );
      newStore.optimized_margin_persqft = parseInt(
        store.optimized_margin_persqft
      );

      newStore.compare_revenue_persqft = parseInt(
        store.compare_revenue_persqft
      );
      newStore.compare_margin_persqft = parseInt(store.compare_margin_persqft);
      newStore.assortmentNum = Number(store.tier + store.cluster);

      newStore.delta_total_area = calculateDiff(
        store.optimized_area,
        store.ly_actual_total_area
      );
      newStore.delta_revenue = calculateDiff(
        store.optimized_revenue,
        store.ly_actual_revenue
      );
      newStore.delta_revenue_persqft = calculateDiff(
        store.optimized_revenue_persqft,
        store.ly_revenue_persqft
      );
      newStore.delta_margin = calculateDiff(
        store.optimized_margin,
        store.ly_actual_margin
      );
      newStore.delta_margin_persqft = calculateDiff(
        store.optimized_margin_persqft,
        store.ly_margin_persqft
      );
      return newStore;
    });
    handleSubtotal(data, true);
  };

  const setDataWithDiffAgainstCompareMetric = (storeDepartmentDetails) => {
    const newData = storeDepartmentDetails.filter(({ isTotal }) => !isTotal);

    const data = newData.map((store) => {
      const newStore = { ...store };
      newStore.floor_str = FLOORS_STRING[store.floor];
      newStore.optimized_revenue_persqft = parseInt(
        store.optimized_revenue_persqft
      );
      newStore.optimized_margin_persqft = parseInt(
        store.optimized_margin_persqft
      );
      newStore.compare_revenue_persqft = parseInt(
        store.compare_revenue_persqft
      );
      newStore.compare_margin_persqft = parseInt(store.compare_margin_persqft);
      newStore.assortmentNum = Number(store.tier + store.cluster);

      newStore.delta_total_area = calculateDiff(
        store.optimized_area,
        store.compare_actual_total_area
      );
      newStore.delta_revenue = calculateDiff(
        store.optimized_revenue,
        store.compare_actual_revenue
      );
      newStore.delta_revenue_persqft = calculateDiff(
        store.optimized_revenue_persqft,
        store.compare_revenue_persqft
      );
      newStore.delta_margin = calculateDiff(
        store.optimized_margin,
        store.compare_actual_margin
      );
      newStore.delta_margin_persqft = calculateDiff(
        store.optimized_margin_persqft,
        store.compare_margin_persqft
      );
      return newStore;
    });
    handleSubtotal(data, false);
  };

  useEffect(() => {
    if (storeDepartmentDetails?.length > 0) {
      if (compareAgainst?.name || compareAgainst?.store_number) {
        setDataWithDiffAgainstCompareMetric(storeDepartmentDetails);
      } else {
        setDataWithDiffAgainstLy(storeDepartmentDetails);
      }
    }
  }, [storeDepartmentDetails]);

  useEffect(() => {
    if (params?.id) {
      refetchStoreDeptFilters();
    }
  }, [params, searchParams]);

  useEffect(() => {
    if (params?.id) {
      refetchStoreDepartmentDetails();
    }
  }, [params, searchParams, compareAgainst]);

  useEffect(() => {
    if (storeDeptFilters?.metric?.length) {
      setMetricOptions(storeDeptFilters?.metric);
    }
  }, [storeDeptFilters?.metric?.length]);

  useEffect(() => {
    if (compareAgainst?.name || compareAgainst?.store_number) {
      const deltaOptions = [
        {
          label: 'LY',
          value: 'ly',
        },
      ];
      const option = {
        label: '',
        value: '',
      };
      if (compareAgainst?.store_number) {
        option.label = 'Another Store';
        option.value = 'another_store';
      } else {
        option.label = compareAgainst?.name.replace('_', ' ');
        option.value = compareAgainst?.name;

        if (option.value === 'lly') {
          option.label = option.label.toLocaleUpperCase();
        }

        if (option.value === 'assortment_tier') {
          option.label = 'tier cluster';
        }
      }
      // deltaOptions[1]= option
      deltaOptions.push(option);
      // deltaOptions.push();
      setDeltaChangeValue(option);
      setDeltaChangeOptions([...deltaOptions]);
    } else {
      const options = [];
      const option = {
        label: 'LY',
        value: 'ly',
      };

      options.push(option);

      setDeltaChangeValue(option);
      setDeltaChangeOptions([...options]);
    }
  }, [compareAgainst]);

  useEffect(() => {
    if (deltaChangeValue?.value && tableData?.length > 0) {
      if (deltaChangeValue?.value === 'ly') {
        setDataWithDiffAgainstLy(tableData);
      } else {
        setDataWithDiffAgainstCompareMetric(tableData);
      }
    }
  }, [deltaChangeValue]);

  const currSeason = isoptimizer ? lySeason : season;

  const subRoutes = [
    {
      text: storeDeptFilters?.store_detail?.store_number
        ? ` View Store: ${
            storeDeptFilters?.store_detail?.store_number
          }-${storeDeptFilters?.store_detail?.store_name.toUpperCase()}`
        : null,
      link: storeDeptFilters?.store_detail?.store_number
        ? `/store/${storeDeptFilters?.store_detail?.store_number}/?season=${
            currSeason?.split(' ')[0]
          } ${currSeason?.split(' ')[1]}`
        : null,
    },
    {
      text: storeDeptFilters?.store_detail?.store_number ? `Constraint` : null,
      link: storeDeptFilters?.store_detail?.store_number
        ? `/constraints/${
            storeDeptFilters?.store_detail.store_number
          }/?ly_season=${currSeason?.split(' ')[0]} ${
            currSeason?.split(' ')[1]
          }`
        : null,
    },
    {
      text: storeDeptFilters?.store_detail?.store_number
        ? 'Optimized Results'
        : null,
    },
  ];

  return (
    <>
      <BreadCrumb
        subRoutes={subRoutes}
        queryParams={isoptimizer ? lySeason : season}
      />
      {!isoptimizer && (
        <CustomCard cardStyles={classes.contentCard}>
          <StoreModelInfo
            storeDetails={storeDeptFilters?.store_detail || null}
            isoptimizer={isoptimizer}
            levelFlag={params?.type}
          />
        </CustomCard>
      )}
      {isoptimizer && (
        <CustomCard cardStyles={classes.contentCard}>
          <OptimisedModelInfo
            storeDetails={storeDeptFilters?.store_detail || null}
            isoptimizer={isoptimizer}
            levelFlag={params?.type}
          />
        </CustomCard>
      )}
      <StoreDetailsFilters
        isoptimizer={isoptimizer}
        headCells={headCells}
        metricsOptions={metricOptions}
        seasonOptions={storeDeptFilters?.season || []}
        compareOptions={[
          ...staticCompare,
          {
            name: `Store Id - ${compareAgainst?.store_number}`,
            value: 'another_store',
            label: 'Another Store',
          },
        ]}
        storeDetails={storeDeptFilters?.store_detail}
        storeDepartmentDetails={tableData}
        setCompareAgaintFilter={setCompareAgainst}
        isLoading={isLoadingStoreDeptFilters || isFetchingStoreDeptFilters}
        isLoadingTableData={
          isLoadingStoreDepartmentDetails || isFetchingStoreDepartmentDetails
        }
        compareAgainstFilter={compareAgainst}
        deltaChangeOptions={deltaChangeOptions}
        deltaChangeValue={deltaChangeValue}
        handleDeltaChange={handleDeltaChange}
        exportEndpoint={exportEndpoint}
      />
    </>
  );
};

export default StoreDetailContainer;
