import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { makeStyles } from '@mui/styles';
import CustomText from '../CustomText';

const styles = (theme) => ({
  fieldLabel: {
    ...theme.typography.fontSizes.normalText,
    marginBottom: '4px',
    color: theme.palette.textSecondary,
    fontWeight: theme.typography.fontWeight.medium,
  },
  form: {
    margin: '8px 0',
    '& .MuiOutlinedInput-notchedOutline': {
      border: `0.5px solid ${theme.palette.stroke} !important`,
      borderRadius: '2px',
    },
  },
  inputFieldRoot: {
    paddingRight: '8px',
    background: '#fff',
  },
  inputField: {
    ...theme.typography.fontSizes.normalText,
    padding: `6.5px 0px 6.5px 14px`,
    boxSizing: theme.content.boxSizing.borderBox,
    height: '2.25rem',
    color: theme.palette.textDark,

    '&::placeholder': {
      opacity: 1,
    },
  },
  searchIcon: {
    color: theme.palette.stroke,
  },
});

const useStyles = makeStyles(styles);

const SimpleSearch = (props) => {
  const { label, text, placeholder, width, maxHeight, onChange, showIcon } =
    props;
  const classes = useStyles();

  return (
    <div>
      <CustomText
        content={label}
        classes={classes.fieldLabel}
        paragraph={false}
        variant={'body1'}
      />
      <FormControl
        sx={{ m: 1, width, maxHeight }}
        size='small'
        className={classes.form}
      >
        <OutlinedInput
          id='outlined-adornment-password'
          sx={{ height: 24 }}
          type={'text'}
          value={text}
          placeholder={placeholder}
          onChange={onChange}
          classes={{
            root: classes.inputFieldRoot,
            input: classes.inputField,
          }}
          endAdornment={
            showIcon ? (
              <InputAdornment position='end'>
                <SearchIcon classes={{ root: classes.searchIcon }} />
              </InputAdornment>
            ) : null
          }
        />
      </FormControl>
    </div>
  );
};

SimpleSearch.propTypes = {
  label: PropTypes.string,
  text: PropTypes.string,
  placeholder: PropTypes.string,
  width: PropTypes.number,
  maxHeight: PropTypes.number,
  onChange: PropTypes.func,
  showIcon: PropTypes.bool,
};

export default SimpleSearch;
