import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { IconButton } from '@mui/material';
import CustomDialog from 'components/CustomDialog';
import CustomText from 'components/CustomText';
import { FLOORS_STRING, helperTexts } from '../../../config/constants';
import SingleSelect from 'components/SingleSelect';
import CustomButton from 'components/CustomButton';
import EditIcon from '../../../assets/images/edit.png';
import { customSearch } from 'utils';
import Helper from 'components/Helper';

const styles = (theme) => ({
  optimizeText: {
    ...theme.typography.fontSizes.linkText,
    color: theme.palette.textDark,
    margin: '16px 0',
  },
  departmentContainer: {
    margin: '16px 0',
    position: theme.content.positions.relative,
  },
  departmentAction: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignCenter,
    width: '100%',
    bottom: '-80px',
    position: theme.content.positions.absolute,
  },
  fieldLabel: {
    ...theme.typography.fontSizes.normalText,
    marginBottom: '4px',
    color: theme.palette.textSecondary,
    fontWeight: theme.typography.fontWeight.medium,
  },
  inputRoot: {
    ...theme.typography.fontSizes.normalText,
    color: theme.palette.textDark,
    width: '100%',
    height: '2.25rem',
    padding: '0 14px !important',
    borderRadius: 2,
    background: theme.palette.textWhite,

    '& .MuiOutlinedInput-notchedOutline': {
      border: `0.5px solid ${theme.palette.stroke} !important`,
      borderRadius: '2px',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      WebkitAppearance: 'none !important',
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      WebkitAppearance: 'none !important',
    },
    '& input[type=number]': {
      MozAppearance: 'textfield',
    },
  },
  inputField: {
    ...theme.typography.fontSizes.normalText,
    padding: `0 !important`,
    height: '2.15rem',
    boxSizing: theme.content.boxSizing.borderBox,
    textAlign: theme.typography.textAlign.right,
    color: theme.palette.textDark,
    '&::placeholder': {
      opacity: 1,
    },
  },
  rangeIcon: {
    cursor: theme.content.cursor.pointer,
    transition: 'all 0.3s',
    '&:active': {
      transform: 'scale(0.8)',
    },
  },
  editIcon: {
    height: 16,
    marginBottom: 4,
    marginRight: 8,
    cursor: theme.content.cursor.pointer,
    transition: 'all 0.3s',
    '&:active': {
      transform: 'scale(0.8)',
    },
  },
  rangeIconDisabled: {
    opacity: 0.4,
    pointerEvents: 'inherit !important',
    cursor: `${theme.content.cursor.notAllowed} !important`,
  },
});

const useStyles = makeStyles(styles);

const AddDepartment = ({
  floors,
  departments,
  addNewDepartment,
  handleOpen,
  handleClose,
}) => {
  const classes = useStyles();
  const [isDisabled, setIsDisabled] = useState(true);
  const [disableSelectingFloors, setSelectingFloors] = useState(false);
  const [floorOptions, setFloorOptions] = useState([]);
  const [selectedFloor, setSelectedFloor] = useState(null);
  const [selectedDept, setSelectedDept] = useState(null);
  const [userFixedArea, setUserFixedArea] = useState(0);

  const setFixedArea = (value) => {
    const dept = { ...selectedDept };
    dept.user_fix_area = customSearch(dept?.sim_area_array, value);

    setUserFixedArea(dept?.user_fix_area);
    setIsDisabled(true);
    setSelectedDept(dept);
  };

  const onFloorSelect = (_, value) => {
    setSelectedFloor(value);
  };

  const onDeptSelect = (_, value) => {
    setSelectedDept(value);

    const userFixedArea = customSearch(value?.sim_area_array, value?.ly_area);
    setUserFixedArea(userFixedArea);
  };

  const onKeyPress = (e) => {
    if (e?.keyCode == 13) {
      setFixedArea(e.target.value);
    }
  };

  const toggleHandler = () => {
    setIsDisabled(!isDisabled);
  };

  const handleAddDepartment = () => {
    if (selectedFloor?.value && selectedDept?.department) {
      addNewDepartment(selectedFloor, selectedDept);
      handleClose();
    }
  };

  useEffect(() => {
    if (floorOptions?.length) {
      const firstFloor = floorOptions.find((floor) => floor.value === 1);

      if (floorOptions?.length === 1) {
        setSelectingFloors(true);
      }
      setSelectedFloor(firstFloor);
    }
  }, [floorOptions?.length]);

  useEffect(() => {
    if (floors) {
      let floorOptions = [...Array(floors)]?.map((_, index) => {
        const floor = {
          label: FLOORS_STRING[index + 1],
          value: index + 1,
        };

        return floor;
      });

      floorOptions = [...floorOptions];
      setFloorOptions(floorOptions);
    }
  }, [floors]);

  return (
    <CustomDialog
      open
      handleOpen={handleOpen}
      handleClose={handleClose}
      isTransparent={false}
    >
      <div style={{ width: 280, minHeight: 348, padding: '0 12px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <CustomText
            content={'Add New Department'}
            variant='body1'
            classes={classes.optimizeText}
          />
          <div className={classes.helper}>
            <Helper title={helperTexts.add.text} link={helperTexts.add.link} />
          </div>
        </div>
        <div className={classes.departmentContainer}>
          <div style={{ marginTop: 8 }}>
            <SingleSelect
              label={'Floor'}
              placeholder={'Select Season'}
              selectedValue={selectedFloor}
              options={floorOptions}
              optionLabelKey={'label'}
              isDisabled={disableSelectingFloors}
              width={256}
              maxHeight={36}
              textTransform='capitalize'
              onSelect={onFloorSelect}
            />
          </div>

          <div style={{ marginTop: 8, marginBottom: !selectedDept ? 152 : 0 }}>
            <SingleSelect
              label={'Deptartment'}
              placeholder={'Select Deptartment'}
              selectedValue={selectedDept}
              options={departments}
              optionLabelKey={'department'}
              width={256}
              maxHeight={36}
              popperHeight={136}
              textTransform='capitalize'
              onSelect={onDeptSelect}
            />
          </div>

          <div>
            {selectedDept?.department && (
              <div style={{ marginTop: 8, marginBottom: 8 }}>
                <CustomText
                  content={'Area'}
                  classes={classes.fieldLabel}
                  paragraph={false}
                  variant={'body1'}
                />
                <TextField
                  value={userFixedArea}
                  type={'number'}
                  autoComplete='off'
                  autoFocus
                  disabled={isDisabled}
                  onBlur={(e) => setFixedArea(e.target.value)}
                  onChange={(e) => setUserFixedArea(e.target.value)}
                  onKeyDown={(e) => onKeyPress(e)}
                  onClick={() => isDisabled && toggleHandler()}
                  inputRef={(input) => input && input.focus()}
                  style={{ width: '100%' }}
                  InputProps={{
                    startAdornment: isDisabled && (
                      <InputAdornment position='start'>
                        <IconButton
                          disableRipple
                          classes={{
                            disabled: classes.rangeIconDisabled,
                          }}
                          onClick={() => {
                            toggleHandler();
                          }}
                        >
                          <img
                            src={EditIcon}
                            alt='edit'
                            className={classes.editIcon}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                    classes: {
                      root: classes.inputRoot,
                      input: classes.inputField,
                    },
                  }}
                />
              </div>
            )}
          </div>

          {selectedFloor?.value && selectedDept?.department && (
            <div className={classes.departmentAction}>
              <CustomButton
                isPrimary={true}
                variant='contained'
                height={32}
                width={160}
                label='Add Department'
                onButtonClick={() => handleAddDepartment()}
              />
            </div>
          )}
        </div>
      </div>
    </CustomDialog>
  );
};

export default AddDepartment;
