import React from 'react';
import { makeStyles } from '@mui/styles';
import { CircularProgress } from '@mui/material';

const styles = (theme) => ({
  container: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignCenter,
    height: '100%',
    width: '100%',
  },
  icon: {
    color: theme.content.textPrimary,
  },
});

const useStyles = makeStyles(styles);

const Loader = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <CircularProgress classes={{ root: classes.icon }} size={32} />
    </div>
  );
};

export default Loader;
