export const GET_ERRORS = 'GET_ERRORS';
export const ERROR_MSG = 'ERROR_MSG';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const DATA_LOADING = 'DATA_LOADING';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const LOGOUT_CURRENT_USER = 'LOGOUT_CURRENT_USER';
export const SET_TENANT_ID = 'SET_TENANT_ID';
export const LOADER = 'LOADER';

// export const SHOW_ERROR = 'SHOW_ERROR'

//Layout
export const LAYOUT_FETCHED = 'LAYOUT_FETCHED';
export const SET_ROUTES = 'SET_ROUTES';
export const SET_HEADER_MENU = 'SET_HEADER_MENU';
export const SET_SIDEBAR_MENU = 'SET_SIDEBAR_MENU';
//Tenant Settings
export const SET_TENANT_APPS = 'SET_TENANT_APPS';
export const SET_APP_SETTINGS = 'SET_APP_SETTINGS';

//Tenant Info
export const TENANT_FETCH_INIT = 'TENANT_FETCH_INIT';
export const TENANT_FETCH_SUCCESS = 'TENANT_FETCH_SUCCESS';
export const TENANT_FETCH_FAILED = 'TENANT_FETCH_FAILED';

//User Session Info
export const CHECK_USER_SESSION_INIT = 'CHECK_USER_SESSION_INFO_INIT';

//EmailLogin
export const EMAIL_LOGIN_FAILED = 'EMAIL_LOGIN_FAILED';

//Snackbar
export const ADD_SNACK = 'ADD_SNACK';
export const REMOVE_SNACK = 'REMOVE_SNACK';
export const CLOSE_SNACK = 'CLOSE_SNACK';
