import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { getComparator, stableSort, calculateStickyLeft } from '../../utils';

import CustomDialog from 'components/CustomDialog';
import Chart from 'components/Chart';
import { styles } from './styles';

import EmptyIcon from '../../assets/images/empty.png';
import TableBodyCell from './TableBodyCell';

const useStyles = makeStyles(styles);

function EnhancedTableBody({
  order,
  orderBy,
  page,
  rowsPerPage,
  rows,
  headCells,
  tableAction,
  urlPrefix,
  queryParams,
  isGroupedData,
  onIncrease,
  onDecrease,
  setFixedArea,
  toggleEditable,
  toggleCheckbox,
}) {
  const classes = useStyles();
  const [openChart, setOpenChart] = useState(false);
  const [chartProps, setChartProps] = useState(null);

  const handleOpenChart = (floor, store_number, department) => {
    setOpenChart(true);
    setChartProps({ floor, store_number, department });
  };

  const handleCloseChart = () => {
    setOpenChart(false);
    setChartProps(null);
  };

  const rowRender = (headCells, row, rowIndex) => {
    return headCells?.map(
      (
        {
          id,
          showId,
          toggleId,
          index,
          newFlag,
          deleteFlag,
          elasiticity,
          elasiticityConfidence,
          colSpan,
          aggregatedColSpan,
          disabled,
          range,
          rangeIndex,
          minRangeIndex,
          maxRangeIndex,
          isVisible,
          type,
          group,
          subColumns,
          isLink,
          prefix,
          prefixId,
          isSticky,
          textTransform,
          showIsFixed,
        },
        headCellIndex
      ) =>
        !group && isVisible ? (
          <TableCell
            align={
              type === 'number' || type === 'numeric' || type === 'delta'
                ? 'right'
                : 'left'
            }
            key={id}
            classes={{
              root:
                headCellIndex === headCells?.length - 1
                  ? classes.customTableLastCell
                  : classnames(
                      classes.customTableCell,
                      isSticky ? classes.sticky : '',
                      ['range', 'checkbox', 'input'].indexOf(type) > -1 &&
                        classes.blendMode
                    ),
            }}
            style={{
              textTransform,
              left: isSticky
                ? calculateStickyLeft(headCells, headCellIndex)
                : 'inherit',
              background:
                ['range', 'checkbox', 'input'].indexOf(type) > -1
                  ? '#D5EAFF'
                  : row.isTotal && '#ebf2f9',
              borderLeft:
                headCellIndex === 0
                  ? row[deleteFlag] === 1
                    ? '8px solid #c11515bf'
                    : row[newFlag] === 1 && '8px solid #037a038c'
                  : null,
              padding: '16px 8px',
              display: row.isTotal && aggregatedColSpan === 0 && 'none',
              textAlign: row.isTotal && 'center',
              fontWeight: row.isTotal && 600,
            }}
            colSpan={row.isTotal ? aggregatedColSpan : colSpan}
          >
            <TableBodyCell
              showIsFixed={!!(showIsFixed && row.fix_flag)}
              id={id}
              data={row[id]}
              showInput={row[showId]}
              fixedInput={row[toggleId]}
              dataIndex={row[index]}
              deleteFlag={row[deleteFlag]}
              newFlag={row[newFlag]}
              isTotal={row.isTotal}
              elasiticity={row[elasiticity]}
              elasiticityConfidence={row[elasiticityConfidence]}
              rowDisabled={row[disabled]}
              inputDisableKey={disabled}
              rangeKey={range}
              rangeLength={row[range]?.length}
              rangeIndex={row[rangeIndex]}
              rangeIndexKey={rangeIndex}
              minRangeIndex={row[minRangeIndex]}
              maxRangeIndex={row[maxRangeIndex]}
              isLink={isLink}
              prefixIdValue={row[prefixId]}
              prefix={prefix}
              type={type}
              floor={row?.floor}
              totalFloors={row?.no_of_floor}
              storeId={row?.store_number}
              department={row?.department}
              textTransform={textTransform}
              rowIndex={rowIndex}
              handleOpenChart={handleOpenChart}
              tableAction={tableAction}
              urlPrefix={urlPrefix}
              queryParams={queryParams}
              onIncrease={onIncrease}
              onDecrease={onDecrease}
              setFixedArea={setFixedArea}
              toggleEditable={toggleEditable}
              toggleCheckbox={toggleCheckbox}
              headCellIndex={headCellIndex}
            />
          </TableCell>
        ) : (
          isVisible &&
          subColumns?.map(
            (
              {
                id,
                showId,
                toggleId,
                index,
                newFlag,
                deleteFlag,
                colSpan,
                disabled,
                range,
                rangeIndex,
                minRangeIndex,
                maxRangeIndex,
                prefix,
                prefixId,
                isLink,
                textTransform,
                type,
                isVisible: isSubColVisible,
              },
              subColIndex
            ) =>
              isSubColVisible && (
                <TableCell
                  align={
                    type === 'number' || type === 'numeric' || type === 'delta'
                      ? 'right'
                      : 'left'
                  }
                  key={id}
                  style={{
                    textTransform,
                    background:
                      ['range', 'checkbox', 'input'].indexOf(type) > -1
                        ? '#D5EAFF'
                        : row.isTotal && '#ebf2f9',
                    borderLeft:
                      headCellIndex === 0 && subColIndex === 0
                        ? row[deleteFlag] === 1
                          ? '8px solid #c11515bf'
                          : row[newFlag] === 1 && '8px solid #037a038c'
                        : null,
                    padding: '16px 8px',
                  }}
                  classes={{
                    root:
                      headCellIndex === headCells?.length - 1 &&
                      subColIndex === subColumns?.length - 1
                        ? classes.customTableLastCell
                        : classes.customTableCell,
                  }}
                  colSpan={colSpan}
                >
                  <TableBodyCell
                    showIsFixed={showIsFixed}
                    id={id}
                    data={row[id]}
                    showInput={row[showId]}
                    fixedInput={row[toggleId]}
                    dataIndex={row[index]}
                    deleteFlag={row[deleteFlag]}
                    newFlag={row[newFlag]}
                    isTotal={row.isTotal}
                    elasiticity={row[elasiticity]}
                    elasiticityConfidence={row[elasiticityConfidence]}
                    rowDisabled={row[disabled]}
                    inputDisableKey={disabled}
                    rangeKey={range}
                    rangeLength={row[range]?.length}
                    rangeIndex={row[rangeIndex]}
                    rangeIndexKey={rangeIndex}
                    minRangeIndex={row[minRangeIndex]}
                    maxRangeIndex={row[maxRangeIndex]}
                    isLink={isLink}
                    prefixIdValue={row[prefixId]}
                    prefix={prefix}
                    type={type}
                    floor={row?.floor}
                    totalFloors={row?.no_of_floor}
                    storeId={row?.store_number}
                    department={row?.department}
                    textTransform={textTransform}
                    rowIndex={rowIndex}
                    handleOpenChart={handleOpenChart}
                    tableAction={tableAction}
                    urlPrefix={urlPrefix}
                    queryParams={queryParams}
                    onIncrease={onIncrease}
                    onDecrease={onDecrease}
                    setFixedArea={setFixedArea}
                    toggleEditable={toggleEditable}
                    toggleCheckbox={toggleCheckbox}
                    headCellIndex={headCellIndex}
                  />
                </TableCell>
              )
          )
        )
    );
  };

  return (
    <>
      <TableBody>
        {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
        {stableSort(rows, getComparator(order, orderBy), isGroupedData)
          ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          ?.map((row, rowIndex) => {
            return (
              <TableRow
                hover
                tabIndex={-1}
                key={rowIndex}
                classes={{ hover: classes.hoverTable }}
              >
                {rowRender(headCells, row, rowIndex)}
              </TableRow>
            );
          })}
        {page === 0 && rows?.length === 0 && (
          <TableRow
            style={{
              height: 352,
            }}
          >
            <TableCell
              colSpan={11}
              classes={{
                root: classnames(classes.customTableCellEmpty),
              }}
            >
              <div className={classes.tableZeroState}>
                <img
                  src={EmptyIcon}
                  alt={'empty'}
                  className={classes.tableZeroStateIcon}
                />
                <p className={classes.tableZeroStateText}>No Data</p>
              </div>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
      <CustomDialog
        open={openChart}
        handleOpen={handleOpenChart}
        handleClose={handleCloseChart}
        isTransparent={true}
      >
        <Chart
          storeId={chartProps?.store_number}
          floor={chartProps?.floor}
          department={chartProps?.department}
        />
      </CustomDialog>
    </>
  );
}

export default EnhancedTableBody;
