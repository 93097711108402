import React, { useEffect } from 'react';
import CustomButton from 'components/CustomButton';
import ExportIcon from 'assets/svg/export.svg';
import { getExportApi } from 'services/generic/generic';
import Loader from 'components/Loader';
import Toast from 'components/Toast';

const Export = ({ reqestPayload, endpoint }) => {
  const { isLoading, isFetching, refetch, error } = getExportApi(
    reqestPayload,
    endpoint
  );

  useEffect(() => {
    if (error) {
      Toast('Something went wrong', 'error');
    }
  }, [error]);

  return (
    <CustomButton
      isPrimary={true}
      variant='contained'
      endIcon={
        isFetching || isLoading ? (
          <Loader size={18} color='#fff' />
        ) : (
          <img src={ExportIcon} alt='download' height={16} width={18} />
        )
      }
      height={36}
      width={96}
      label='Export'
      onButtonClick={refetch}
    />
  );
};

export default Export;
