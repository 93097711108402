import React from 'react';
import { TextField, InputAdornment } from '@mui/material';
import { MailOutline, LockOutlined } from '@mui/icons-material';

const LoginForm = (props) => {
  const { email, password, onChange, ResetPassword, onSubmit, error } = {
    ...props,
  };
  // const [isValid, checklist] = usePwdValidationHook(
  //   passwdRef,
  //   1,
  //   1,
  //   0,
  //   0,
  //   6,
  //   true,
  //   false
  // );
  return (
    <>
      <form className='form-width signin-form' onSubmit={onSubmit}>
        <h2 id='signInText' className='sign-in-text'>
          Sign in to IA Smart Platform
        </h2>
        <p>Enter your details below.</p>
        <div className='input-group'>
          <label className='' htmlFor='email'>
            EMAIL
          </label>
          <TextField
            style={{ width: '100%' }}
            variant='outlined'
            name='email'
            type='email'
            id='loginInputEmail'
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position='start'
                  className='login-form-adornment'
                >
                  <MailOutline />
                </InputAdornment>
              ),
            }}
            className={
              error.email && error.email.length
                ? 'signin-form-input error-border'
                : 'signin-form-input'
            }
            aria-describedby='emailHelp'
            placeholder='E-mail'
            required
            value={email}
            onChange={onChange}
          />
          <p className='error-text'>{error.email}</p>
        </div>
        <div className='input-group'>
          <label className='' htmlFor='password'>
            PASSWORD
          </label>
          <TextField
            variant='outlined'
            name='password'
            type='password'
            id='loginPassword'
            // inputRef={passwdRef}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position='start'
                  className='login-form-adornment'
                >
                  <LockOutlined />
                </InputAdornment>
              ),
            }}
            className={
              error.password && error.password.length
                ? 'signin-form-input error-border'
                : 'signin-form-input'
            }
            placeholder='Password'
            required
            value={password}
            onChange={onChange}
          />
          {/* <Validation ref={passwdRef} checklist={checklist} /> */}
          <p className='error-text'>{error.password}</p>
          <div id='divReset'>
            <button
              type='button'
              id='btnReset'
              className='btn'
              onClick={ResetPassword}
            >
              Forgot Password?
            </button>
          </div>
        </div>
        <button type='submit' id='btnLogin' className='btn-signin'>
          Sign in
        </button>
      </form>
    </>
  );
};

export default LoginForm;
