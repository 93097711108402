/* eslint-disable no-undef */
import axios from 'axios';

// const baseUrl = {
//   'spacesmart-dsg-dev.iaproducts.ai':
//     'https://spacesmart-dsg-dev-backend.iaproducts.ai',
//   'spacesmart-dsg-test.iaproducts.ai':
//     'https://spacesmart-dsg-test-backend.iaproducts.ai',
// };
const api = axios.create({
  baseURL: '',
});

export default api;
