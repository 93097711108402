export const styles = (theme) => ({
  customTableCell: {
    borderBottom: `1px solid ${theme.palette.strokeTable}`,
    borderRight: `2px solid ${theme.palette.strokeTable}`,
  },
  customTableCellEmpty: {
    textAlign: theme.typography.textAlign.center,
    borderBottom: 0,
  },
  customTableLastCell: {
    borderBottom: `1px solid ${theme.palette.strokeTable}`,
  },
  tableZeroState: {
    ...theme.content.flexStyles.flexCol,
    ...theme.content.flexStyles.flexAlignCenter,
  },
  tableZeroStateIcon: {
    height: 64,
    margin: 24,
  },
  tableZeroStateText: {
    ...theme.typography.fontSizes.normalText,
    color: theme.palette.stroke,
  },
  textFieldRoot: {
    width: '100%',
  },
  inputRoot: {
    ...theme.typography.fontSizes.normalText,
    color: theme.palette.textDark,
    height: '2.15rem',
    padding: '0 14px 0 2px !important',
    borderRadius: 2,
    background: theme.palette.textWhite,

    '& .MuiOutlinedInput-notchedOutline': {
      border: `0.5px solid ${theme.palette.stroke} !important`,
      borderRadius: '2px',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      WebkitAppearance: 'none !important',
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      WebkitAppearance: 'none !important',
    },
    '& input[type=number]': {
      MozAppearance: 'textfield',
    },
  },
  inputField: {
    ...theme.typography.fontSizes.normalText,
    padding: `0 !important`,
    height: '2.15rem',
    boxSizing: theme.content.boxSizing.borderBox,
    textAlign: theme.typography.textAlign.right,
    color: theme.palette.textDark,
    '&::placeholder': {
      opacity: 1,
    },
  },
  checkField: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignCenter,
  },
  text: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignBetweenCenter,
    ...theme.typography.fontSizes.normalText,
    color: theme.palette.textTableBody,
  },
  high: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignBetweenCenter,
    ...theme.typography.fontSizes.normalText,
    padding: 6,
    borderRadius: 4,
    fontWeight: theme.typography.fontWeight.medium,
    color: theme.palette.textSuccess,
  },
  medium: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignBetweenCenter,
    ...theme.typography.fontSizes.normalText,
    padding: 6,
    borderRadius: 4,
    fontWeight: theme.typography.fontWeight.medium,
    color: theme.palette.textWarning,
  },
  low: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignBetweenCenter,
    ...theme.typography.fontSizes.normalText,
    padding: 6,
    borderRadius: 4,
    fontWeight: theme.typography.fontWeight.medium,
    color: theme.palette.textDanger,
  },
  rangeText: {
    color: theme.palette.textRange,
  },
  animatedItem: {
    animation: `$myEffect 250ms ${theme.transitions.easing.easeInOut}`,
  },
  animatedItemExiting: {
    animation: `$myEffectExit 250ms ${theme.transitions.easing.easeInOut}`,
  },
  '@keyframes myEffect': {
    '0%': {
      opacity: 0.5,
      transform: 'translateY(-75%)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateY(0)',
    },
  },
  '@keyframes myEffectExit': {
    '0%': {
      opacity: 0.5,
      transform: 'translateY(75%)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateY(0)',
    },
  },
  rangeTextAnimate: {
    color: theme.palette.textRange,
  },
  textLink: {
    ...theme.typography.fontSizes.linkText,
    textDecoration: 'none',
    color: theme.palette.textPrimary,
  },
  sticky: {
    position: theme.content.positions.sticky,
    //Will need to change tomorrow
    background: 'inherit',
    zIndex: 1,
  },
  hoverTable: {
    background: '#fff',
    willChange: 'opacity !important',

    '&:hover': {
      background: '#f5f5f5 !important',
    },
  },
  blendMode: {
    mixBlendMode: 'multiply',
  },
  chartIcon: {
    fontSize: 20,
    cursor: theme.content.cursor.pointer,
    transition: 'all 0.3s',
    '&:active': {
      transform: 'scale(0.8)',
    },
    color: theme.palette.textPrimary,
    borderBottom: `2px solid ${theme.palette.textPrimary}`,
  },
  checkBoxIcon: {
    fontSize: '10px !important',
    color: theme.palette.stroke,
    background: 'transparent !important',
  },
  rangeIcon: {
    cursor: theme.content.cursor.pointer,
    transition: 'all 0.3s',
    '&:active': {
      transform: 'scale(0.8)',
    },
  },
  editIcon: {
    height: 16,
    marginBottom: 4,
    marginRight: 8,
    cursor: theme.content.cursor.pointer,
    transition: 'all 0.3s',
    '&:active': {
      transform: 'scale(0.8)',
    },
  },
  rangeIconDisabled: {
    opacity: 0.4,
    pointerEvents: 'inherit !important',
    cursor: `${theme.content.cursor.notAllowed} !important`,
  },
  inherit: {
    textTransform: theme.typography.textTransform.inherit,
  },
  capitalize: {
    textTransform: theme.typography.textTransform.capitalize,
  },
  uppercase: {
    textTransform: theme.typography.textTransform.uppercase,
  },
  lowercase: {
    textTransform: theme.typography.textTransform.lowercase,
  },
});
