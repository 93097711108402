import LoadingOverlay from 'components/LoadingOverlay';
// import Toast from 'components/Toast';
import React from 'react';
import { connect } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

function RequireAuth(rest) {
  let location = useLocation();
  if (rest.isAuthenticated) {
    return <Outlet />;
  } else {
    if (rest.isTokenVerified) {
      //   Toast('Please Login to continue...', 'error');
      return <Navigate to='/login' state={{ from: location }} />;
    } else {
      return <LoadingOverlay loader={true} spinner text={rest.loaderText} />;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.authReducer.isAuthenticated,
    isTokenVerified: state.authReducer.isTokenVerified,
    isTenantFetchFailed: state.authReducer.isTenantFetchFailed,
    loaderText: state.authReducer.alertProperties.Text,
  };
};

export default connect(mapStateToProps, null)(RequireAuth);
