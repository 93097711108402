import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Checkbox, Grid } from '@mui/material';
import CustomDialog from 'components/CustomDialog';
import CustomButton from 'components/CustomButton';
import CustomText from 'components/CustomText';
import Loader from 'components/Loader';

import { ReactComponent as UncheckedIcon } from 'assets/svg/unchecked.svg';
import { ReactComponent as CheckedIcon } from 'assets/svg/check.svg';

const styles = (theme) => ({
  filterActions: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignCenter,
    flexWrap: theme.content.flexStyles.flexWrap.wrap,
    marginTop: 40,
  },
  dataFilter: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignStartCenter,
    marginRight: 16,
  },
  dataFilterText: {
    ...theme.typography.fontSizes.normalText,
    color: theme.palette.textDark,
  },
});

const useStyles = makeStyles(styles);
const NoSavePrompt = ({ handleOpen, handleClose, isLoading, handleSave }) => {
  const classes = useStyles();
  const [isChecked, setIsChecked] = useState(false);

  return (
    <CustomDialog
      open
      handleOpen={handleOpen}
      handleClose={handleClose}
      disableClose={isLoading}
      isTransparent={false}
    >
      <div style={{ height: 180, width: 440 }}>
        {!isLoading ? (
          <>
            <p>{`You haven't saved the Constraints yet, would you Like to save the constraints and then run the optimizer ?`}</p>
            <div className={classes.dataFilter}>
              <Checkbox
                checked={isChecked}
                icon={<UncheckedIcon />}
                checkedIcon={<CheckedIcon />}
                className={classes.checkBoxIcon}
                onChange={() => setIsChecked(!isChecked)}
              />
              <CustomText
                content={'Do not show again'}
                variant={'body1'}
                classes={classes.dataFilterText}
              />
            </div>
            <Grid item className={classes.filterActions}>
              <CustomButton
                isPrimary={true}
                variant='contained'
                height={32}
                width={72}
                label='Yes'
                onButtonClick={() => {
                  if (isChecked) {
                    localStorage.setItem(`no-prompt`, true);
                  }
                  handleSave();
                }}
              />
              <CustomButton
                isPrimary={false}
                variant='outlined'
                height={32}
                width={72}
                label='No'
                onButtonClick={() => {
                  if (isChecked) {
                    localStorage.setItem(`no-prompt`, true);
                  }
                  handleClose();
                }}
              />
            </Grid>
          </>
        ) : (
          <Loader />
        )}
      </div>
    </CustomDialog>
  );
};

export default NoSavePrompt;
