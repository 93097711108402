import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Grid } from '@mui/material';

import AreaIcon from 'assets/svg/area.svg';
import SettingsIcon from 'assets/svg/settingsPrimary.svg';
import ListIcon from 'assets/svg/listPrimary.svg';
import SyncIcon from 'assets/svg/syncPrimary.svg';
import TotalArea from './TotalArea';
import LastOptimizedResults from './LastOptimizedResults';
import RunOptimizer from './RunOptimizer';
import FloorLevels from './FloorLevels';
import CustomText from 'components/CustomText';
import Export from './Export';

const styles = (theme) => ({
  actionContainer: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignStartCenter,
    cursor: theme.content.cursor.pointer,
    width: 'fit-content',
  },
  content: {
    ...theme.typography.fontSizes.normalText,
    color: theme.palette.textPrimary,
    letterSpacing: '-0.42px',
    marginRight: 8,
  },
  actionIcon: {
    width: 16,
    height: 18,
  },
});

const useStyles = makeStyles(styles);

const StoreFilterActions = ({
  season,
  isoptimizer,
  seasonOptions,
  storeDetails,
  compareAgainstFilter,
  exportEndpoint,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [openArea, setOpenArea] = useState(false);
  const [openLastOptimizedResults, setOpenLastOptimizedResults] =
    useState(false);
  const [openRunOptimizer, setOpenRunOptimizer] = useState(false);
  const [openFloorLevels, setOpenFloorLevels] = useState(false);
  const params = useParams();

  const handleOpenArea = () => {
    setOpenArea(true);
  };

  const handleCloseArea = () => {
    setOpenArea(false);
  };

  const handleOpenLastOptimizedResults = () => {
    setOpenLastOptimizedResults(true);
  };

  const handleCloseLastOptimizedResults = () => {
    setOpenLastOptimizedResults(false);
  };

  const handleOpenRunOptimizer = () => {
    setOpenRunOptimizer(true);
  };

  const handleCloseRunOptimizer = () => {
    setOpenRunOptimizer(false);
  };

  const handleOpenFloorLevels = () => {
    setOpenFloorLevels(true);
  };

  const handleCloseFloorLevels = () => {
    setOpenFloorLevels(false);
  };

  const runOptimizer = (level) => {
    handleCloseRunOptimizer();

    if (level === 'floor' && storeDetails?.no_of_floor > 1) {
      setTimeout(() => {
        handleOpenFloorLevels();
      }, 100);
    }
  };

  const setConstraints = () => {
    let { year } = season;
    if (isoptimizer) {
      year -= 1;
    }
    storeDetails &&
      navigate(
        `/constraints/${storeDetails?.store_number}/?ly_season=${year} ${season?.name}`
      );
  };

  return (
    <Grid
      container
      spacing={1}
      columns={{ xs: 12, sm: 12, md: 12, lg: 15, xl: 15 }}
      alignItems={'center'}
      justifyContent={'flex-end'}
    >
      <Grid item xs={6} md={4} lg={isoptimizer ? 3.5 : 2.6} xl={2.7}>
        <div
          className={classes.actionContainer}
          onClick={() => handleOpenArea()}
        >
          <CustomText
            content={'Total Area'}
            variant={'body1'}
            classes={classes.content}
          />
          <img src={AreaIcon} alt='area' className={classes.actionIcon} />
        </div>
      </Grid>
      {!isoptimizer && (
        <Grid item xs={6} md={4} lg={4.2} xl={4.3}>
          <div
            className={classes.actionContainer}
            onClick={() => handleOpenLastOptimizedResults()}
          >
            <CustomText
              content={'Last Optimized Result'}
              variant={'body1'}
              classes={classes.content}
            />
            <img src={ListIcon} alt='area' className={classes.actionIcon} />
          </div>
        </Grid>
      )}
      <Grid item xs={6} md={4} lg={isoptimizer ? 3.5 : 2.6} xl={2.7}>
        <div
          className={classes.actionContainer}
          onClick={() => setConstraints()}
        >
          <CustomText
            content={'Constraints'}
            variant={'body1'}
            classes={classes.content}
          />
          <img src={SettingsIcon} alt='area' className={classes.actionIcon} />
        </div>
      </Grid>
      {!isoptimizer && (
        <Grid item xs={6} md={4} lg={2.7} xl={2.8}>
          <div
            className={classes.actionContainer}
            onClick={() => handleOpenRunOptimizer()}
          >
            <CustomText
              content={'Run Optimizer'}
              variant={'body1'}
              classes={classes.content}
            />
            <img src={SyncIcon} alt='area' className={classes.actionIcon} />
          </div>
        </Grid>
      )}
      <Grid item xs={6} md={4} lg={isoptimizer ? 4 : 3} xl={2.5}>
        <Export
          reqestPayload={{
            season: { year: String(season?.year), name: season?.name },
            compare_against: compareAgainstFilter,
            level_flag: params?.type,
          }}
          endpoint={exportEndpoint}
        />
      </Grid>
      {openArea && (
        <TotalArea
          season={season}
          isoptimizer={isoptimizer}
          handleOpen={handleOpenArea}
          handleClose={handleCloseArea}
          isTransparent={false}
        />
      )}
      {openLastOptimizedResults && (
        <LastOptimizedResults
          season={season}
          seasonOptions={seasonOptions}
          handleOpen={handleOpenLastOptimizedResults}
          handleClose={handleCloseLastOptimizedResults}
        />
      )}
      {openRunOptimizer && (
        <RunOptimizer
          season={season}
          seasonOptions={seasonOptions}
          floors={storeDetails?.no_of_floor}
          handleOpen={handleOpenRunOptimizer}
          handleClose={handleCloseRunOptimizer}
          runOptimizer={runOptimizer}
        />
      )}
      {openFloorLevels && (
        <FloorLevels
          floors={storeDetails?.no_of_floor}
          handleOpen={handleOpenFloorLevels}
          handleClose={handleCloseFloorLevels}
          runOptimizer={runOptimizer}
        />
      )}
    </Grid>
  );
};

export default StoreFilterActions;
