import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
// import AutoCompleteSearch from 'components/AutoCompleteSearch';
import CustomText from 'components/CustomText';
// import { getStoreList } from 'services/stores/storeFilters';
import { infoStyles } from './StoreModelInfo';
import { helperTexts } from 'config/constants';
import Helper from 'components/Helper';

const useStyles = makeStyles(infoStyles);

const OptimisedModelInfo = ({ storeDetails }) => {
  // const navigate = useNavigate();
  // const [searchParams] = useSearchParams();
  // const season = isoptimizer
  //   ? searchParams.get('optimized_season')
  //   : searchParams.get('season');
  // const lySeason = searchParams.get('ly_season');
  const [store, setStore] = useState(null);

  const classes = useStyles();

  // const onStoreSearch = (_, value) => {
  //   if (value) {
  //     setStore(value);

  //     if (isoptimizer && levelFlag) {
  //       navigate(
  //         `/optimized/${value.store_number}/${levelFlag}/?optimized_season=${season}&ly_season=${lySeason}`
  //       );
  //     } else {
  //       navigate(`/store/${value.store_number}?season=${season}`);
  //     }
  //   }
  // };

  // const {
  //   isLoading,
  //   data = [],
  //   refetch,
  // } = getStoreList({
  //   text: '',
  //   region: [],
  //   state: [],
  //   climate: [],
  // });

  // useEffect(() => {
  //   refetch();
  // }, []);

  useEffect(() => {
    if (storeDetails) {
      setStore(storeDetails);
    }
  }, [storeDetails]);

  const type = useParams()?.type?.split('_')?.join(' ');

  return (
    <Grid container spacing={3} columns={{ sm: 12, md: 12, lg: 16, xl: 16 }}>
      <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            classes={{ root: classes.gridFlexFirstItem }}
            style={{
              cursor: 'not-allowed',
              pointerEvents: 'none',
            }}
          >
            <CustomText
              content={'Store: '}
              classes={classes.fieldLabel}
              paragraph={false}
              variant={'body1'}
            />
            <CustomText
              content={
                store ? `${store?.store_number}-${store?.store_name}` : '-'
              }
              classes={classes.textValue}
              paragraph={false}
              variant={'body1'}
            />
            {/* <AutoCompleteSearch
              label='Store'
              selectedValue={store}
              placeholder='Enter Store Name'
              options={[store, ...data].filter(
                (a, i, self) =>
                  self.findIndex((s) => a?.store_number === s?.store_number) ===
                  i
              )}
              multiple={false}
              loading={isLoading}
              showLabel={false}
              textTransform='uppercase'
              width={'194px'}
              maxWidth={194}
              maxHeight={36}
              textColor={'stroke'}
              onChange={onStoreSearch}
              disableCloseOnSelect={false}
            /> */}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
        <Grid container spacing={1}>
          <Grid item xs={12} classes={{ root: classes.gridFlexFirstItem }}>
            <CustomText
              content={'Optimization Level: '}
              classes={classes.fieldLabel}
              paragraph={false}
              variant={'body1'}
            />
            <CustomText
              content={`Intra ${type}`}
              classes={classes.textValue}
              paragraph={false}
              variant={'body1'}
            />
            <div className={classes.helper}>
              <Helper
                title={
                  type === 'store'
                    ? helperTexts.intra_store_optimization.text
                    : type === 'floor'
                    ? helperTexts.intra_floor_optimization.text
                    : helperTexts.intra_floor_plan_optimization.text
                }
                link={
                  type === 'store'
                    ? helperTexts.intra_store_optimization.link
                    : type === 'floor'
                    ? helperTexts.intra_floor_optimization.link
                    : helperTexts.intra_floor_plan_optimization.link
                }
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OptimisedModelInfo;
