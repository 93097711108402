import { useQuery } from 'react-query';
import axiosInstance from 'Utils/axios';

const fetchStoreFilters = () => {
  return axiosInstance.get(`store-master-filter-data/`);
};

const fetchStoreList = (payload) => {
  return axiosInstance.post(`store-master-filter-search/`, payload);
};

export const getStoreFilters = (key) => {
  return useQuery(key, fetchStoreFilters, {
    enabled: false,
    select: (data) => {
      const filters = data.data.data;

      filters.region = filters.region.map((reg) => {
        reg.label = reg.value;
        return reg;
      });

      filters.climate = filters.climate.map((climate) => {
        climate.label = climate.value;
        return climate;
      });

      filters.state = filters.state.map((state) => {
        state.label = state.value;
        return state;
      });

      filters.season = filters.season.map((season) => {
        season.label = `${season.year} ${season.name}`;

        return season;
      });

      return filters;
    },
  });
};

export const getStoreList = (payload) => {
  return useQuery(['stores-list', payload], () => fetchStoreList(payload), {
    enabled: false,
    select: (data) => {
      const storesList = data.data.data.map((store) => {
        store.title = `${store.store_number}-${store.store_name}`;

        return store;
      });
      return storesList;
    },
  });
};
