import React from 'react';
import { makeStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import MultiSelect from 'components/MultiSelect';
import CustomButton from 'components/CustomButton';
import CustomCard from 'components/CustomCard';
import StoreSearchFilter from './StoreSearchFilter';

const styles = (theme) => ({
  contentCard: {
    ...theme.content.card,
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignBetweenCenter,
    width: '100%',
    margin: '16px 0',
    padding: '24px 32px',
  },
});

const useStyles = makeStyles(styles);

const DataFilters = ({
  isLoading,
  filterActionsClass,
  regions,
  states,
  climates,
  storeNumber,
  regionOptions,
  stateOptions,
  climateOptions,
  onRegionSelect,
  onStateSelect,
  onClimateSelect,
  setStoreNumber,
  onApplyFilter,
  onResetFilter,
}) => {
  const classes = useStyles();

  return (
    <CustomCard cardStyles={classes.contentCard}>
      <Grid container spacing={3} columns={15} justifyContent={'space-between'}>
        <Grid item xs={15} md={12}>
          <Grid container spacing={3}>
            <Grid item xs={6} lg={3}>
              <MultiSelect
                label={'Region'}
                placeholder={'Select Region'}
                selectedValues={regions}
                options={regionOptions}
                limitTags={1}
                width={156}
                maxHeight={36}
                textTransform='capitalize'
                onSelect={onRegionSelect}
                loading={isLoading}
              />
            </Grid>
            <Grid item xs={6} lg={3}>
              <MultiSelect
                label={'State'}
                placeholder={'Select State'}
                selectedValues={states}
                options={stateOptions}
                limitTags={2}
                width={156}
                maxHeight={36}
                textTransform='uppercase'
                onSelect={onStateSelect}
                loading={isLoading}
              />
            </Grid>
            <Grid item xs={6} lg={3}>
              <MultiSelect
                label={'Climate'}
                placeholder={'Select Climate'}
                selectedValues={climates}
                options={climateOptions}
                limitTags={1}
                width={156}
                maxHeight={36}
                textTransform='capitalize'
                onSelect={onClimateSelect}
                loading={isLoading}
              />
            </Grid>
            <Grid item xs={6} lg={3}>
              <StoreSearchFilter
                region={regions}
                state={states}
                climate={climates}
                storeNumber={storeNumber}
                setStoreNumber={setStoreNumber}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={filterActionsClass}>
          <CustomButton
            isPrimary={true}
            variant='contained'
            height={32}
            width={72}
            label='Filter'
            onButtonClick={() => onApplyFilter()}
          />
          <CustomButton
            isPrimary={false}
            variant='outlined'
            height={32}
            width={72}
            label='Reset'
            onButtonClick={() => onResetFilter()}
          />
        </Grid>
      </Grid>
    </CustomCard>
  );
};

export default DataFilters;
