import React from 'react';

import { Typography } from '@mui/material';

const CustomText = (props) => {
  const { classes, variant, content, paragraph } = props;

  return (
    <>
      <Typography className={classes} variant={variant} paragraph={paragraph}>
        {content}
      </Typography>
    </>
  );
};

export default CustomText;
