import React from 'react';
import { IconButton } from '@mui/material';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { makeStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { logoutUser } from 'actions/authActions';

const styles = (theme) => ({
  logOut: {
    fontSize: '1.3rem',
    color: theme.palette.textDark,
  },
});

const useStyles = makeStyles(styles);

const Logout = ({ logoutUser }) => {
  const classes = useStyles();

  return (
    <IconButton color='inherit' disableRipple onClick={logoutUser}>
      <PowerSettingsNewIcon classes={{ root: classes.logOut }} />
    </IconButton>
  );
};

export default connect(null, {
  logoutUser,
})(Logout);
