import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Tooltip } from '@mui/material';

import CustomText from 'components/CustomText';

import ListIcon from '../assets/svg/list.svg';
import ListPrimaryIcon from '../assets/svg/listPrimary.svg';
import LastOptimizedResults from './LastOptimizedResults';

const styles = (theme) => ({
  container: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignBetweenCenter,
    flexWrap: theme.content.flexStyles.flexWrap.wrap,
  },
  actionContainer: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignStartCenter,
    cursor: theme.content.cursor.pointer,
    width: 'fit-content',
  },
  actionIconContainer: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignStartCenter,
    cursor: theme.content.cursor.pointer,
    padding: 8,
    width: 'fit-content',
  },
  content: {
    ...theme.typography.fontSizes.normalText,
    color: theme.palette.textPrimary,
    letterSpacing: '-0.42px',
    marginRight: 8,
    whiteSpace: 'nowrap',
  },
  actionIcon: {
    width: 16,
    height: 18,
  },
});

const useStyles = makeStyles(styles);

const LastOptimizedResultsContainer = (props) => {
  const classes = useStyles();
  const { showIcon, showLabel, isActionButton, storeId, season } = props;
  const [openRunOptimizer, setOpenRunOptimizer] = useState(false);

  const handleOpenRunOptimizer = () => {
    setOpenRunOptimizer(true);
  };

  const handleCloseRunOptimizer = () => {
    setOpenRunOptimizer(false);
  };

  return (
    <>
      <div
        className={
          !showLabel ? classes.actionIconContainer : classes.actionContainer
        }
        onClick={() => {
          handleOpenRunOptimizer();
        }}
      >
        {showLabel && (
          <CustomText
            content={'Last Optimized Results'}
            variant={'body1'}
            classes={classes.content}
          />
        )}
        {showIcon && (
          <Tooltip
            arrow
            title={'Last Optimized Results'}
            disableFocusListener={!isActionButton}
            disableHoverListener={!isActionButton}
          >
            <img
              src={isActionButton ? ListIcon : ListPrimaryIcon}
              alt='area'
              className={!isActionButton ? classes.actionIcon : ''}
            />
          </Tooltip>
        )}
      </div>

      {openRunOptimizer && (
        <LastOptimizedResults
          season={season}
          storeId={storeId}
          handleOpen={handleOpenRunOptimizer}
          handleClose={handleCloseRunOptimizer}
          // isLoading={isLoading}
        />
      )}
    </>
  );
};

export default LastOptimizedResultsContainer;
